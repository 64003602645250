export interface ITokenModel {
  id: string;
  token: string;
  userId: string;
  issuedAt: number;
  expiredAt: number;
}

export interface IAuthModel extends Components.Schemas.TokenPairPayloadDto {}
export interface IAuthParams extends Components.Schemas.CredentialsDto {}
export interface IAuthRefreshParams extends Components.Schemas.RefreshTokenDto {}

export interface IPasswordForgotParams extends Components.Schemas.PasswordForgotDto {}
export interface IPasswordRestoreParams extends Components.Schemas.PasswordRecoveryDto {}
export interface ICheckTokenData extends Components.Schemas.CheckTokenDto {}

export enum EAuthSuccessMessage {
  SMSResend = 'SMS code was resent',
  SuccessfullyEdited = 'Profile successfully updated',
  PasswordChanged = 'Password successfully changed',
  CheckEmailForConfirmedLink = 'Please check your email',
  AccountConfirmed = 'Account successfully confirmed',
  CheckEmailAfterRequest = 'Instructions sent! Instructions for resetting your password have been sent to your email',
  CheckEmailAfterRequestWithEmail = 'Instructions sent! Instructions for resetting your password have been sent to ',
  ChangePasswordSuccess = 'Password changed'
}

export enum EAuthErrorCode {
  InvalidCredentials = 'error.userInvalidCredentials',
  UserBlocked = 'error.userBlockedException',
  UserNotConfirmed = 'error.userNotConfirmedException'
}

export enum EAuthErrorMessage {
  LinkExpired = 'The restore link has expired. Please request password restore one more time',
  InvalidLink = 'Invalid link',
  InvalidCredentials = 'The password or email address that you have used are incorrect. Please try again.',
  UserBlocked = 'We are sorry but your account might be suspended. If this is a mistake, please contact our MB Go Helpdesk',
  UserNotConfirmed = 'Please verify your email address to log in'
}

export enum EBaseErrorMessage {
  Default = 'An error occurred, please try again later'
}

export enum ESocialType {
  Google = 'google',
  Facebook = 'facebook'
}

export enum EAuthSocialErrorCode {
  FacebookAlreadyExists = 'FacebookAlreadyConnected',
  GoogleAlreadyExists = 'GoogleAlreadyConnected'
}

export const socialErrorMessages: Record<EAuthSocialErrorCode, string> = {
  [EAuthSocialErrorCode.FacebookAlreadyExists]: 'This Facebook account is already linked to another MB Go account.',
  [EAuthSocialErrorCode.GoogleAlreadyExists]: 'This Google account is already linked to another MB Go account.'
};
