import React, { ReactNode } from 'react';
import { Button } from 'antd';
import iconBack from 'app/assets/images/back.svg';

interface IComponentProps {
  header?: string | ReactNode;
  subHeader?: string | JSX.Element;
  back?: () => void;
}

export const MobileLayout: React.FC<IComponentProps> = props => {
  const { header, subHeader, children, back } = props;

  return (
    <div className="layout-mobile">
      <div className={`layout-mobile__header ${subHeader ? 'mb-2' : ''}`}>
        {back && (
          <Button className="btn-opacity layout-mobile__header-icon" onClick={back}>
            <img src={iconBack} alt="back" />
          </Button>
        )}

        <div className="layout-mobile__header-text">{header}</div>
      </div>

      {subHeader && <div className="layout-mobile__sub-header">{subHeader}</div>}

      {children}
    </div>
  );
};
