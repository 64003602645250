import React from 'react';
import moment from 'moment';
import { Row, Spin } from 'antd';
import { ReactComponent as SvgClock } from 'app/assets/images/clock.svg';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';

interface IComponentProps {
  time?: moment.Moment;
  asap?: moment.Moment;
  asapIncrement?: number;
  times: moment.Moment[];
  loading: boolean;
  setTime: (param: moment.Moment, isAsap: boolean) => void;
}

type AllProps = IComponentProps;

export const TimeSelector: React.FC<AllProps> = props => {
  const { time, setTime, asap, asapIncrement, times, loading } = props;

  const selectTime = (newTime: moment.Moment, isAsap: boolean) => {
    setTime(newTime, isAsap);
  };

  if (!asap) {
    return (
      <div className="t-align-c">
        This store is currently offline. You can wait until it opens or you may try another Minute Burger location
      </div>
    );
  }

  return (
    <Spin spinning={loading}>
      <div className="list-times">
        <Row
          className={`p-4 list-times__item ${time && formatTime(time) === formatTime(asap) ? 'list-times__item_active' : ''}`}
          align="middle"
          onClick={() => selectTime(asap, true)}
        >
          <SvgClock className="mr-4" />

          <span>ASAP {asapIncrement && `(${asapIncrement} minutes)`}</span>
        </Row>

        {times.map(item => (
          <div
            className={`pl-13 pt-4 pb-4 pr-4 list-times__item ${
              time && formatTime(time) === formatTime(item) ? 'list-times__item_active' : ''
            }`}
            key={item.toISOString()}
            onClick={() => selectTime(item, false)}
          >
            <span>{formatTime(item)}</span>
          </div>
        ))}
      </div>
    </Spin>
  );
};
