import queryString from 'querystring';
import { EAuthSocialErrorCode } from 'entities/Auth/Auth.models';

const useSocialConnectError = (): EAuthSocialErrorCode | null => {
  const param = queryString.parse(window.location.search)[`?errorCode`];
  const code = param ? (Array.isArray(param) ? param[0] : param) : '';

  return code === EAuthSocialErrorCode.FacebookAlreadyExists || code === EAuthSocialErrorCode.GoogleAlreadyExists ? code : null;
};

export const AuthHooks = { useSocialConnectError };
