import isEqual from 'lodash.isequal';
import { ICartAddItemParams } from 'entities/Cart/Cart.models';

export const getCartItemTotalSum = (item: ICartAddItemParams, discountPrice?: number | null): number => {
  const { price, quantity } = item;
  const calcPrice = discountPrice || price;
  const itemSum = calcPrice * quantity;
  const addOnsSum =
    item?.addOns?.reduce((acc, current) => {
      const { quantity: addOnQuantity, price: addOnPrice } = current;
      acc += addOnQuantity * addOnPrice * quantity;
      return acc;
    }, 0) || 0;

  return itemSum + addOnsSum;
};

export const isCartAddItemParamsEquals = (first: ICartAddItemParams, second: ICartAddItemParams): boolean => {
  const firstWithSortAddOns = { ...first, addOns: [...first.addOns].sort((a, b) => a?.menuItemId?.localeCompare(b?.menuItemId)) };
  const secondWithSortAddOns = {
    ...second,
    addOns: [...second.addOns].sort((a, b) => a?.menuItemId?.localeCompare(b?.menuItemId))
  };
  return isEqual(firstWithSortAddOns, secondWithSortAddOns);
};
