import React from 'react';
import { Button } from 'antd';
import { ReactComponent as GoogleIcon } from 'app/assets/images/google.svg';

interface IComponentProps {
  text: string;
  onClick(): void;
  disabled?: boolean;
  className?: string;
}

export const GoogleButton: React.FC<IComponentProps> = props => {
  const { className, disabled, onClick, text } = props;

  return (
    <Button
      className={`fs-sm font-weight-bold d-flex width-full d-flex_align-center social-bar__google-btn ${className || ''}`}
      disabled={!!disabled}
      onClick={onClick}
    >
      <GoogleIcon className="social-bar__icon" />
      <span title={text}>{text}</span>
    </Button>
  );
};
