import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Row, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesCommon } from 'common/models/routesModel';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import menuBurger from 'app/assets/images/menuBurger.svg';
import menuClose from 'app/assets/images/menuClose.svg';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { ShopBag } from 'entities/Orders/components/ShopBag';

type AllProps = IAuthConnectedProps & RouteComponentProps & IUserConnectedProps;

interface IComponentState {
  visibleMobMenu: boolean;
}

class HeaderMenuWrapperMobComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = { visibleMobMenu: false };

  render() {
    const { visibleMobMenu } = this.state;

    return (
      <>
        <Row gutter={10} align="middle">
          <Button className="btn-opacity" onClick={this.showDrawer}>
            <img src={menuBurger} alt="open menu" />
          </Button>

          <Link to={ERoutesCommon.Root}>
            <span className="header__title header__title_with-p">MB Go!</span>
          </Link>
        </Row>

        <Drawer
          placement="top"
          closable={false}
          onClose={this.closeDrawer}
          visible={visibleMobMenu}
          key="top"
          className="header-menu__drawer"
          height="auto"
        >
          <Row gutter={10} align="middle">
            <Button className="btn-opacity btn-opacity_sm" onClick={this.closeDrawer}>
              <img src={menuClose} alt="close menu" />
            </Button>

            <Link to={ERoutesCommon.Root} className="flex__item_1 mt-1">
              <span className="header__title header__title_with-p">MB Go!</span>
            </Link>

            <ShopBag />
          </Row>

          <HeaderMenu mobile close={this.closeDrawer} />
        </Drawer>
      </>
    );
  }

  showDrawer = () => {
    this.setState({
      visibleMobMenu: true
    });
  };

  closeDrawer = () => {
    this.setState({
      visibleMobMenu: false
    });
  };

  logout = () => {
    const { deleteAuthModel } = this.props;
    this.closeDrawer();
    deleteAuthModel();
  };
}

export const HeaderMenuWrapperMob = communicationUser.injector(
  communicationAuth.injector(withRouter(HeaderMenuWrapperMobComponent))
);
