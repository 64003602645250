import React, { ReactNode } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';

interface IComponentProps {
  privateRoutes: ReactNode;
  publicRoutes: ReactNode;
  nonCompletedUserRoutes: ReactNode;
}
type AllProps = IAuthConnectedProps & ICustomerConnectedProps & IComponentProps;

class PermissionLayoutComponent extends React.Component<AllProps> {
  render() {
    const { authModel, privateRoutes, publicRoutes, customerModel, nonCompletedUserRoutes } = this.props;
    const { data: authData, loading: authLoading } = authModel;
    const { data: customerModelData } = customerModel;
    const authorized = !!(authData && Object.keys(authData).length);
    const isUserCompleted = customerModelData?.isCompleted;
    const checkForAccountComplete = isUserCompleted ? privateRoutes : nonCompletedUserRoutes;

    if (authLoading) {
      return <LoadingSpin />;
    }

    return authorized ? customerModelData ? checkForAccountComplete : <LoadingSpin /> : publicRoutes;
  }
}

export const PermissionLayout = communicationCustomer.injector(communicationAuth.injector(PermissionLayoutComponent));
