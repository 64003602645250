import React, { memo } from 'react';
import { List, Row } from 'antd';
import { formatAmount } from 'common/helpers/format.helper';
import { ECartPromoBonusType, ICartItemModel } from 'entities/Cart/Cart.models';
import { CartItemAddOnsListItem } from 'entities/Cart/components/CartItemAddOnsListItem';

interface IComponentProps {
  item: ICartItemModel;
  promoBonusType?: ECartPromoBonusType;
  onClick?(item: ICartItemModel): void;
  regular?: boolean;
}

const CartItemsListItemComponent: React.FC<IComponentProps> = props => {
  const { item, onClick, promoBonusType, regular } = props;
  const { isEnable, name, addOns, total, quantity, discountTotal, isPromo, maxLimit } = item;
  const isPromoDiscountedItem = isPromo && promoBonusType === ECartPromoBonusType.DiscountMenuItem;
  const isPromoAdditionalItem =
    isPromo &&
    (promoBonusType === ECartPromoBonusType.AdditionalMenuItem || promoBonusType === ECartPromoBonusType.AdditionalNotOnMenu);
  const classes = `menu-card cursor-pointer mt-5 ${isPromo ? 'menu-card_box-shadow_orange' : ''}`;
  const showAddMessage = isPromoDiscountedItem && maxLimit && maxLimit > quantity;

  const handleClick = () => {
    if (isPromoAdditionalItem) {
      return;
    }
    onClick && onClick(item);
  };

  const isRegular = regular || isEnable;

  return (
    <div className={classes} onClick={handleClick}>
      <Row wrap={false} justify="space-between" className="font-weight-7 fs-xs lh-sm">
        <div className={`${!isRegular ? 'color-gray' : ''} word-break`}>
          {quantity || 0}x {name}
        </div>
        {!isRegular ? (
          <div className="ml-2 fs font-weight-5 color-gray">Unavailable</div>
        ) : (
          <>
            {isPromoDiscountedItem && (
              <Row justify="space-between" wrap={false}>
                <div className="ml-2 t-decoration-line-through">P{formatAmount(total)}</div>
                <div className="ml-4 color-orange-main">P{formatAmount(discountTotal || 0)}</div>
              </Row>
            )}
            {isPromoAdditionalItem && <div className="ml-2 color-orange-main">P0</div>}
            {!isPromo && <div className="ml-2">P{formatAmount(total)}</div>}
          </>
        )}
      </Row>
      {showAddMessage && <div className="font-weight-7 fs-xs color-orange-main">Add more discounted items</div>}
      <List
        className="list-without-empty"
        dataSource={addOns}
        renderItem={addOn => <CartItemAddOnsListItem addOn={addOn} itemQuantity={quantity} />}
      />
    </div>
  );
};

export const CartItemsListItem = memo(CartItemsListItemComponent);
