import React from 'react';
import { ERoutesPublic } from 'common/models/routesModel';
import { FacebookButton } from 'common/components/FacebookButton';
import { GoogleButton } from 'common/components/GoogleButton';

interface IComponentProps {
  disabled: boolean;
}

export const SocialBar: React.FC<IComponentProps> = props => {
  const { disabled } = props;
  const facebookAuth = () => {
    window.location.replace(ERoutesPublic.FacebookAuth);
  };
  const googleAuth = () => {
    window.location.replace(ERoutesPublic.GoogleAuth);
  };
  return (
    <>
      <FacebookButton text="Continue with Facebook" onClick={facebookAuth} disabled={disabled} />
      <GoogleButton className="mt-4" text="Continue with Google" onClick={googleAuth} disabled={disabled} />
    </>
  );
};
