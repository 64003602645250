export const formatCurrency = (valueInit?: number | null): string => {
  const value = valueInit || 0;

  return `P ${value}`;
};

export const getWithoutSpaces = (value: string): string => {
  return value.replace(/\s+/g, '');
};

export const formatAmount = (amount: number = 0): string => `${Number.isInteger(amount) ? amount : amount.toFixed(2)}`;
