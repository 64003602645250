import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import {
  IOrderHistoryCollection,
  IOrderHistoryCollectionFilter,
  IOrderHistoryModel
} from 'entities/OrderHistory/OrderHistory.models';
import { communicationOrderHistory, IOrderHistoryConnectedProps } from 'entities/OrderHistory/OrderHistory.communication';
import { OrderHistoryItem } from 'entities/OrderHistory/components/OrderHistoryItem';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { ECustomerStatus } from 'entities/Customer/Customer.models';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';

interface IComponentProps {
  filter?: IOrderHistoryCollectionFilter;
  showConfirm(item: IOrderHistoryModel): void;
}

type AllProps = IOrderHistoryConnectedProps & ICartConnectedProps & IComponentProps & ICustomerConnectedProps;

class OrderHistoryListComponent extends InfiniteList<
  IOrderHistoryCollection,
  IOrderHistoryModel,
  AllProps,
  IOrderHistoryCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getOrderHistoryCollection, filter } = this.props;
    const { store } = filter || {};
    const newFilter: IOrderHistoryCollectionFilter = {
      ...params
    };

    if (store) {
      newFilter.store = store;
    }

    getOrderHistoryCollection(newFilter);
  };

  renderListItem = (item: IOrderHistoryModel) => {
    const { addOrderHistoryReorder, showConfirm, customerModel, cartModel } = this.props;
    const isBagEmpty = cartModel?.data?.items?.length === 0;
    const isUserBanned = customerModel?.data?.status === ECustomerStatus.Blocked;

    return (
      <OrderHistoryItem
        order={item}
        addReorder={addOrderHistoryReorder}
        isBagEmpty={isBagEmpty}
        isUserBanned={isUserBanned}
        showConfirm={showConfirm}
      />
    );
  };

  getCollection = () => {
    return this.props.orderHistoryCollection;
  };

  clearCollection = () => {
    return this.props.clearOrderHistoryCollection;
  };
}

export const OrderHistoryList = communicationCustomer.injector(
  communicationCart.injector(communicationOrderHistory.injector(OrderHistoryListComponent))
);
