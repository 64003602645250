import React, { memo } from 'react';
import { List, Row } from 'antd';
import { IOrderItemModel } from 'entities/Orders/Orders.models';
import { OrderItemAddOnsListItem } from 'entities/Orders/components/OrderItemAddOnsListItem';
import { ECartPromoBonusType } from 'entities/Cart/Cart.models';

interface IComponentProps {
  item: IOrderItemModel;
  promoBonusType?: ECartPromoBonusType;
}

const OrderItemListItemComponent: React.FC<IComponentProps> = props => {
  const { item, promoBonusType } = props;
  const { assetItemData, addOns, total, quantity, discountTotal, isPromo } = item;
  const { name } = assetItemData;

  const isPromoDiscountedItem = isPromo && promoBonusType === ECartPromoBonusType.DiscountMenuItem;
  const isPromoAdditionalItem = isPromo && promoBonusType === ECartPromoBonusType.AdditionalMenuItem;

  return (
    <div className="mb-6">
      <Row className="font-weight-7 fs-xs lh-sm" wrap={false} justify="space-between">
        <div className="word-break">
          {quantity || 0}x {name}
        </div>
        {isPromoDiscountedItem && (
          <Row justify="space-between" wrap={false}>
            <div className="ml-2 t-decoration-line-through">P{total || 0}</div>
            <div className="ml-4 color-orange-main">P{discountTotal || 0}</div>
          </Row>
        )}
        {isPromoAdditionalItem && <div className="ml-2 color-orange-main">P0</div>}
        {!isPromo && <div className="ml-2">P{total || 0}</div>}
      </Row>

      <List
        className="list-without-empty"
        dataSource={addOns}
        renderItem={addOn => <OrderItemAddOnsListItem addOn={addOn} itemQuantity={quantity} />}
      />
    </div>
  );
};

export const OrderItemListItem = memo(OrderItemListItemComponent);
