import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  onContinue(store: IStoreModel): void;
}

type AllProps = IComponentProps & IUIConnectedProps;

const ChangeStoreConfirmModalComponent: React.FC<AllProps> = props => {
  const { uiChangeStoreConfirmModal, closeUiChangeStoreConfirmModal, onContinue } = props;
  const { data } = uiChangeStoreConfirmModal;
  const { isVisible, selectedStore } = data || {};
  const header = 'One or more items in your order may not be available in this store, or may be available at a different price.';
  const subHeader = 'Unavailable items will be removed from your Bag and prices will be updated.';

  const submit = () => {
    selectedStore && onContinue(selectedStore);
    closeUiChangeStoreConfirmModal();
  };

  return (
    <ModalContent header={header} subHeader={subHeader} close={() => closeUiChangeStoreConfirmModal()} visible={!!isVisible}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={submit}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={() => closeUiChangeStoreConfirmModal()}>
        Cancel
      </Button>
    </ModalContent>
  );
};

export const ChangeStoreConfirmModal = communicationUI.injector(ChangeStoreConfirmModalComponent);
