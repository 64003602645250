import moment from 'moment';

export const dateFormatterToUTC = (date: string, dateFormat: string = 'DD MMM YYYY, HH:mm') =>
  moment.utc(date, dateFormat).format();
export const dateDifference = (firstDate: string, secondDate: string) => moment(firstDate).diff(secondDate);

export const secondsToStringTime = (seconds: number): string => {
  return moment()
    .startOf('hour')
    .second(seconds)
    .format('mm:ss');
};
