import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosInstance } from 'axios';
import { IConfirmPhoneCodeParams } from 'entities/CustomersPhones/CustomersPhones.models';

class CustomersPhonesTransport extends BaseHttpTransport<AxiosInstance, {}> {
  public activate = (params: IConfirmPhoneCodeParams) => {
    return axios.put(`${this.path}/activate`, params);
  };
}

export const customersPhonesTransport = new CustomersPhonesTransport('/customers-phones', axios);
