import React from 'react';
import { List, Row } from 'antd';
import { IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  store: IStoreModel;
  onSelect(store: IStoreModel): void;
}

export const StoresListItem: React.FC<IComponentProps> = props => {
  const { store, onSelect } = props;
  const { storeName, address } = store;
  const city = address?.city || 'Unknown city';
  const region = address?.region || 'Unknown region';
  const street = address?.street || 'Unknown street';
  const house = address?.house || 'Unknown house';

  return (
    <List.Item onClick={() => onSelect(store)} className="cursor-pointer">
      <List.Item.Meta
        title={
          <>
            {storeName && (
              <div className="basic__text_title mb-2">
                <Row justify="space-between" align="middle">
                  <span>{storeName}</span>
                  {store.distance && <span>{`${store.distance} km`}</span>}
                </Row>
              </div>
            )}

            <div className="basic__text_title">
              {city}. {region}
            </div>
          </>
        }
        description={
          <div className="fs-xs lh-sm">
            {street}. {house}
          </div>
        }
      />
    </List.Item>
  );
};
