import axios from 'axios';
import { IParamWithCallback } from 'common/models/requestModels';
import {
  IAuthModel,
  IAuthParams,
  IPasswordRestoreParams,
  IPasswordForgotParams,
  ICheckTokenData,
  IAuthRefreshParams
} from 'entities/Auth/Auth.models';

const basePath = '/auth';
const passwordRestore = '/restore-password';

export const authTransport = {
  refresh: (params: IAuthRefreshParams): Promise<IAuthModel> => axios.put(`${basePath}`, params),
  login: (params: IParamWithCallback<IAuthParams>): Promise<IAuthModel> => axios.post(`${basePath}`, params.params),
  logout: (): Promise<any> => axios.delete(`${basePath}`),
  passwordRestore: (params: IPasswordForgotParams): Promise<any> => axios.post(`${passwordRestore}`, params),
  passwordRestoreNew: (data: IPasswordRestoreParams): Promise<any> => axios.put(`${passwordRestore}`, data),
  passwordRestoreCheckToken: (data: ICheckTokenData): Promise<any> => axios.post(`${passwordRestore}/check-token`, data)
};
