import { IMenuAddOn, IMenuItem } from 'entities/Menu/Menu.models';
import { ICartAddItemAddOnParams, ICartAddItemParams, ICartItemAddOnModel, ICartItemModel } from 'entities/Cart/Cart.models';

export const mapMenuAddonToCartAddItemAddonParams = (
  { menuItemId, price }: IMenuAddOn,
  quantity: number = 0
): ICartAddItemAddOnParams => ({
  price,
  quantity,
  menuItemId
});

export const mapMenuItemToCartAddItemParams = ({ menuItemId, price }: IMenuItem, quantity: number = 1): ICartAddItemParams => ({
  price,
  quantity,
  menuItemId,
  addOns: []
});

export const mapCartItemAddonToCartAddItemAddonParams = ({
  quantity,
  menuItemId,
  price
}: ICartItemAddOnModel): ICartAddItemAddOnParams => ({
  quantity,
  menuItemId,
  price
});

export const mapCartItemToCartAddItemParams = (cartItem: ICartItemModel): ICartAddItemParams => ({
  ...cartItem,
  addOns: cartItem.addOns.map(mapCartItemAddonToCartAddItemAddonParams)
});
