import React from 'react';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ESocialLinkModals } from 'entities/UI/UI.models';
import { SocialLinkErrorsModal } from 'entities/User/components/SocialLinkErrorsModal';
import { SocialUnlinkConfirmModal } from 'entities/User/components/SocialUnlinkConfirmModal';

type AllProps = IUIConnectedProps;

const SocialLinkModalComponent: React.FC<AllProps> = props => {
  const { uiSocialLinkModal, closeUiSocialLinkModal } = props;
  const isVisible = uiSocialLinkModal.data?.isVisible;
  const modalType = uiSocialLinkModal.data?.type;
  const errorCode = uiSocialLinkModal.data?.errorCode;
  const socialType = uiSocialLinkModal.data?.socialType;
  const isLinkErrorsModal = modalType === ESocialLinkModals.LinkErrors;
  const isUnlinkConfirmModal = modalType === ESocialLinkModals.UnlinkConfirm;
  const isLinkErrorsModalVisible = isVisible && isLinkErrorsModal;
  const isUnlinkConfirmModalVisible = isVisible && isUnlinkConfirmModal;

  return (
    <>
      <SocialLinkErrorsModal
        isVisible={!!isLinkErrorsModalVisible}
        errorCode={errorCode}
        onClose={() => closeUiSocialLinkModal()}
      />
      <SocialUnlinkConfirmModal
        isVisible={!!isUnlinkConfirmModalVisible}
        socialType={socialType}
        onClose={() => closeUiSocialLinkModal()}
      />
    </>
  );
};

export const SocialLinkModal = communicationUI.injector(SocialLinkModalComponent);
