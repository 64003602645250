import { IGDLProduct } from 'common/models/gdlModels';
import { IBaseCartItemModel, ICartItemModel } from 'entities/Cart/Cart.models';

const addOrUpdateIfExists = (item: IBaseCartItemModel, data: IBaseCartItemModel[]) => {
  const storedItem = data.find(existItem => existItem.menuItemToAssetId === item.menuItemToAssetId);
  if (!storedItem) {
    data.push(item);
  } else {
    storedItem.quantity += item.quantity;
  }
};

export const mapCartItemsToGDLProducts = (items: ICartItemModel[]): IGDLProduct[] => {
  const data: ICartItemModel[] = [];

  items.forEach(item => {
    addOrUpdateIfExists(item, data);
    item?.addOns?.forEach(addOn => addOrUpdateIfExists(addOn, data));
  });

  return data.map(mapCartItemToGDLProduct);
};

export const mapCartItemToGDLProduct = ({
  name,
  quantity,
  price,
  categoryName,
  menuItemToAssetId
}: ICartItemModel): IGDLProduct => ({
  quantity,
  price,
  name,
  variant: categoryName,
  id: menuItemToAssetId
});
