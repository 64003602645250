import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { Header } from 'common/components/Header/Header';
import { ERoutesCommon, ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { PermissionLayout } from 'common/components/Layouts/PermissionLayout';
import { getStoreShortId } from 'common/helpers/Store.helper';
import { CenterLayout } from 'common/components/Layouts/CenterLayout';
import { ScanPage } from 'app/pages/ScanPage';
import { RootPage } from 'app/pages/RootPage';
import { PrivacyPolicyPage } from 'app/pages/PrivacyPolicyPage';
import { TosPage } from 'app/pages/TosPage';
import { SocialAuthPage } from 'app/pages/SocialAuthPage';
import { CookieBlockedPage } from 'app/pages/CookieBlockedPage';
import { MainPage } from 'app/pages/MainPage';
import { TechnicalWorkPage } from 'app/pages/TechnicalWorkPage';
import { OrderHistoryPage } from 'app/pages/OrderHistoryPage';
import { RateStorePage } from 'app/pages/RateStorePage';
import { PersonalPromoPage } from 'app/pages/PersonalPromoPage';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import '@axmit/mb-api';
import { ForgotPasswordPage, Logout, PasswordRestorePage } from 'entities/Auth/components';
import { LoginPage } from 'entities/Auth/components/LoginPage';
import { EmailConfirm } from 'entities/Auth/components/EmailConfirm';
import { OrderPage } from 'entities/Orders/components/OrderPage';
import { SettingsPage } from 'entities/User/components/SettingsPage';
import { SignupPage } from 'entities/Auth/components/SignupPage';
import { BagPage } from 'entities/Orders/components/BagPage';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { AuthModal } from 'entities/UI/components/AuthModal';
import { CompleteRegistrationPage } from 'entities/Auth/components/CompleteRegistrationPage';

type AllProps = IAuthConnectedProps & RouteComponentProps & IStoreConnectedProps;

class AppComponent extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.props.initAuthModel();

    const storeShortId = getStoreShortId();
    if (storeShortId) {
      this.props.getByShortIdStoresModel(storeShortId);
    } else {
      this.props.initStoresModel();
    }
  }

  render() {
    return (
      <Switch>
        <Route path={ERoutesCommon.TechnicalWork} component={TechnicalWorkPage} />
        <Route path={ERoutesCommon.CookieBlocked} component={CookieBlockedPage} />

        <Route path={`${ERoutesCommon.Root}*`}>
          <Header />

          <CenterLayout>
            <PermissionLayout
              publicRoutes={
                <Switch>
                  <Route path={ERoutesCommon.PasswordForgot} component={ForgotPasswordPage} />
                  <Route path={ERoutesCommon.PasswordRestore} component={PasswordRestorePage} />
                  <Route path={ERoutesCommon.EmailConfirm} component={EmailConfirm} />
                  <Route path={ERoutesPublic.Login} component={LoginPage} />
                  <Route path={ERoutesPublic.Signup} component={SignupPage} />
                  <Route path={ERoutesPublic.SocialAuth} component={SocialAuthPage} />
                  <Route path={ERoutesCommon.Logout} component={Logout} />
                  <Route path={ERoutesCommon.Privacy} component={PrivacyPolicyPage} />
                  <Route path={ERoutesCommon.ToS} component={TosPage} />
                  <Route path={ERoutesCommon.Main} component={MainPage} />
                  <Route path={ERoutesCommon.ScanPage} component={ScanPage} />
                  <Route path={`${ERoutesCommon.Root}*`} component={RootPage} />
                </Switch>
              }
              privateRoutes={
                <Switch>
                  <Route path={ERoutesCommon.PasswordRestore} component={PasswordRestorePage} />
                  <Route path={ERoutesCommon.EmailConfirm} component={EmailConfirm} />
                  <Route path={ERoutesPrivate.Order} component={OrderPage} />
                  <Route path={ERoutesPrivate.Bag} component={BagPage} />
                  <Route path={ERoutesPrivate.Settings} component={SettingsPage} />
                  <Route path={ERoutesPrivate.OrderHistory} component={OrderHistoryPage} />
                  <Route path={`${ERoutesPrivate.RateStore}/:id`} component={RateStorePage} />
                  <Route path={ERoutesCommon.Privacy} component={PrivacyPolicyPage} />
                  <Route path={ERoutesCommon.ToS} component={TosPage} />
                  <Route path={ERoutesCommon.Logout} component={Logout} />
                  <Route path={ERoutesCommon.Main} component={MainPage} />
                  <Route path={ERoutesCommon.ScanPage} component={ScanPage} />
                  <Route path={ERoutesPrivate.PersonalPromo} component={PersonalPromoPage} />
                  <Route path={`${ERoutesCommon.Root}*`} component={RootPage} />
                </Switch>
              }
              nonCompletedUserRoutes={
                <Switch>
                  <Route path={ERoutesPrivate.CompleteRegistration} component={CompleteRegistrationPage} />
                  <Route path={`${ERoutesCommon.Root}*`}>
                    <Redirect to={ERoutesPrivate.CompleteRegistration} />
                  </Route>
                </Switch>
              }
            />
          </CenterLayout>
          <AuthModal />
        </Route>
      </Switch>
    );
  }
}

export const App = communicationStore.injector(communicationAuth.injector(withRouter(AppComponent)));
