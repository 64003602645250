import React from 'react';
import { Button } from 'antd';
import { ReactComponent as FacebookIcon } from 'app/assets/images/facebook.svg';

interface IComponentProps {
  text: string;
  onClick(): void;
  disabled?: boolean;
}

export const FacebookButton: React.FC<IComponentProps> = props => {
  const { disabled, text, onClick } = props;

  return (
    <Button
      className="fs-sm font-weight-bold d-flex width-full d-flex_align-center social-bar__facebook-btn"
      disabled={!!disabled}
      onClick={onClick}
    >
      <FacebookIcon className="social-bar__icon" />
      <span title={text}>{text}</span>
    </Button>
  );
};
