import React, { memo } from 'react';
import { Collapse, Row, Col, Divider, Button, List } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getMapLinkToStore, getStoreAddressString } from 'common/helpers/Store.helper';
import { ORDERS_PICKUP_DATE_FORMAT } from 'common/consts/Orders.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { StoreRating } from 'common/components/StoreRating';
import { ReactComponent as Chevron } from 'app/assets/images/orderHistoryChevron.svg';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';
import { IOrderHistoryAddReorderParams, IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';
import { ECommonRejectReasons, EOrderStatus } from 'entities/Orders/Orders.models';
import { OrderHistoryMenuItem } from 'entities/OrderHistory/components/OrderHistoryMenuItem';
import { isOrderRateTimeExpired } from 'entities/OrderHistory/OrderHistory.helpers';
import { getOrderStatusMessage } from 'entities/Orders/Orders.helpers';

interface IComponentProps {
  order: IOrderHistoryModel;
  addReorder(params: IOrderHistoryAddReorderParams): void;
  showConfirm(item: IOrderHistoryModel): void;
  isBagEmpty: boolean;
  isUserBanned: boolean;
}

const OrderHistoryItemComponent: React.FC<IComponentProps> = props => {
  const { order, addReorder, isBagEmpty, showConfirm, isUserBanned } = props;
  const { id, code, createdAt, total, store, pickUpTime, status, items, pickedUpAt, isRatingExist, rejectReason } = order;
  const { storeName } = store;
  const map = getMapLinkToStore(store);
  const address = getStoreAddressString(store);
  const statusText = getOrderStatusMessage(status as EOrderStatus, rejectReason as ECommonRejectReasons);
  const showRateStoreButton =
    !isUserBanned && order.status === EOrderStatus.Finished && !isOrderRateTimeExpired(order) && !isRatingExist;

  const totalTitle = `P${total}`;
  const createdAtDateTitle = formatTime(moment(createdAt), ORDERS_PICKUP_DATE_FORMAT);
  const orderPickupTimeTitle = formatTime(moment(pickUpTime), ORDERS_PICKUP_DATE_FORMAT);
  const pickedUpAtTitle = formatTime(moment(pickedUpAt), ORDERS_PICKUP_DATE_FORMAT);

  const history = useHistory();
  const handleRateClick = () => {
    history.push(`${ERoutesPrivate.RateStore}/${id}`);
  };

  const handleReorderClick = () => {
    isBagEmpty && addReorder({ id, storeId: store.id, storeName });
    !isBagEmpty && showConfirm(order);
  };

  return (
    <Collapse
      expandIconPosition="right"
      className="order-history-item mb-4"
      expandIcon={({ isActive }) => (
        <Chevron className={`order-history-item__chevron ${isActive ? 'order-history-item__chevron_rotate--180' : ''}`} />
      )}
    >
      <Collapse.Panel
        className="order-history-item__panel pt-3 pb-3"
        key={id}
        header={
          <>
            <Row className="mb-3">
              <Col className="basic__text_title" title={code}>
                {code}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <div className="fs" title="Placed at">
                  Placed at:
                </div>
                <div className="basic__text_title fs-xxs" title={createdAtDateTitle}>
                  {createdAtDateTitle}
                </div>
              </Col>
              <Col>
                <div className="fs" title="Total">
                  Total:
                </div>
                <div className="basic__text_title fs-xxs" title={totalTitle}>
                  {totalTitle}
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <div className="basic__text_title" title={storeName}>
          <span>{storeName}</span>
          <StoreRating store={store} className="ml-3" />
        </div>
        <div className="fs mb-4" title={address}>
          {address}
        </div>
        <a target="_blank" rel="noopener noreferrer" href={map} className="link fs-xs color-orange-main">
          Store location
        </a>
        <Divider className="bg-color-grayLight mt-6 mb-6" />
        <div className="fs" title="Order pickup time">
          Order pickup time:
        </div>
        <div className="basic__text_title fs-xxs" title={orderPickupTimeTitle}>
          {orderPickupTimeTitle}
        </div>
        {pickedUpAt && (
          <>
            <div className="fs mt-4" title="Picked up at">
              Picked up at:
            </div>
            <div className="basic__text_title fs-xxs" title={pickedUpAtTitle}>
              {pickedUpAtTitle}
            </div>
          </>
        )}
        <Divider className="bg-color-grayLight mt-6 mb-6" />
        <Row justify="space-between">
          <Col className="basic__text_title" title="Status">
            Status
          </Col>
          <Col className="basic__text_title color-orange-main" title={statusText}>
            {statusText}
          </Col>
        </Row>
        <Divider className="bg-color-grayLight mt-6 mb-6" />
        <List className="list-without-empty" dataSource={items} renderItem={item => <OrderHistoryMenuItem item={item} />} />
        <Divider className="bg-color-grayLight mb-9" />
        <Button size="large" block className="btn" type="primary" onClick={handleReorderClick}>
          Re-order now
        </Button>
        {showRateStoreButton && (
          <Button size="large" block className="btn mt-7 mb-3" onClick={handleRateClick}>
            Rate Your Order
          </Button>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export const OrderHistoryItem = memo(OrderHistoryItemComponent);
