import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { getStoreAddressString } from 'common/helpers/Store.helper';
import { StoreRating } from 'common/components/StoreRating';
import iconMarker from 'app/assets/images/marker.svg';
import iconChevron from 'app/assets/images/chevron.svg';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { EStoreState } from 'entities/Store/Store.models';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IAuthConnectedProps & ICustomerConnectedProps & IStoreConnectedProps & IUIConnectedProps;

const StorePanelComponent: React.FC<AllProps> = props => {
  const { customerModel, storesModel, openUiCommonModal } = props;

  const { data: customer } = customerModel;
  const store = customer?.store || storesModel.data || undefined;

  const isStoreSelected = !!store;
  const storeAddress = getStoreAddressString(store);
  const storeStatusText = store ? `Store is ${store.state}` : '';
  const storeName = store?.storeName || '';
  const hasOpenTime = store?.openTime;
  const openTime = moment(store?.openTime || '00:00', 'HH:mm');
  const closeTime = moment(store?.closeTime || '00:00', 'HH:mm');
  const storeOpenCloseText =
    store?.state === EStoreState.Online ? ` — Closes at ${formatTime(closeTime)}` : ` — Opens at ${formatTime(openTime)}`;

  return (
    <div className="store-panel">
      <div className="store-panel__container" onClick={() => openUiCommonModal()}>
        <Row align="middle" className="flex-noWrap">
          <Col>
            <img src={iconMarker} alt="marker" className={`mr-5 store_${store?.state}`} />
          </Col>
          <Col>
            {isStoreSelected ? (
              <div className="mr-5">
                <div className="fs-xxs font-weight-5 color-default o-md lh-sm mb-1">
                  <span title={storeName}>{storeName}</span>
                  <StoreRating store={store} className="ml-3" />
                </div>
                <div className="fs-xxs color-default o-md lh-sm mb-1">{storeAddress}</div>
                <div className="fs color-default lh-sm">
                  <b className={`store_${store?.state}`}>{storeStatusText}</b> {hasOpenTime && storeOpenCloseText}
                </div>
              </div>
            ) : (
              <div className="mr-5">Please select a pick-up store</div>
            )}
          </Col>
          <Col>
            <img src={iconChevron} alt="chevron" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const StorePanel = communicationUI.injector(
  communicationStore.injector(communicationCustomer.injector(communicationAuth.injector(StorePanelComponent)))
);
