import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { IError, EErrorStatus } from '@axmit/error-helper';
import { IApplicationState } from 'app/store/reducers';
import { customersPhonesTransport } from 'entities/CustomersPhones/CustomersPhones.transport';
import {
  IConfirmPhoneParams,
  IConfirmPhoneCodeParams,
  ECustomerPhoneErrorMessage,
  ECustomerPhoneSuccessMessage
} from 'entities/CustomersPhones/CustomersPhones.models';
import { getCustomerModel } from 'entities/Customer/Customer.communication';
import { ECustomerErrorCode } from 'entities/Customer/Customer.models';

const namespace = 'customersPhones';

export interface ICustomersPhonesConnectedProps {
  customersPhonesModel: StoreBranch<{}, IConfirmPhoneParams>;
  addCustomersPhonesModel(params: IConfirmPhoneParams): void;
  clearCustomersPhonesModel(): void;

  customersPhonesCode: StoreBranch<{}, IConfirmPhoneCodeParams>;
  addCustomersPhonesCode(params: IConfirmPhoneCodeParams): void;
  clearCustomersPhonesCode(): void;
}

const ModelAPIProviders = [
  new APIProvider<{}, IConfirmPhoneParams>(EActionsTypes.add, customersPhonesTransport.add, {
    mapParams: originalParams => originalParams?.params,
    onSuccess: function(response, originalParams) {
      originalParams?.onSuccess && originalParams.onSuccess();
    },
    onFail: function(response, originalParams) {
      if (response.status === EErrorStatus.Validation && response.data.code === ECustomerErrorCode.CodeSendingError) {
        originalParams?.onFail && originalParams.onFail();
      }
    }
  })
];

const CodeAPIProviders = [
  new APIProvider<{}, IConfirmPhoneCodeParams>(EActionsTypes.add, customersPhonesTransport.activate, {
    mapParams: originalParams => originalParams?.params,
    onSuccess: function*(response, originalParams, branchState, fullState: IApplicationState) {
      originalParams?.onSuccess && originalParams.onSuccess();
      message.success(ECustomerPhoneSuccessMessage.Activated);

      const userId = fullState?.auth.model?.data?.access?.userId || '';
      yield userId && getCustomerModel(userId);
    },
    onFail: function(response: IError) {
      if (response.status === EErrorStatus.NotFound) {
        message.error(ECustomerPhoneErrorMessage.InvalidCode);
      }
    }
  })
];

const branches = [new Branch('model', ModelAPIProviders), new Branch('code', CodeAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCustomersPhones = buildCommunication<ICustomersPhonesConnectedProps>(strategy);
