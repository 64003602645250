import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IParamWithCallback } from 'common/models/requestModels';
import {
  ICustomerCompleteRegistrationParams,
  ICustomerModel,
  ICustomerSetPasswordParams,
  ICustomerSetStoreParams,
  ICustomerUpdatePasswordParams
} from 'entities/Customer/Customer.models';

const passwordPath = 'password';
const setPasswordPath = 'set-password';
const setStorePath = 'set-store';
const completeRegistrationPath = 'complete-registration';
const disconnectFacebookPath = '/auth/disconnect-facebook';
const disconnectGooglePath = '/auth/disconnect-google';

class CustomerTransport extends BaseHttpTransport<AxiosInstance, ICustomerModel> {
  public setPassword = ({ id, password }: ICustomerSetPasswordParams) =>
    axios.put(`${this.path}/${id}/${setPasswordPath}`, { password });
  public updatePassword = ({ id, password, oldPassword }: ICustomerUpdatePasswordParams) =>
    axios.put(`${this.path}/${id}/${passwordPath}`, { password, oldPassword });
  public setStore = ({ params: { id, store } }: IParamWithCallback<ICustomerSetStoreParams>) =>
    axios.put(`${this.path}/${id}/${setStorePath}`, { store });
  public completeRegistration = ({ id, ...rest }: ICustomerCompleteRegistrationParams) =>
    axios.post(`${this.path}/${id}/${completeRegistrationPath}`, rest);
  public disconnectFacebook = () => axios.put(`${disconnectFacebookPath}`);
  public disconnectGoogle = () => axios.put(`${disconnectGooglePath}`);
}

export const customerTransport = new CustomerTransport('/customers', axios);

export const confirmationTransport = new BaseHttpTransport('customers/email-confirm', axios);
