import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IBasePathParams } from 'common/models/requestModels';
import { IAuthModel, IAuthParams, ICheckTokenData } from 'entities/Auth/Auth.models';
import { IUserConfirmParams, IUserModel, IUserUpdateModel, IUpdatePasswordParams } from 'entities/User/User.models';

const basePath = '/users';

export const userTransport = {
  get: (path: IBasePathParams): Promise<IUserModel> => axios.get(`${basePath}/${path.id}`),
  update: (params: { path: IBasePathParams; data: IUserUpdateModel }): Promise<IUserModel> =>
    axios.put(`${basePath}/${params.path.id}`, params.data),

  registration: (params: IAuthParams): Promise<IAuthModel> => axios.post(`${basePath}`, params),
  confirm: (params: IUserConfirmParams): Promise<void> => axios.post(`${basePath}/confirm`, params),
  checkInviteToken: (data: ICheckTokenData): Promise<any> => axios.post(`${basePath}/check-token`, data)
};

class UsersTransport extends BaseHttpTransport<AxiosInstance, IUserModel> {
  public changePassword = ({ id, ...rest }: IUpdatePasswordParams) => {
    return axios.put(`${this.path}/${id}/password`, rest);
  };
}

export const usersTransport = new UsersTransport('/users', axios);
