import React, { useCallback, useEffect, useMemo } from 'react';
import { Spin, List } from 'antd';
import { getStoreShortId } from 'common/helpers/Store.helper';
import { IMenuConnectedProps, communicationMenu } from 'entities/Menu/Menu.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EStoreErrorCode, EStoresLSStatus, IStoreModel } from 'entities/Store/Store.models';
import { MenuDetailsModal } from 'entities/UI/components/MenuDetailsModal';
import { MenuCategoriesListItem } from 'entities/Menu/components/MenuCategoriesListItem';
import { StorePickerModal } from 'entities/Store/components/StorePickerModal';
import { IMenuItemWithCategoryNameAndType } from 'entities/Menu/Menu.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ChangeStoreConfirmModal } from 'entities/Store/components/ChangeStoreConfirmModal';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';

type AllProps = IMenuConnectedProps &
  ICustomerConnectedProps &
  IStoreConnectedProps &
  IAuthConnectedProps &
  IUIConnectedProps &
  ICartConnectedProps;

const MenuPageComponent: React.FC<AllProps> = props => {
  const {
    getMenuCollection,
    menuCollection,
    customerModel,
    getMenuAddOns,
    getDefaultMenuCollection,
    storesModel,
    authModel,
    storesLsStatus
  } = props;
  const { data, loading } = menuCollection;
  const { categoriesToAsset: menu } = data || {};

  const isDefaultMenu = useMemo(() => {
    const { data: customer } = customerModel;
    const { data: store } = storesModel;

    return !customer?.store?.id && !store?.id;
  }, [customerModel, storesModel]);

  useEffect(() => {
    const { data: authData, loading: authLoading } = authModel;
    const { data: customerData, loading: customerLoading } = customerModel;
    const { data: storesData, loading: storesLoading, errors: storesErrors } = storesModel;
    const { data: lsStatusData, loading: lsStatusLoading } = storesLsStatus;

    const customerStoreId = customerData?.store?.id;
    const storeId = storesData?.id;
    const authorized = authData && Object.keys(authData).length !== 0;
    const dataLoading = authLoading || customerLoading || storesLoading || lsStatusLoading;
    const incorrectStoreCodeError = storesErrors?.data?.code === EStoreErrorCode.NotFound;
    const isStoreInfoUpdated = lsStatusData === EStoresLSStatus.Updated;

    const isNonAuthUserWithoutStore = !authorized && !customerData?.userId && !storesData && !dataLoading;
    const isNonAuthUserWithStore = storeId && !authorized && !customerData?.userId && !dataLoading;
    const isAuthUserWithoutStore = authorized && customerData?.userId && !customerStoreId && !dataLoading;
    const isAuthUserWithStore = customerStoreId && authorized && !dataLoading;

    const storeShortId = getStoreShortId();

    const needResetAuthUserStoreFromLS = (isAuthUserWithoutStore || isAuthUserWithStore) && storeId;
    const needResetAuthUserStoreFromCode = (isAuthUserWithStore || isAuthUserWithoutStore) && storeShortId;

    if (incorrectStoreCodeError || needResetAuthUserStoreFromLS || needResetAuthUserStoreFromCode) {
      return;
    }

    if (isNonAuthUserWithoutStore || isAuthUserWithoutStore) {
      getDefaultMenuCollection();
    }

    if (isAuthUserWithStore) {
      customerStoreId && getMenuCollection(customerStoreId);
      customerStoreId && getMenuAddOns(customerStoreId);
    }

    if (isNonAuthUserWithStore && isStoreInfoUpdated) {
      storeId && getMenuCollection(storeId);
      storeId && getMenuAddOns(storeId);
    }
  }, [authModel, getDefaultMenuCollection, customerModel, getMenuAddOns, getMenuCollection, storesModel, storesLsStatus]);

  const { openUiCommonModal, openUiCartItemModal } = props;
  const handleMenuItemClick = useCallback(
    (item: IMenuItemWithCategoryNameAndType) => {
      if (isDefaultMenu) {
        openUiCommonModal();
      } else {
        openUiCartItemModal({ menuItem: item });
      }
    },
    [openUiCommonModal, openUiCartItemModal, isDefaultMenu]
  );

  const handleChangeStoreConfirm = (store: IStoreModel) => {
    changeStore(store);
  };

  const { cartModel, openUiChangeStoreConfirmModal, setStoreCustomerModel, getStoresModel } = props;
  const changeStore = (newStore: IStoreModel) => {
    const { id: newStoreId } = newStore;
    const { data: customer } = customerModel;
    const { store: customerStore, userId } = customer || {};
    const { data: store } = storesModel;
    const finalStore = customerStore || store;
    const isSameStore = finalStore && finalStore.id === newStoreId;

    if (userId && !isSameStore) {
      setStoreCustomerModel({ params: { id: userId, store: newStoreId } });
    }
    if (!userId && !isSameStore) {
      getStoresModel(newStoreId);
    }
  };

  const handleSelectStore = (selectedStore: IStoreModel) => {
    const { data: cart } = cartModel;
    const isCartEmpty = !cart || cart?.items?.length === 0;

    if (!isCartEmpty) {
      openUiChangeStoreConfirmModal({ selectedStore });
      return;
    }

    changeStore(selectedStore);
  };

  return (
    <>
      <Spin spinning={loading} className="mt-2">
        <List
          dataSource={menu}
          renderItem={category => (
            <MenuCategoriesListItem category={category} isDefaultMenu={isDefaultMenu} onMenuItemClick={handleMenuItemClick} />
          )}
        />
      </Spin>
      <MenuDetailsModal />
      <StorePickerModal onSelect={handleSelectStore} />
      <ChangeStoreConfirmModal onContinue={handleChangeStoreConfirm} />
    </>
  );
};

export const MenuPage = communicationCart.injector(
  communicationUI.injector(
    communicationAuth.injector(
      communicationStore.injector(communicationCustomer.injector(communicationMenu.injector(MenuPageComponent)))
    )
  )
);
