import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosInstance } from 'axios';
import { IParamWithCallback } from 'common/models/requestModels';
import { ICartAddItemParams, ICartAddPromoCodeParams, ICartModel, ICartUpdateItemParams } from 'entities/Cart/Cart.models';

const basePath = '/carts/default';
const itemsPath = 'items';
const promoCodePath = 'promo-code';

class CartTransport extends BaseHttpTransport<AxiosInstance, ICartModel> {
  public getCart = () => this.axios.get(`${this.path}`);
  public addItem = (params: IParamWithCallback<ICartAddItemParams>) =>
    this.axios.post(`${this.path}/${itemsPath}`, params.params);
  public updateItem = ({ id, ...rest }: ICartUpdateItemParams) => this.axios.put(`${this.path}/${itemsPath}/${id}`, rest);
  public deleteItem = (id: string) => this.axios.delete(`${this.path}/${itemsPath}/${id}`);
  public addPromoCode = (params: ICartAddPromoCodeParams) => this.axios.post(`${this.path}/${promoCodePath}`, params);
  public deletePromoCode = () => this.axios.delete(`${this.path}/${promoCodePath}`);
}

export const cartTransport = new CartTransport(basePath, axios);
