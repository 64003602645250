import { APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch, EActionsTypes } from '@axmit/redux-communications';
import { IError } from '@axmit/error-helper';
import { RequestLoadingHelper } from 'common/helpers/requestLoading.helper';
import { IQRModelData } from 'entities/QR/QR.models';
import { qrTransport } from 'entities/QR/QR.transport';

const namespace = 'qr';

export interface IQrConnectedProps {
  qrModel: StoreBranch<IQRModelData, null, IError>;
  getQrModel(name: string): void;
}

const ModelApiProvider = [
  new APIProvider(EActionsTypes.get, qrTransport.getQr, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const branches = [new Branch('model', ModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationQR = buildCommunication<IQrConnectedProps>(strategy);
