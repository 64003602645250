import { IError } from '@axmit/error-helper';
import { currentOrderStatus, ECommonRejectReasons, EOrdersErrorCode, EOrderStatus } from 'entities/Orders/Orders.models';
import { ICartItemModel } from 'entities/Cart/Cart.models';

interface IUnavailableItemsData {
  isContainUnavailableItems: boolean;
  unavailableItems?: ICartItemModel[];
}

// TODO: Looks like we need small refactoring here
export const isUnavailableError = (error?: IError | null): boolean =>
  error?.data?.code === EOrdersErrorCode.UnavailableItemInOrder;

// TODO: Add item to IErrorData on backend side to remove @ts-ignore
export const hasUnavailableItemsInError = (error?: IError | null): IUnavailableItemsData =>
  error?.data.error === 'CartHasUnavailableItemsException'
    ? // @ts-ignore-next-line
      { isContainUnavailableItems: true, unavailableItems: error?.data?.items }
    : { isContainUnavailableItems: false };

export const getOrderStatusMessage = (status?: EOrderStatus, reason?: ECommonRejectReasons): string => {
  if (!status) {
    return '';
  }

  if (status === EOrderStatus.RejectedByStore && reason && reason === ECommonRejectReasons.Abandoned) {
    return 'Not picked up by Customer';
  }

  return currentOrderStatus[status] || '';
};
