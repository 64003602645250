import React from 'react';
import { Button, Col, Row } from 'antd';
import { IPromoItem } from 'entities/Promo/Promo.models';
import { ECartPromoType } from 'entities/Cart/Cart.models';

interface IComponentProps {
  promoItem: IPromoItem;
  isActive?: boolean;
  onClick: (code?: string) => void;
  loading: boolean;
}

type AllProps = IComponentProps;

export const PromoCard: React.FC<AllProps> = ({ promoItem, isActive, onClick, loading }) => {
  const { name, description, code } = promoItem;

  const cardFooter = isActive ? (
    <Row justify="space-between">
      <Col span={12} className="fs-xs color-orange-main font-weight-7">
        Applied to the current order
      </Col>
      <Col>
        {promoItem.type === ECartPromoType.Code && (
          <Button className="btn btn_red" onClick={() => onClick()} disabled={loading}>
            Use later
          </Button>
        )}
      </Col>
    </Row>
  ) : (
    <Button size="large" block className="btn" type="primary" onClick={() => code && onClick(code)} disabled={loading}>
      Apply
    </Button>
  );

  return (
    <div className="menu-card mb-7">
      <div className="font-weight-7 fs-xs mb-3">{name}</div>
      <div className="mb-8">{description}</div>
      {cardFooter}
    </div>
  );
};
