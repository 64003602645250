import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { storesListTransport } from 'entities/Store/Store.transport';
import { IStoreCollectionParams, IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {}

export class StoreSelector extends AbstractSearchableSelector<IStoreModel, IComponentProps> {
  getItemLabel = (item: IStoreModel): string => {
    return item?.storeName || 'Unknown store';
  };

  loadData = (value?: string): Promise<{ data: IStoreModel[] }> => {
    const filter: IStoreCollectionParams = {};
    if (value) {
      filter.customerSearch = value;
    }
    return storesListTransport.getCollection(filter);
  };
}
