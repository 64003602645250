import React from 'react';
import { Col, Row } from 'antd/es/grid';
import { List } from 'antd';
import { IOrderHistoryMenuItem } from 'entities/OrderHistory/OrderHistory.models';
import { OrderHistoryAddOnListItem } from 'entities/OrderHistory/components/OrderHistoryAddOnListItem';

interface IComponentProps {
  item: IOrderHistoryMenuItem;
}

export const OrderHistoryMenuItem: React.FC<IComponentProps> = props => {
  const { item } = props;
  const { name, total, addOns, quantity } = item;
  const totalWithAddons =
    total +
    addOns.reduce<number>((acc, current) => {
      acc += current.total;
      return acc;
    }, 0);
  const totalTitle = `P${total}`;
  const totalWithAddonsTitle = `P${totalWithAddons}`;

  return (
    <>
      <Row justify="space-between" wrap={false} className="mb-3" gutter={[12, 0]}>
        <Col className="basic__text_title" title={name}>
          {quantity || 0}x {name}
        </Col>
        <Col className="basic__text_title" title={totalTitle}>
          {totalTitle}
        </Col>
      </Row>
      <List
        className="list-without-empty"
        dataSource={addOns}
        renderItem={addOn => <OrderHistoryAddOnListItem addOn={addOn} itemQuantity={quantity} />}
      />
      <Row justify="space-between" className="mb-6">
        <Col className="fs font-weight-7" title="Total">
          Total
        </Col>
        <Col className="fs font-weight-7" title={totalWithAddonsTitle}>
          {totalWithAddonsTitle}
        </Col>
      </Row>
    </>
  );
};
