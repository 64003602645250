import React, { memo } from 'react';
import { Col, Row } from 'antd/es/grid';
import { IOrderHistoryAddOnItem } from 'entities/OrderHistory/OrderHistory.models';

interface IComponentProps {
  addOn: IOrderHistoryAddOnItem;
  itemQuantity: number;
}

const OrderHistoryAddOnListItemComponent: React.FC<IComponentProps> = props => {
  const { addOn, itemQuantity } = props;
  const { quantity, name, total } = addOn;
  const addOnTitle = `${quantity * itemQuantity || 0}x ${name || ''}`;
  const addOnTotal = `P${total || 0}`;

  return (
    <Row justify="space-between" wrap={false} className="mb-3" gutter={[12, 0]}>
      <Col className="fs" title={addOnTitle}>
        {addOnTitle}
      </Col>
      <Col className="fs" title={addOnTotal}>
        {addOnTotal}
      </Col>
    </Row>
  );
};

export const OrderHistoryAddOnListItem = memo(OrderHistoryAddOnListItemComponent);
