import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCollectionPreRequestDataMapper,
  buildCollectionResponseFormatter,
  buildCommunication,
  EActionsTypes,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { push, replace } from 'react-router-redux';
import { message } from 'antd';
import { ERoutesCommon, ERoutesPrivate } from 'common/models/routesModel';
import { EErrorStatus } from 'common/models/requestModels';
import {
  IOrderHistoryCollection,
  IOrderHistoryCollectionFilter,
  IOrderHistoryModel,
  IOrderHistoryReorderModel,
  IOrderHistoryAddReorderParams,
  EOrderHistoryErrorCode,
  EOrderHistoryErrorMessage
} from 'entities/OrderHistory/OrderHistory.models';
import { orderHistoryTransport } from 'entities/OrderHistory/OrderHistory.transport';
import { updateCustomerStore } from 'entities/Customer/Customer.communication';
import { getSuccessCartModel } from 'entities/Cart/Cart.communication';

const namespace = 'orderHistory';

export interface IOrderHistoryConnectedProps {
  orderHistoryCollection: StoreBranch<IOrderHistoryCollection>;
  getOrderHistoryCollection(filter?: IOrderHistoryCollectionFilter): void;
  clearOrderHistoryCollection(): void;

  orderHistoryReorder: StoreBranch<IOrderHistoryReorderModel>;
  addOrderHistoryReorder(params: IOrderHistoryAddReorderParams): void;

  orderHistoryModel: StoreBranch<IOrderHistoryModel>;
  getOrderHistoryModel(id: string): void;
  clearOrderHistoryModel(): void;
}

const collectionApiProviders: APIProvider[] = [
  new APIProvider(EActionsTypes.get, orderHistoryTransport.getCollection, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    mapSuccess: buildCollectionResponseFormatter()
  })
];

const reorderApiProviders: APIProvider[] = [
  new APIProvider<IOrderHistoryReorderModel, IOrderHistoryAddReorderParams>(EActionsTypes.add, orderHistoryTransport.reorder, {
    *onSuccess(response) {
      const { store, cart } = response || {};

      yield store && updateCustomerStore(store);
      yield cart && getSuccessCartModel(cart);
      yield put(push(ERoutesPrivate.Bag));
    },
    onFail(error, params) {
      if (error?.data?.code === EOrderHistoryErrorCode.StoreIsClosed) {
        const storeName = params?.storeName || 'Unknown Store';
        message.error(`${storeName} is currently unavailable.`);
      }
      if (error?.data?.code === EOrderHistoryErrorCode.EmptyOrder) {
        message.error(EOrderHistoryErrorMessage.EmptyOrder);
      }
    }
  })
];

const modelApiProviders: APIProvider[] = [
  new APIProvider(EActionsTypes.get, orderHistoryTransport.get, {
    *onFail(error) {
      yield put(replace(ERoutesCommon.Main));

      if (error?.data?.statusCode === EErrorStatus.Forbidden && error?.data?.message) {
        message.error(error.data.message);
      }
    }
  })
];

const branches = [
  new Branch('collection', collectionApiProviders),
  new Branch('model', modelApiProviders),
  new Branch('reorder', reorderApiProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationOrderHistory = buildCommunication<IOrderHistoryConnectedProps>(strategy);
