import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { formatAmount } from 'common/helpers/format.helper';
import { ReactComponent as SvgShopBag } from 'app/assets/images/shopBag.svg';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';

type AllProps = ICartConnectedProps;

const ShopBagComponent: React.FC<AllProps> = props => {
  const { cartModel } = props;
  const { data: cartData } = cartModel;
  const { total, items, discountTotal } = cartData || {};
  const history = useHistory();
  const finalTotal = formatAmount(discountTotal ?? total);

  const hasCart = items?.length;

  if (hasCart) {
    return (
      <Button
        className="btn-opacity btn-opacity_orange d-flex-inline"
        onClick={() => {
          history.push(ERoutesPrivate.Bag);
        }}
      >
        <span>
          P<span className="font-weight-bold">{finalTotal}</span>
        </span>

        <SvgShopBag className="ml-4" />
      </Button>
    );
  }

  return (
    <Button
      className="btn-opacity"
      onClick={() => {
        history.push(ERoutesPrivate.Bag);
      }}
    >
      <SvgShopBag />
    </Button>
  );
};

export const ShopBag = communicationCart.injector(ShopBagComponent);
