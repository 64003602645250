import React from 'react';
import { objectToQuery } from '@axmit/transport';
import { axiosConfig } from 'index';
import { refreshToken } from '@axmit/axios-patch-jwt';
import { FacebookButton } from 'common/components/FacebookButton';
import { ERoutesPrivate } from 'common/models/routesModel';
import { GoogleButton } from 'common/components/GoogleButton';
import exclamationMark from 'app/assets/images/exclamationMark.svg';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ESocialLinkModals } from 'entities/UI/UI.models';
import { ESocialType } from 'entities/Auth/Auth.models';

interface IComponentProps {
  className?: string;
}

type AllProps = IComponentProps & IAuthConnectedProps & ICustomerConnectedProps & IUIConnectedProps;

const SocialLinkBarComponent: React.FC<AllProps> = props => {
  const { className, authModel, customerModel, openUiSocialLinkModal } = props;
  const { data: authData } = authModel;
  const { data: customerData } = customerModel;
  const { isPasswordSet, isGoogleSet, isFacebookSet } = customerData || {};
  const facebookRemoveDisabled = isFacebookSet && !isPasswordSet && !isGoogleSet;
  const googleRemoveDisabled = isGoogleSet && !isPasswordSet && !isFacebookSet;

  const connectSocial = async (socialType: ESocialType) => {
    await refreshToken(axiosConfig);
    const { access } = authData || {};
    const baseUrl = socialType === ESocialType.Facebook ? ERoutesPrivate.ConnectFacebook : ERoutesPrivate.ConnectGoogle;
    access && window.location.replace(`${baseUrl}${objectToQuery(access)}`);
  };

  const removeSocial = (socialType: ESocialType) => {
    openUiSocialLinkModal({ type: ESocialLinkModals.UnlinkConfirm, socialType });
  };

  return (
    <div className={className}>
      {!isFacebookSet && <FacebookButton onClick={() => connectSocial(ESocialType.Facebook)} text="Connect with Facebook" />}
      {isFacebookSet && (
        <FacebookButton
          disabled={facebookRemoveDisabled}
          onClick={() => removeSocial(ESocialType.Facebook)}
          text="Remove Facebook"
        />
      )}
      {facebookRemoveDisabled && (
        <div className="d-flex mt-4">
          <div className="mr-5 ml-2 pt-1">
            <img width={20} src={exclamationMark} alt="warning" />
          </div>
          <div className="fs-xxs color-default">
            Before removing your Facebook account from MB Go, please set a password in your Account Settings or connect your
            account with Google instead.
          </div>
        </div>
      )}
      {!isGoogleSet && (
        <GoogleButton className="mt-7" text="Connect with Google" onClick={() => connectSocial(ESocialType.Google)} />
      )}
      {isGoogleSet && (
        <GoogleButton
          className="mt-7"
          disabled={googleRemoveDisabled}
          onClick={() => removeSocial(ESocialType.Google)}
          text="Remove Google"
        />
      )}
      {googleRemoveDisabled && (
        <div className="d-flex mt-4">
          <div className="mr-5 ml-2 pt-1">
            <img width={20} src={exclamationMark} alt="warning" />
          </div>
          <div className="fs-xxs color-default">
            Before removing your Google account from MB Go, please set a password in your Account Settings or connect your account
            with Facebook instead.
          </div>
        </div>
      )}
    </div>
  );
};

export const SocialLinkBar = communicationUI.injector(
  communicationCustomer.injector(communicationAuth.injector(SocialLinkBarComponent))
);
