import React, { memo } from 'react';
import { Row } from 'antd/es/grid';
import { Typography } from 'antd';
import { CountSelector } from 'common/components/CountSelector';
import { IMenuItem } from 'entities/Menu/Menu.models';
import 'app/assets/sass/components/item-card.scss';
import { EStoreState, IStoreModel } from 'entities/Store/Store.models';
import { MenuItemImage } from 'entities/Menu/components/MenuItemImage';

interface IComponentProps {
  item: IMenuItem;
  quantity: number;
  selectedStore?: IStoreModel;
  onQuantityChange: (quantity: number) => void;
}

const ScannedItemCardComponent: React.FC<IComponentProps> = ({ item, quantity, selectedStore, onQuantityChange }) => {
  const { price, name, description, image } = item || {};
  const isStoreOffline = selectedStore && selectedStore.state !== EStoreState.Online;

  return (
    <div className="menu-details fs-xs menu-card mt-8">
      <div className="menu-details__content">
        <Row justify="space-between" className="d-flex_no-wrap">
          <div>
            {name && <div className="menu-details__header">{name}</div>}
            {description && (
              <Typography.Paragraph className="menu-details__description word-break">{description}</Typography.Paragraph>
            )}

            {selectedStore && (
              <CountSelector
                label={
                  <Row className="fs" align="middle">
                    P&nbsp;<span className="fs-xs font-weight-7">{price}</span>
                  </Row>
                }
                value={quantity}
                onChange={onQuantityChange}
                min={1}
                className={isStoreOffline ? 'offline' : ''}
              />
            )}
          </div>

          {image && (
            <div className="menu-details__image-wrapper">
              <MenuItemImage src={image} className="menu-details__image" alt={name} />
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export const ScannedItemCard = memo(ScannedItemCardComponent);
