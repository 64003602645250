import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IOrderHistoryAddReorderParams, IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

const basePath = '/order-histories';

class OrderHistoryTransport extends BaseHttpTransport<AxiosInstance, IOrderHistoryModel> {
  public reorder = ({ id, storeId }: IOrderHistoryAddReorderParams) => axios.post(`${this.path}/${id}/reorder/${storeId}`);
}

export const orderHistoryTransport = new OrderHistoryTransport(basePath, axios);
