import React from 'react';
import { RouteComponentProps } from 'react-router';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { OrderHistoryList } from 'entities/OrderHistory/components/OrderHistoryList';
import { StoreSelector } from 'entities/Store/components/StoreSelector';
import { IStoreModel } from 'entities/Store/Store.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ReorderConfirmModal } from 'entities/OrderHistory/components/ReorderConfirmModal';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

type AllProps = RouteComponentProps & IUIConnectedProps;

interface IFilter {
  storeItem?: Partial<IStoreModel>;
}

interface IComponentState {
  filter: IFilter;
}

class OrderHistoryPageComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      storeItem: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IFilter>(this.state.filter) };
  }

  openReorderConfirm = (item: IOrderHistoryModel) => {
    const { openUiReorderConfirmModal } = this.props;
    openUiReorderConfirmModal({ item });
  };

  render() {
    const { uiReorderConfirmModal, closeUiReorderConfirmModal } = this.props;
    const isVisible = uiReorderConfirmModal?.data?.isVisible;
    const itemForReorder = uiReorderConfirmModal?.data?.item;
    const { filter } = this.state;
    const storeItem = filter.storeItem;
    const storeId = storeItem?.id;
    const emptyText = storeId ? (
      <>
        There are no orders in your order history that match the selected store.
        <br />
        Please try again.
      </>
    ) : (
      'There are no orders in your order history yet.'
    );

    return (
      <TwoColumnsLayout
        left={
          <MobileLayout header="Order history">
            <div className="basic__text_title mb-4">Filter orders by Store name or address</div>
            <div className="mb-9">
              <StoreSelector onChange={this.onChangeSelector} value={storeItem} />
            </div>
            <OrderHistoryList
              showConfirm={this.openReorderConfirm}
              elementId="basicLayout"
              filter={{ store: storeId }}
              useWindow={true}
              emptyText={emptyText}
            />
            <ReorderConfirmModal
              isVisible={!!isVisible}
              onClose={() => closeUiReorderConfirmModal()}
              orderHistoryItem={itemForReorder}
            />
          </MobileLayout>
        }
      />
    );
  }
  onChangeSelector = (item?: IStoreModel) => {
    const storeItem = item ? { id: item?.id, storeName: item?.storeName } : undefined;

    this.setFilter({ storeItem });
  };

  setFilter = (partialFilter: Partial<IFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };
}

export const OrderHistoryPage = communicationUI.injector(OrderHistoryPageComponent);
