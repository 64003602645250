import { all, takeEvery, put } from 'redux-saga/effects';
import { message } from 'antd';
import { replace } from 'connected-react-router';
import { ECommonErrorCode, EErrorStatus } from 'common/models/requestModels';
import { ERoutesCommon } from 'common/models/routesModel';
import { communicationStore } from 'entities/Store/Store.communication';
import { clearAuth, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationCustomer } from 'entities/Customer/Customer.communication';
import { communicationCustomersPhones } from 'entities/CustomersPhones/CustomersPhones.communication';
import { communicationMenu } from 'entities/Menu/Menu.communication';
import { communicationOrder } from 'entities/Orders/Orders.communication';
import { communicationPeakHours } from 'entities/PeakHours/PeakHours.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationOrderHistory } from 'entities/OrderHistory/OrderHistory.communication';
import { communicationRatings } from 'entities/Ratings/Ratings.communication';
import { communicationCart } from 'entities/Cart/Cart.communication';
import { communicationQR } from 'entities/QR/QR.communication';
import { communicationPromo } from 'entities/Promo/Promo.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: any) {
    if (action.type.match('FAIL')) {
      const status = action.payload && action.payload.status;

      switch (status) {
        case EErrorStatus.Validation:
        case EErrorStatus.Forbidden:
        case EErrorStatus.NotFound:
          break;
        case EErrorStatus.Unauthorized:
          yield clearAuth();
          break;
        case EErrorStatus.NotConfirmedEmail:
          message.warning('Please check your email and confirm account!');
          break;
        case EErrorStatus.InternalServerError:
          break;
        case EErrorStatus.PreconditionFailed:
          if (action?.payload?.data?.code === ECommonErrorCode.ReleaseVersionNotSupported) {
            message.warning('Something went wrong, reload page and try again');
          }
          if (action?.payload?.data?.code === ECommonErrorCode.ApiIsUnderMaintenance) {
            const { pathname: currentPath } = window.location;
            yield currentPath !== ERoutesCommon.TechnicalWork && put(replace(ERoutesCommon.TechnicalWork));
          }
          break;
        default:
          break;
      }
      console.log('ERROR', action.payload);
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationCustomer.sagas,
    ...communicationCustomersPhones.sagas,
    ...communicationMenu.sagas,
    ...communicationUser.sagas,
    ...communicationStore.sagas,
    ...communicationPeakHours.sagas,
    ...communicationUI.sagas,
    ...communicationOrder.sagas,
    ...communicationOrderHistory.sagas,
    ...communicationRatings.sagas,
    ...communicationCart.sagas,
    ...communicationQR.sagas,
    ...communicationPromo.sagas
  ]);
}
