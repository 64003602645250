import { IUpdateParams } from '@axmit/transport';

export interface ICartModel extends Components.Schemas.CartDto {}

export interface IBaseCartItemModel extends Components.Schemas.BaseCartItemDto {}
export interface ICartItemModel extends Components.Schemas.CartItemDto {}
export interface ICartAddItemParams extends Components.Schemas.AddCartItemDto {
  price: number;
  addOns: ICartAddItemAddOnParams[];
  isPromo?: boolean;
  maxLimit?: number;
}
export interface ICartUpdateItemParams extends Components.Schemas.UpdateCartItemDto, IUpdateParams {}

export interface ICartItemAddOnModel extends Components.Schemas.BaseCartItemDto {}
export interface ICartAddItemAddOnParams extends Components.Schemas.AddAddOnItemDto {
  price: number;
}

export interface ICartAddPromoCodeParams extends Components.Schemas.PromoCodeDto {}

export enum ECartPromoType {
  Action = 'action',
  Code = 'code'
}

export enum ECartPromoBonusType {
  DiscountMenuItem = 'discountMenuItem',
  DiscountFix = 'discountFix',
  DiscountPercent = 'discountPercent',
  AdditionalMenuItem = 'additionalMenuItem',
  AdditionalNotOnMenu = 'additionalItemNotInMenu'
}

const discountIsAppliedHeader = 'Enjoy your discount!';
export const promoLabelTexts: Record<ECartPromoBonusType | ECartPromoType.Code, { header: string; subHeader: string }> = {
  [ECartPromoBonusType.DiscountFix]: {
    header: discountIsAppliedHeader,
    subHeader: 'You have a discount on the total order sum.'
  },
  [ECartPromoBonusType.DiscountPercent]: {
    header: discountIsAppliedHeader,
    subHeader: 'You have a discount on the total order sum.'
  },
  [ECartPromoBonusType.DiscountMenuItem]: {
    header: discountIsAppliedHeader,
    subHeader: 'You have added a discounted item to your bag.'
  },
  [ECartPromoBonusType.AdditionalMenuItem]: {
    header: 'You got a FREE item!',
    subHeader: "You've got a free item from the menu."
  },
  [ECartPromoBonusType.AdditionalNotOnMenu]: {
    header: 'You got a FREE item!',
    subHeader: "You've got a free item not from the menu."
  },
  [ECartPromoType.Code]: {
    header: 'Promo code is applied!',
    subHeader: 'You have successfully redeemed a promo code.'
  }
};

export enum ECartErrorCode {
  PromoNotFound = 'error.promoNotFoundException',
  PromoTemporarilyUnavailable = 'error.promoTimeOutOfRangeException',
  CartItemNotFound = 'error.cartItemNotFoundException',
  CartQRItemNotFound = 'error.menuItemUnavailableException',
  StoreItemNotFound = 'error.storeItemNotFoundException',
  StoreQRItemNotFound = 'error.storeTemporarilyUnavailableException'
}

export enum ECartErrorMessage {
  PromoNotFound = 'Please enter a valid promo code',
  CartItemNotFound = 'Cart item not found',
  PromoTemporarilyUnavailable = 'Promo is temporarily unavailable'
}
