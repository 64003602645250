export interface IStoreModel extends Components.Schemas.StoreDto {}

export interface IStoreModel extends Components.Schemas.StoreDto {}
export interface IStoreCollection extends Components.Schemas.StoreCollectionDto {}
export interface IStoreCollectionParams extends Paths.StoreControllerListForCustomer.QueryParameters {}

export enum EStoreState {
  Offline = 'offline',
  Online = 'online',
  TemporarilyUnavailable = 'temporarilyUnavailable'
}

export enum EStoreErrorCode {
  StoreIsClosed = 'error.storeIsClosedException',
  NotFound = 'error.storeNotFoundException'
}
export enum EStoreErrorMessage {
  StoreIsClosed = 'Store is closed',
  NotFound = 'Store not found'
}

export enum EStoresLSStatus {
  Updated = 'Updated',
  NonUpdated = 'NonUpdated'
}

export enum EDisplayMode {
  All = 'all',
  Nearest = 'nearest'
}
