import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { push } from 'react-router-redux';
import { put, select } from 'redux-saga/effects';
import { ERoutesCommon } from 'common/models/routesModel';
import { ratingsTransport } from 'entities/Ratings/Ratings.transport';
import { ERatingsErrorCode, ERatingsErrorMessage, IAddRatingParams, IRatingModel } from 'entities/Ratings/Ratings.models';
import { EAuthErrorCode, EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { updateCustomerStore } from 'entities/Customer/Customer.communication';

const namespace = 'ratings';

export interface IRatingsConnectedProps {
  ratingsModel: StoreBranch<IRatingModel>;
  addRatingsModel(params: IAddRatingParams): void;
}

const ModelApiProviders = [
  new APIProvider<IRatingModel>(EActionsTypes.add, ratingsTransport.add, {
    *onSuccess(response) {
      const { store } = response || {};
      const customerStore = yield select(state => state?.customer?.model?.data?.store);
      if (customerStore && store && customerStore?.id === store?.id) {
        yield updateCustomerStore(store);
      }
      yield put(push(ERoutesCommon.Main));
      yield message.success(
        'Thank you for your feedback! Your rating helps us improve the experience on MB Go for our customers.'
      );
    },
    onFail(error) {
      if (error?.data?.code === ERatingsErrorCode.RatingTimeExpired) {
        message.error(ERatingsErrorMessage.RatingTimeExpired);
      }
      if (error?.data?.code === ERatingsErrorCode.RatingAlreadyExists) {
        message.error(ERatingsErrorMessage.RatingAlreadyExists);
      }
      if (error?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
    }
  })
];

const branches = [new Branch('model', ModelApiProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationRatings = buildCommunication<IRatingsConnectedProps>(strategy);
