import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { BaseHttpTransport } from '@axmit/transport';
import axios from 'axios';
import { IPeakHours } from 'entities/PeakHours/PeakHours.models';

const namespace = 'peakHours';

export interface IPeakHoursConnectedProps {
  peakHoursModel: StoreBranch<IPeakHours>;
  getPeakHoursModel(): void;
}

const transport = new BaseHttpTransport('stores/peak-hours', axios);

const ModelAPIProviders = [new APIProvider(EActionsTypes.get, transport.getCollection)];

const branches = [new Branch('model', ModelAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPeakHours = buildCommunication<IPeakHoursConnectedProps>(strategy);
