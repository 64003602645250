import React from 'react';
import { Row, Col } from 'antd';

interface IComponentProps {
  left?: JSX.Element;
  right?: JSX.Element;
}

export const TwoColumnsLayout: React.FC<IComponentProps> = props => {
  const { left, right } = props;

  return (
    <Row className="layout-two-columns" gutter={24}>
      <Col xs={24} sm={12} lg={8}>
        {left}
      </Col>

      <Col className="layout-two-columns__slot-right" xs={24} sm={12} lg={8}>
        {right}
      </Col>
    </Row>
  );
};
