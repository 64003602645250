import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IMenuItem } from 'entities/Menu/Menu.models';
import { IRejectOrderParams } from 'entities/Orders/Orders.models';

const basePath = '/orders';

class OrdersTransport extends BaseHttpTransport<AxiosInstance, IMenuItem> {
  public getCurrent = () => {
    return this.axios.get(`${this.path}/current`);
  };
  public reject = ({ id, ...rest }: IRejectOrderParams) => {
    return this.axios.put(`${this.path}/${id}/reject-customer`, rest);
  };
}

export const ordersTransport = new OrdersTransport(basePath, axios);
