import React from 'react';
import { Spin } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { OrderPageBeforeSend } from 'entities/Orders/components/OrderPageBeforeSend';
import { BagPageEmpty } from 'entities/Orders/components/BagPageEmpty';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';
import { OrderDetailsModal } from 'entities/UI/components/OrderDetailsModal';
import { communicationOrder, IOrdersConnectedProps } from 'entities/Orders/Orders.communication';
import { communicationMenu, IMenuConnectedProps } from 'entities/Menu/Menu.communication';

type AllProps = ICartConnectedProps & IOrdersConnectedProps & IMenuConnectedProps;

const BagPageComponent: React.FC<AllProps> = props => {
  const { cartModel, ordersModel, menuAddOns } = props;
  const { data: cartData, loading: cartLoading } = cartModel;
  const { items } = cartData || {};

  const { loading: orderLoading } = ordersModel;
  const { loading: menuLoading } = menuAddOns;
  const loading = cartLoading || orderLoading || menuLoading;

  const content =
    cartData && items?.length ? <OrderPageBeforeSend cart={cartData} isBag /> : <TwoColumnsLayout left={<BagPageEmpty />} />;

  return (
    <Spin spinning={loading}>
      {content}
      <OrderDetailsModal />
    </Spin>
  );
};

export const BagPage = communicationMenu.injector(communicationOrder.injector(communicationCart.injector(BagPageComponent)));
