import React, { useEffect, useState } from 'react';
import { Button, Col, List, Radio, Row, Spin } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { getMapLinkToStore, getStoreAddressString } from 'common/helpers/Store.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { ModalContent } from 'common/components';
import { ORDERS_PICKUP_DATE_FORMAT } from 'common/consts/Orders.const';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { StoreRating } from 'common/components/StoreRating';
import { formatAmount } from 'common/helpers/format.helper';
import { ReactComponent as SvgPlacePoint } from 'app/assets/images/placePoint.svg';
import { ReactComponent as SvgMapPoint } from 'app/assets/images/mapPoint.svg';
import { communicationOrder, IOrdersConnectedProps } from 'entities/Orders/Orders.communication';
import { OrderPageEmpty } from 'entities/Orders/components/OrderPageEmpty';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';
import { ECommonRejectReasons, EOrderStatus, rejectReasonsGroup, TRejectReasons } from 'entities/Orders/Orders.models';
import { OrderItemListItem } from 'entities/Orders/components/OrderItemListItem';
import { getOrderStatusMessage } from 'entities/Orders/Orders.helpers';
import { PromoInfo } from 'entities/Cart/components/PromoInfo';
import { ECartPromoBonusType, ECartPromoType } from 'entities/Cart/Cart.models';
import { CartItemTotal } from 'entities/Cart/components/CartItemTotal';

type AllProps = ICustomerConnectedProps & IOrdersConnectedProps;

const OrderPageComponent: React.FC<AllProps> = props => {
  const { ordersModel, getOrdersModel, customerModel } = props;
  const { data: order, loading } = ordersModel;
  const { items, total, discountTotal, promo, bonusTotal } = order || {};

  const isPromoApplied = !!promo;
  const promoBonusType = promo?.bonus?.bonusType as ECartPromoBonusType | undefined;
  const promoType = promo?.type;

  const history = useHistory();

  const orderNumber = order?.code || '';
  const customerName = getCustomerFullName(customerModel.data);
  const store = order?.storeData;
  const { storeName = 'Unknown store' } = store || {};
  const storePhone = order?.storeData?.phone || '';

  const isFinished =
    order?.status === EOrderStatus.RejectedByCustomer ||
    order?.status === EOrderStatus.RejectedByStore ||
    order?.status === EOrderStatus.Finished;
  const orderStatusLabel = getOrderStatusMessage(order?.status as EOrderStatus, order?.rejectReason as ECommonRejectReasons);
  const isCancelable = order?.status === EOrderStatus.New;

  const time = order?.pickUpTime ? formatTime(moment(order?.pickUpTime), ORDERS_PICKUP_DATE_FORMAT) : '';

  const [visibleModal, toggleVisibility] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<TRejectReasons>(rejectReasonsGroup[0].value);

  useEffect(() => {
    getOrdersModel();
  }, [getOrdersModel]);

  const back = () => {
    history.push(ERoutesCommon.Root);
  };

  const reject = () => {
    const { rejectOrdersModel } = props;

    order?.id &&
      rejectOrdersModel({
        id: order.id,
        rejectReason,
        onFail: refresh
      });
  };

  const refresh = () => {
    getOrdersModel();
  };

  if (!order) {
    return (
      <Spin spinning={loading}>
        <TwoColumnsLayout left={<OrderPageEmpty />} />
      </Spin>
    );
  }

  return (
    <Spin spinning={loading}>
      <TwoColumnsLayout
        left={
          <MobileLayout header="Your Order" subHeader={`Order number: ${orderNumber}`}>
            <div className="pl-4 pr-4">
              {isFinished ? (
                <Row justify="space-between" align="middle" className="mb-9">
                  <Col span={24} className="fs-sm font-weight-bold color-orange-main">
                    {orderStatusLabel}
                  </Col>
                </Row>
              ) : (
                <Row justify="space-between" align="middle" className="mb-4">
                  <Col span={12} className="fs-sm font-weight-bold color-orange-main">
                    {orderStatusLabel}
                  </Col>

                  <Col>
                    <Button size="large" className="btn" onClick={refresh} disabled={loading}>
                      Refresh
                    </Button>
                  </Col>
                </Row>
              )}

              <div className="fs font-weight-bold color-default mb-7">
                <a href={`tel:+${storePhone}`} className="link fs color-default t-decoration-none">
                  Store phone: {storePhone}
                </a>
              </div>

              <div className="font-weight-bold mb-7">
                <div className="fs color-darkCherry">Pick up at</div>
                <div className="fs-xs color-default">{time || '-'}</div>
              </div>

              <div className="order-info-item mb-7">
                <SvgPlacePoint />

                <div>
                  <div className="basic__text_title mb-3 mt-1">Pick up from</div>
                  {storeName && (
                    <div className="fs-xs color-default font-weight-5 lh-sm mb-2">
                      <span>{storeName}</span>
                      <StoreRating store={store} className="ml-3" />
                    </div>
                  )}
                  <div className="color-gray fs-xxs lh-sm">{order?.storeData ? getStoreAddressString(order.storeData) : '-'}</div>
                </div>
              </div>

              {order?.storeData?.address && (
                <div className="order-info-item mb-7">
                  <SvgMapPoint />

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getMapLinkToStore(order.storeData)}
                    className="link fs-xs color-orange-main"
                  >
                    View Directions to Store
                  </a>
                </div>
              )}

              <PromoInfo promoType={promoType as ECartPromoType} bonusType={promoBonusType} />

              <div className="font-weight-bold fs-xs color-darkCherry mb-2">Order</div>
            </div>

            <div className="menu-card menu-card_border-light">
              <div className="mb-6 bordered">
                <List dataSource={items} renderItem={item => <OrderItemListItem promoBonusType={promoBonusType} item={item} />} />
              </div>

              {!!bonusTotal && (
                <Row className="menu-card__header pb-4" justify="space-between" align="middle" wrap={false}>
                  <div>Discount</div>
                  <div className="color-orange-main">P{formatAmount(bonusTotal)}</div>
                </Row>
              )}

              <Row className="menu-card__header" justify="space-between" align="middle" wrap={false}>
                <div>Total</div>
                <CartItemTotal
                  total={total}
                  discountTotal={discountTotal}
                  bonusType={promoBonusType}
                  isPromoApplied={isPromoApplied}
                  bonusTotal={bonusTotal}
                />
              </Row>
            </div>
          </MobileLayout>
        }
        right={
          <MobileLayout>
            {!isFinished && (
              <div className="color-grayDark">
                <p>Cancellations, after an order has been accepted by the store, are not allowed.</p>
                <p>
                  Please be at the store on or shortly before {time || 'pick up time'}. Orders that are not picked up after the 15
                  minute grace period may result in your account being temporarily suspended.
                </p>
                <p>
                  We would like to encourage a positive experience for all users, from our customers to our stores. See you at our
                  stores soon{customerName && `, ${customerName}`}
                </p>
              </div>
            )}

            <Button size="large" block className="btn mb-7" type="primary" onClick={back}>
              Back to the main page
            </Button>

            {isCancelable && (
              <Button
                size="large"
                block
                className="btn mb-9"
                onClick={() => {
                  toggleVisibility(true);
                }}
                disabled={loading}
              >
                Cancel order
              </Button>
            )}

            {isCancelable && (
              <ModalContent
                visible={visibleModal}
                close={() => {
                  toggleVisibility(false);
                }}
                header="Please choose the reason for cancelling"
              >
                <Radio.Group
                  onChange={e => {
                    setRejectReason(e.target.value);
                  }}
                  value={rejectReason}
                  className="radio-vertical mb-5"
                >
                  {rejectReasonsGroup.map(({ value, label }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>

                <Button size="large" block className="btn" onClick={reject} disabled={loading}>
                  Cancel order
                </Button>
              </ModalContent>
            )}
          </MobileLayout>
        }
      />
    </Spin>
  );
};

export const OrderPage = communicationCustomer.injector(communicationOrder.injector(OrderPageComponent));
