import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { getWithoutSpaces } from 'common/helpers/format.helper';
import { MenuItemCard } from 'entities/Menu/components/MenuItemCard';
import { ECategoryType, IMenuCategory, IMenuItemWithCategoryNameAndType } from 'entities/Menu/Menu.models';

interface IComponentProps {
  category: IMenuCategory;
  isDefaultMenu: boolean;
  onMenuItemClick(item: IMenuItemWithCategoryNameAndType): void;
}

const MenuCategoriesListItemComponent: React.FC<IComponentProps> = props => {
  const { category, isDefaultMenu, onMenuItemClick } = props;
  const { name, menuItemsToAsset, type = '' } = category;

  return (
    <>
      <div className="menu-card-sign mt-7" id={getWithoutSpaces(name)}>
        {name}
      </div>

      <Row gutter={[24, 24]}>
        {menuItemsToAsset?.map(item => (
          <Col sm={12} lg={8} xs={24} key={item.id}>
            <MenuItemCard
              item={{ ...item, category: name, type: type as ECategoryType }}
              isDefaultMenu={isDefaultMenu}
              onClick={onMenuItemClick}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export const MenuCategoriesListItem = memo(MenuCategoriesListItemComponent);
