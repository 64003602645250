export interface IUserModel extends Components.Schemas.UserDto {}

export interface IUserConfirmParams {
  token: string;
}

export interface IUserUpdateModel {
  password: string;
  oldPassword: string;
}

export interface IUpdatePasswordParams
  extends Components.Schemas.UpdatePasswordDto,
    Paths.UserControllerUpdatePassword.PathParameters {
  onSuccess?: Function;
}

export enum EUserSuccessMessage {
  PasswordChanged = 'Profile successfully updated'
}
