import React from 'react';
import { Link } from 'react-router-dom';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { ERoutesCommon } from 'common/models/routesModel';

export const BagPageEmpty: React.FC = () => {
  return (
    <MobileLayout header="Your Bag">
      <div className="fs-xxs t-align-c mb-8">Your bag is empty</div>
      <div className="t-align-c ">
        <Link className="link fs-xs" to={ERoutesCommon.Root}>
          Add products
        </Link>
      </div>
    </MobileLayout>
  );
};
