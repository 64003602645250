import React, { memo } from 'react';
import { ECartPromoBonusType, ECartPromoType, promoLabelTexts } from 'entities/Cart/Cart.models';

interface IComponentProps {
  promoType?: ECartPromoType;
  bonusType?: ECartPromoBonusType;
}

const PromoInfoComponent: React.FC<IComponentProps> = props => {
  const { promoType, bonusType } = props;

  if (!bonusType || !promoType) {
    return null;
  }

  const textsKey = promoType === ECartPromoType.Code ? promoType : bonusType;

  const { header, subHeader } = promoLabelTexts[textsKey];

  return (
    <>
      <div className="fs-xs font-weight-7 color-orange-main mb-3">{header}</div>
      <div className="mb-9 fs">{subHeader}</div>
    </>
  );
};

export const PromoInfo = memo(PromoInfoComponent);
