import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getSuccessType
} from '@axmit/redux-communications';
import { message } from 'antd';
import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { IBasePathParams } from 'common/models/requestModels';
import { ERoutes } from 'common/models/routesModel';
import { mapParamsWithCallback } from 'common/mappers/Params.mapper';
import { userTransport, usersTransport } from 'entities/User/User.transport';
import { IUserModel, IUserUpdateModel, IUpdatePasswordParams, EUserSuccessMessage } from 'entities/User/User.models';
import { EAuthSuccessMessage } from 'entities/Auth/Auth.models';

const namespace = 'user';

export interface IUserConnectedProps {
  userModel: StoreBranch<IUserModel>;
  updatePassForUserModel(path: IUpdatePasswordParams): void;
  getUserModel(path: IBasePathParams): void;
  updateUserModel(params: { path: IBasePathParams; data: IUserUpdateModel }): void;
  clearUserModel(): void;
}

const UserModelAPIProviders = [
  new APIProvider('updatePassFor', usersTransport.changePassword, {
    mapParams: mapParamsWithCallback,
    onSuccess: function(response, originalParams) {
      message.success(EUserSuccessMessage.PasswordChanged);
      originalParams?.onSuccess && originalParams.onSuccess();
    }
  }),
  new APIProvider(EActionsTypes.get, userTransport.get),
  new APIProvider(EActionsTypes.update, userTransport.update, {
    onSuccess: function*(response: IUserModel) {
      message.success(EAuthSuccessMessage.SuccessfullyEdited);
      yield put(push(`${ERoutes.Users}/${response.id}`));
    }
  })
];

const UserRegistrationAPIProviders = [
  new APIProvider(EActionsTypes.add, userTransport.registration, {
    onSuccess: function() {
      message.success(EAuthSuccessMessage.CheckEmailForConfirmedLink);
    },
    throwOnFail: true
  })
];

const UserConfirmAPIProviders = [
  new APIProvider(EActionsTypes.add, userTransport.confirm, {
    onSuccess: function() {
      message.success(EAuthSuccessMessage.AccountConfirmed);
    }
  })
];

const branches = [
  new Branch('model', UserModelAPIProviders),
  new Branch('registration', UserRegistrationAPIProviders),
  new Branch('confirm', UserConfirmAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

function* registrationSaga() {
  const registrationSuccessType = getSuccessType(namespace, 'confirm', EActionsTypes.add);

  yield takeEvery(registrationSuccessType, function*(action: any) {
    const loginSuccessType = getSuccessType('auth', 'model', EActionsTypes.add);

    yield put({ type: loginSuccessType, payload: action.payload });
  });
}

export const communicationUser = buildCommunication<IUserConnectedProps>(strategy);

communicationUser.sagas.push(registrationSaga());
