import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { IMenuCollection, IGetMenuCollectionParams } from 'entities/Menu/Menu.models';
import { menuTransport } from 'entities/Menu/Menu.transport';

const namespace = 'menu';

export interface IMenuConnectedProps {
  menuCollection: StoreBranch<IMenuCollection, IGetMenuCollectionParams>;
  getMenuCollection(id: string): void;
  getDefaultMenuCollection(): void;

  menuAddOns: StoreBranch<IMenuCollection, IGetMenuCollectionParams>;
  getMenuAddOns(id: string): void;
}

const CollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, menuTransport.getList, {
    mapParams: id => ({ isAddOns: false, id })
  }),
  new APIProvider('getDefault', menuTransport.getDefault, {
    mapParams: () => ({ isAddOns: false })
  })
];

const AddOnsAPIProviders = [
  new APIProvider(EActionsTypes.get, menuTransport.getList, {
    mapParams: id => ({ isAddOns: true, id })
  })
];

export function* getDefaultMenuCollection() {
  yield put({ type: getStartType(namespace, 'collection', 'getDefault') });
}

export function* getMenuCollection(id: string) {
  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload: id });
}

export function* getMenuAddOns(id: string) {
  yield put({ type: getStartType(namespace, 'addOns', EActionsTypes.get), payload: id });
}

const branches = [new Branch('collection', CollectionAPIProviders), new Branch('addOns', AddOnsAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationMenu = buildCommunication<IMenuConnectedProps>(strategy);
