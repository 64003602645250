import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IComponentProps {
  onConfirm(): void;
}

type AllProps = IComponentProps & IUIConnectedProps;

const header = 'You are about to update your pick-up Store location.';
const subHeader =
  'You currently have a different store selected. If you continue, your current store will be updated and any items in your bag will be replaced with the scanned item.';

const AddToBagConfirmModalComponent: React.FC<AllProps> = ({
  closeUiAddToBagConfirmModal,
  onConfirm,
  uiAddToBagConfirmModal
}) => {
  const { isVisible } = uiAddToBagConfirmModal?.data || {};

  const submit = () => {
    onConfirm();
    closeUiAddToBagConfirmModal();
  };

  return (
    <ModalContent header={header} subHeader={subHeader} close={() => closeUiAddToBagConfirmModal()} visible={!!isVisible}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={submit}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={() => closeUiAddToBagConfirmModal()}>
        Cancel
      </Button>
    </ModalContent>
  );
};

export const AddToBagConfirmModal = communicationUI.injector(AddToBagConfirmModalComponent);
