export const STORE_SEARCH_LIMIT = 3;
export const STORE_SEARCH_WAIT_TIME = 300;
export const STORE_CODE_URL_PARAM = 'store';
export const ERROR_MESSAGE_DURATION = 3000;
export const GEOLOCATION_REQUEST_TIMEOUT = 10000;
export const ERROR_MESSAGE_PERMISSION_DENIED =
  'Sorry! We cannot identify your location. Please allow mbgo.ph to access your location to see the stores available nearby.';
export const ERROR_MESSAGE_POSITION_UNAVAILABLE = 'Location identification failed. Please try again';
export const ERROR_MESSAGE_TIMEOUT = 'Location identification is taking too long. Please refresh the page and try again';
export const ERROR_NAVIGATOR = 'Sorry! We cannot identify your location. Please refresh the page and try again';
