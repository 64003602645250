import React from 'react';
import { StorePanel } from 'entities/Store/components/StorePanel';
import { MenuPage } from 'entities/Menu/components/MenuPage';
import { CategoriesPanel } from 'entities/Menu/components/CategoriesPanel';

const MainPageComponent: React.FunctionComponent = () => {
  return (
    <>
      <StorePanel />

      <CategoriesPanel />

      <MenuPage />
    </>
  );
};

export const MainPage = MainPageComponent;
