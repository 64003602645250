export enum ERoutes {
  Login = '/login',
  SignUp = '/sign-up',
  NewPassword = '/new-password/code',
  UsersEdit = '/users/edit',
  Users = '/users',
  Order = '/order',
  AccountSettings = '/settings'
}

export enum ERoutesCommon {
  Logout = '/logout',
  PasswordRestore = '/password-restore',
  EmailConfirm = '/email-confirm',
  Main = '/main',
  Root = '/',
  Privacy = '/privacy',
  PasswordForgot = '/password-forgot',
  ToS = '/ToS',
  TechnicalWork = '/tech-work',
  CookieBlocked = '/cookies-fail',
  ScanPage = '/scan-page'
}

export enum ERoutesPublic {
  Signup = '/signup',
  SocialAuth = '/social-auth',
  Login = '/login',
  GoogleAuth = '/api/auth/google',
  FacebookAuth = '/api/auth/facebook'
}

export enum ERoutesPrivate {
  Settings = '/settings',
  Bag = '/bag',
  Order = '/order',
  CompleteRegistration = '/complete-registration',
  OrderHistory = '/order-history',
  RateStore = '/rate-store',
  PersonalPromo = '/personal-promo',
  ConnectFacebook = '/api/auth/connect-facebook',
  ConnectGoogle = '/api/auth/connect-google'
}
