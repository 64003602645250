import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ICartAddItemParams } from 'entities/Cart/Cart.models';
import { IMenuItem } from 'entities/Menu/Menu.models';

interface IResult {
  formValues: ICartAddItemParams;
  setFormValues: Dispatch<SetStateAction<ICartAddItemParams>>;
  initValues(params?: IMenuItem): ICartAddItemParams;
}

export const useCartItemForm = (item?: ICartAddItemParams): IResult => {
  const initValues = (): ICartAddItemParams => ({
    price: 0,
    menuItemId: '',
    quantity: 1,
    addOns: []
  });

  const [formValues, setFormValues] = useState<ICartAddItemParams>(item || initValues());

  useEffect(() => {
    item && setFormValues(item);
  }, [item, setFormValues]);

  return { formValues, setFormValues, initValues };
};
