import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import { useMediaPredicate } from 'react-media-hook';
import { useHistory } from 'react-router';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import { HeaderMenuWrapperMob } from 'common/components/Header/HeaderMenuWrapperMob';
import { MEDIA_MOBILE } from 'common/config/base.config';
import { ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { BlockingMessage } from 'common/components/Header/BlockingMessage';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { ShopBag } from 'entities/Orders/components/ShopBag';
import { ECustomerStatus } from 'entities/Customer/Customer.models';

type AllProps = IAuthConnectedProps & RouteComponentProps & ICustomerConnectedProps;

const HeaderComponent: React.FC<AllProps> = ({ authModel, deleteAuthModel, customerModel }) => {
  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);
  const { data } = authModel;
  const { data: customerData } = customerModel;
  const isCustomerBlocked = customerData?.status && customerData.status === ECustomerStatus.Blocked;
  const banExpiredAt = customerData?.banExpiredAt || undefined;
  const history = useHistory();
  const authorized = data && Object.keys(data).length !== 0;
  const visibleLogout = authorized && !isMobile;
  const visibleLogin = !authorized && !isMobile;
  const showBlockingMessage = history.location.pathname !== ERoutesPrivate.Bag && isCustomerBlocked;

  const menuLayout = { lg: 20, md: 19, sm: 17, xs: 16 };

  return (
    <header>
      <div className="ant-menu header">
        <Row className="basic__center-desktop-wrapper flex-noWrap height-full" justify="space-between" align="middle">
          <Col {...menuLayout}>{isMobile ? <HeaderMenuWrapperMob /> : <HeaderMenu />}</Col>

          <Col>
            <Row justify="end" align="middle">
              <Col>
                <ShopBag />
              </Col>
              <Col>
                {visibleLogout && (
                  <Button onClick={() => deleteAuthModel()} className="header__desktop-logout-btn pr-0">
                    Log out
                  </Button>
                )}
                {visibleLogin && (
                  <Button onClick={() => history.push(ERoutesPublic.Login)} className="header__desktop-logout-btn pr-0">
                    Log In
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {showBlockingMessage && (
        <div className="basic__center-desktop-wrapper">
          <BlockingMessage banExpiredAt={banExpiredAt} />
        </div>
      )}
    </header>
  );
};

export const Header = communicationCustomer.injector(communicationAuth.injector(withRouter(HeaderComponent)));
