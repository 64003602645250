import { APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch, EActionsTypes } from '@axmit/redux-communications';
import { put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import { ERoutesPrivate } from 'common/models/routesModel';
import { RequestLoadingHelper } from 'common/helpers/requestLoading.helper';
import { mapParamsWithCallback } from 'common/mappers/Params.mapper';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import {
  IOrderModel,
  IAddOrderParams,
  IRejectOrderParams,
  EOrdersErrorMessage,
  EOrdersErrorCode
} from 'entities/Orders/Orders.models';
import { ordersTransport } from 'entities/Orders/Orders.transport';
import { EAuthErrorCode, EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { EStoreErrorCode, EStoreErrorMessage } from 'entities/Store/Store.models';
import { getCustomerModel } from 'entities/Customer/Customer.communication';
import { getCartModel } from 'entities/Cart/Cart.communication';
import { getMenuAddOns } from 'entities/Menu/Menu.communication';
import { isUnavailableError } from 'entities/Orders/Orders.helpers';
import { ECartErrorMessage, ECartErrorCode, ICartItemModel } from 'entities/Cart/Cart.models';

const namespace = 'orders';

export interface IOrdersConnectedProps {
  ordersModel: StoreBranch<IOrderModel, null, IError>;
  addOrdersModel(params: IAddOrderParams): void;
  rejectOrdersModel(params: IRejectOrderParams): void;
  getOrdersModel(): void;
}

const ModelApiProvider = [
  new APIProvider(EActionsTypes.add, ordersTransport.add, {
    *onFail(response: IError) {
      if (response?.data?.code === EOrdersErrorCode.ActiveOrderExists) {
        message.error(EOrdersErrorMessage.ActiveOrderExists);
      }
      if (response?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      if (response?.data?.code === EStoreErrorCode.StoreIsClosed) {
        message.error(EStoreErrorMessage.StoreIsClosed);
        const userId = yield select(state => state?.auth.model?.data?.access?.userId);
        yield userId && getCustomerModel(userId);
      }

      const hasUnavailableError = isUnavailableError(response);
      if (hasUnavailableError) {
        yield getCartModel();
        const storeId = yield select(state => state?.customer?.model?.data?.store?.id);
        yield storeId && getMenuAddOns(storeId);
      }

      if (response?.data?.code === ECartErrorCode.PromoNotFound) {
        message.error(ECartErrorMessage.PromoNotFound);
      }

      if (response?.data?.code === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
      if (response?.data?.code === EOrdersErrorCode.EmptyItems) {
        message.error(EOrdersErrorMessage.EmptyItems);
      }
    },
    onSuccess: function*(response) {
      const { customerData, id, total } = response || {};
      const items: ICartItemModel[] = yield select(state => state?.cart?.model?.data?.items);
      const customerFullName = getCustomerFullName(customerData);

      yield put(push(ERoutesPrivate.Order));
      yield items && GDLHelper.pushPurchaseEvent(customerFullName, customerData?.email || '', id, total, items);
      yield getCartModel();
    }
  }),
  new APIProvider('reject', ordersTransport.reject, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapParams: mapParamsWithCallback,
    onFail: (response: IError, originalParams) => {
      if (response.data.code === EOrdersErrorCode.AlreadyFinished) {
        message.error(EOrdersErrorMessage.AlreadyFinished);
        originalParams?.onFail && originalParams.onFail();
      }
    }
  }),
  new APIProvider(EActionsTypes.get, ordersTransport.getCurrent)
];

const branches = [new Branch('model', ModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationOrder = buildCommunication<IOrdersConnectedProps>(strategy);
