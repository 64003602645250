export interface IRatingModel extends Components.Schemas.RatingDto {}
export interface IAddRatingParams extends Components.Schemas.CreateRatingDto {}

export enum ERatingsErrorCode {
  RatingTimeExpired = 'error.ratingTimeExpiredException',
  RatingAlreadyExists = 'error.unprocessableEntityException'
}

export enum ERatingsErrorMessage {
  RatingTimeExpired = 'Sorry! Ratings can only be submitted up to 24 hours after an order has been completed.',
  RatingAlreadyExists = 'Sorry! You have already rated this order.'
}
