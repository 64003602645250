import React from 'react';
import { Typography } from 'antd';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';

const { Title, Paragraph, Link } = Typography;

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <MobileLayout header="Privacy Policy">
      <Title level={2} className="fs-xxs">
        Description of services
      </Title>
      <Paragraph className="fs">
        Leslie Corporation (“We” or “Leslie Corporation”) is the Personal Information Controller, as defined under Republic Act
        No. 10173 or the Data Privacy Act of 2012, responsible for ensuring the privacy of the personal information you provide
        through the use of this delivery website. As such Personal Information Controller, we process your personal information in
        accordance with the Data Privacy Act of 2012 and this Privacy Policy.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        What we collect
      </Title>
      <Paragraph className="fs">To fulfill your delivery orders, we shall collect the following information:</Paragraph>
      <ul className="fs">
        <li>Full Name</li>
        <li>Birthdate</li>
        <li>E-mail address</li>
        <li>Cellphone Number</li>
        <li>Your current location</li>
        <li>
          Online Services account access information e.g. username and password, transaction information e.g., products you buy
          and payment preferences, and
        </li>
        <li>
          Other details provided in your public profiles, if applicable, as well as other metadata e.g,. frequency of usage,
          product preferences, preferred delivery times, and other log-in data (Wi-Fi log-in information) when voluntarily
          submitted by you.
        </li>
      </ul>
      <Paragraph className="fs">
        If you provide these information, you understand and agree that we will use such information for purposes described below
        in the “What We Do With the Information We Gather” section (i.e. for direct marketing purposes).
      </Paragraph>
      <Paragraph className="fs">
        The website stores browser level cookies for analytics and advertisement purposes. For the exhaustive list of cookies we
        collect, see the “Cookie Description” section below.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        How we collect
      </Title>
      <Title level={3} className="fs">
        Create an Account/Register:
      </Title>
      <Paragraph className="fs">
        We collect personal information once you register and create an account with us through this website or by signing up
        using your Facebook account. When you choose to create an account by linking it with an external third party service or
        application, such as Facebook or Google, this feature will not modify nor allow the access of the content of your Facebook
        account. However, if you use your Facebook or Google account to register your account, the following shall apply:
      </Paragraph>
      <Paragraph className="fs">
        <ul>
          <li>Your Facebook or Google account name shall be your Account name in the website;</li>
          <li>The e-mail address of your Facebook or Google account shall be used as the e-mail address for your Account.</li>
          <li>
            If available, your mobile number used in your Facebook or Google account shall be used as the mobile number for your
            Account
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        By registering and creating an account with us, you understand and agree that we shall use your personal information in
        accordance with this Privacy Policy.
      </Paragraph>
      <Paragraph className="fs">
        For registered users, we may also request for other personal information such as birthday, gender and other demographic
        information like preferences and interests and other information relevant to customer surveys and/or offers. If you
        provide these information, you understand and agree that we will use such information for purposes described below in the
        What We Do With the Information We Gather section (i.e. for direct marketing purposes)
      </Paragraph>
      <Title level={3} className="fs">
        Allowing your browser to use your location to find stores nearest your location:
      </Title>
      <Paragraph className="fs">
        If you do not allow your browser to use your current location, we will not use your current location to find stores
        nearest to you. You may instead search for stores manually by city or store name.
      </Paragraph>
      <Title level={3} className="fs">
        Signing up for Promotions and Online Offers:
      </Title>
      <Paragraph className="fs">
        If you do not register/create an account but agree to subscribe to marketing emails by giving your email in the
        “Stay-up-to-date” portion of our website, we will keep the e-mail address provided for purposes of sending you updates on
        exclusive offers.
      </Paragraph>
      <Title level={3} className="fs">
        Browsing:
      </Title>
      <Paragraph className="fs">
        If you simply browse through our website but without registering an account, we do not collect any of your personal
        information
      </Paragraph>
      <Title level={2} className="fs-xxs">
        What we do with the information we gather
      </Title>
      <Paragraph className="fs">We require this information for the following purposes:</Paragraph>
      <Paragraph className="fs">
        <ol>
          <li>to find stores nearest to you;</li>
          <li>to fulfill your order;</li>
          <li>to understand your needs;</li>
          <li>to provide you with a better service, and</li>
          <li>
            internal record keeping, for the following reasons:
            <ul>
              <li>We may use the information to improve our products and services.</li>
              <li>
                We may periodically send promotional emails about new products, special offers or other information which we think
                you may find interesting using the email address which you have provided.
              </li>
              <li>From time to time, we may also use your information to contact you for market research purposes.</li>
              <li>
                We may contact you by phone or mail. We may use the information to customize the website according to your
                interests.
              </li>
            </ul>
          </li>
        </ol>
      </Paragraph>

      <Paragraph className="fs">
        For purposes of this Privacy Policy, these purposes (other than the fulfillment of your delivery order), shall be referred
        to as “direct marketing purposes”.
      </Paragraph>
      <Paragraph className="fs">
        We also share your information with our service providers, subsidiaries, affiliates and franchisees, to fulfill your
        order. From time to time, we may also share your personal information with our subsidiaries, affiliates, partners and
        franchisees for their use and processing in accordance with this Privacy Policy.
      </Paragraph>
      <Paragraph className="fs">
        We do not sell, distribute or lease your personal information to third parties other than those stated above, unless we
        have your permission or are required by law to do so.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Security storage and disposal
      </Title>
      <Paragraph className="fs">
        We are committed to ensuring that your personal information we collect and store is secure. To prevent unauthorized
        access, processing or disclosure of your personal information, we have put in place suitable physical, electronic and
        managerial procedures to safeguard and secure the personal information we collect online such as but not limited to access
        restrictions on our databases.
      </Paragraph>
      <Paragraph className="fs">
        We store your personal information in a secured database, but which may be accessed by our service providers,
        subsidiaries, affiliates and franchisees for delivery services. We retain your personal information as long as your
        account is active or as long as necessary to fulfill your delivery orders. If there is no activity within thirty six (36)
        months, we shall dispose of your personal information in accordance with the relevant privacy rules and our internal
        guidelines.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Links to other websites
      </Title>
      <Paragraph className="fs">
        Our website may contain links to other websites of interest as indicated below. The only links outside Minute Burger
        domain the customer can be directed to from this website are Minute Burger’s pages on social media:
      </Paragraph>

      <Paragraph className="fs">
        <ul>
          <li>
            <Link href="https://www.facebook.com/minuteburger">Facebook</Link>
          </li>
          <li>
            <Link href="https://www.twitter.com/minuteburger">Twitter</Link>
          </li>
          <li>
            <Link href="https://www.youtube.com/channel/UCj-evkfm9CzsssyH5JvPlkQ">YouTube</Link>
          </li>
          <li>
            <Link href="https://instagram.com/minuteburger">Instagram</Link>
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        However, once you have used these links to leave our site, you should note that we do not have any control over these
        other sites. Therefore, we will not be responsible for the protection and privacy of any information which you provide
        whilst visiting such sites and such sites are not governed by this Privacy Policy. You should exercise caution and look at
        the privacy statement applicable to the website in question.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Controlling your personal information
      </Title>
      <Paragraph className="fs">
        You may choose to restrict the collection or use of your personal information in the following ways:
      </Paragraph>
      <Paragraph className="fs">
        <ul>
          <li>
            Whenever you are asked to fill up a form on our website, any information you provide will be bound by the{' '}
            <strong>“What We Do With the Information We Gather”</strong> section. If you do not wish to receive any direct
            information or promotion, you may amend or request to opt out through our{' '}
            <Link href="https://mbcustomerservice.zendesk.com/hc/en-us/requests/new">customer care</Link>.
          </li>
          <li>
            It is important that the personal data you provide to us is accurate and complete for you to continue using MB Go! and
            for us to provide the Services. You are responsible for informing us of changes to your personal data, or in the event
            you believe that the personal data we have about you is inaccurate, incomplete, misleading or out of date.
          </li>
          <li>You can update your personal data anytime by accessing your account on MB Go!</li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        You are in control of any Personal Data you submit and provide to us through our Services. If at any time, you would like
        to exercise your right to access, object, erase, block, edit, rectify, or update the Personal Data we have about you. If
        you would like to withdraw or have the Personal Data that you had given or provided us deleted, you can let us know by
        contacting us using our{' '}
        <Link href="https://mbcustomerservice.zendesk.com/hc/en-us/requests/new">MB Go! Customer Care helpdesk</Link>.
      </Paragraph>
      <Paragraph className="fs">
        Once at the Customer Care Helpdesk form, enter your email address, name, order number, subject, and specifically select
        “Request for my Data to be Removed” as the concern. Once you have made that request, your data will be deleted within 2-3
        business days.
      </Paragraph>
      <Paragraph className="fs">
        For any questions about deleting your personal data, kindly contact{' '}
        <Link href="mailto:minuteburger@leslie.ph">minuteburger@leslie.ph</Link> with the subject title “Request for Data
        Deletion”.
      </Paragraph>
      <Paragraph className="fs">
        Please note that if you communicate your withdrawal of your consent to our use, disclosure, storing or processing of your
        personal data for the purposes and in the manner as stated above or exercise your other rights as available under
        applicable local laws, we may not be in a position to continue to provide the Services to you or perform any contract we
        have with you, and we will not be liable in the event that we do not continue to provide the Services to, or perform our
        contract with you. Our legal rights and remedies are expressly reserved in such an event.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Updating this privacy policy
      </Title>
      <Paragraph className="fs">
        Whenever necessary, we may, from time to time, update and/or revise this Privacy Policy. We shall inform you of any update
        or revision by placing the date of the latest version on top of this Privacy Policy.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Inquiry on this privacy policy
      </Title>
      <Paragraph className="fs">
        If you have any questions regarding this Privacy Policy, or our use of your personal information, please contact us
        through our <Link href="https://mbcustomerservice.zendesk.com/hc/en-us/requests/new">customer care</Link>.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        How we use cookies
      </Title>

      <Paragraph className="fs">
        A cookie is a small file which asks permission to be placed on your computer&apos;s hard drive. Once you agree, the file
        is added, and the cookie helps analyze web traffic or lets you know when you visit a site. Cookies allow web applications
        to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by
        gathering and remembering information about your preferences.
      </Paragraph>
      <Paragraph className="fs">
        We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and
        improve our website in order to tailor it to customer needs. We only use this information for statistical analysis
        purposes and then the data is removed from the system. If you, however, want to delete cookies from your browser or
        device, we suggest that you use the option to delete cookies and other site and plug-in data, including data stored on
        your device from your browser settings normally found in browsing history.
      </Paragraph>
      <Paragraph className="fs">
        Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which
        you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you
        choose to share with us. Most web browsers automatically accept cookies, but you can usually modify your browser setting
        to decline cookies if you prefer. This may however prevent you from taking full advantage of the website.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Cookie description
      </Title>
      <Paragraph className="fs">Facebook cookie: This will be used for third party authentication via Facebook.</Paragraph>
      <Paragraph className="fs">
        Analytics cookie: A first party browser-based cookie allows the site to measure audience behavior. There is no personally
        identifiable information stored in these cookies.
      </Paragraph>
      <Paragraph className="fs pb-9">
        Advertising cookies: These are third party cookies (example, Facebook, Google etc) from our partners to re-target
        advertisements in their respective platforms.
      </Paragraph>
    </MobileLayout>
  );
};
