import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationStore } from 'entities/Store/Store.communication';
import { communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationCustomer } from 'entities/Customer/Customer.communication';
import { communicationCustomersPhones } from 'entities/CustomersPhones/CustomersPhones.communication';
import { communicationMenu } from 'entities/Menu/Menu.communication';
import { communicationOrder } from 'entities/Orders/Orders.communication';
import { communicationPeakHours } from 'entities/PeakHours/PeakHours.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationOrderHistory } from 'entities/OrderHistory/OrderHistory.communication';
import { communicationRatings } from 'entities/Ratings/Ratings.communication';
import { communicationCart } from 'entities/Cart/Cart.communication';
import { communicationQR } from 'entities/QR/QR.communication';
import { communicationPromo } from 'entities/Promo/Promo.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  [key: string]: any;
}

const reducers = {
  ...communicationAuth.reducers,
  ...communicationCustomer.reducers,
  ...communicationCustomersPhones.reducers,
  ...communicationMenu.reducers,
  ...communicationUser.reducers,
  ...communicationPeakHours.reducers,
  ...communicationStore.reducers,
  ...communicationUI.reducers,
  ...communicationOrder.reducers,
  ...communicationOrderHistory.reducers,
  ...communicationRatings.reducers,
  ...communicationCart.reducers,
  ...communicationQR.reducers,
  ...communicationPromo.reducers
};

export default reducers;
