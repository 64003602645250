import React from 'react';
import { Link } from 'react-router-dom';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { ERoutesCommon } from 'common/models/routesModel';

export const OrderPageEmpty: React.FC = () => {
  return (
    <MobileLayout header="Your Order">
      <div className="fs-xxs t-align-c mb-8">You haven’t made any orders yet</div>
      <div className="t-align-c ">
        <Link className="link fs-xs" to={ERoutesCommon.Root}>
          Go to Menu
        </Link>
      </div>
    </MobileLayout>
  );
};
