import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ADMIN_COMMUNICATION_LINK } from 'common/consts/common';
import { phonePattern } from 'common/consts/validation.consts';
import {
  communicationCustomersPhones,
  ICustomersPhonesConnectedProps
} from 'entities/CustomersPhones/CustomersPhones.communication';

interface IComponentProps {
  onSuccess?: (phone: string) => void;
}

type AllProps = IComponentProps & ICustomersPhonesConnectedProps;

const PhoneConfirmComponent: React.FC<AllProps> = props => {
  const { customersPhonesModel } = props;
  const { data, params, errors, loading } = customersPhonesModel;
  const { fields } = useFormMapper(['phone'], data, params?.params, errors);

  const submit = (values: any) => {
    const { onSuccess, addCustomersPhonesModel } = props;

    if (loading) {
      return;
    }

    const successCallback = () => onSuccess && onSuccess(values.phone);
    const failCallback = () =>
      message.warning(
        <div>
          We can`t send the confirmation code. Please contact Customer Care{' '}
          <a target="_blank" rel="noopener noreferrer" href={ADMIN_COMMUNICATION_LINK} className="link fs-xxs">
            here.
          </a>
        </div>
      );

    addCustomersPhonesModel({ onSuccess: successCallback, params: values, onFail: failCallback });
  };

  return (
    <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item
        name="phone"
        rules={[
          { required: true, message: 'Please input your mobile number (+63 9XX XXX XXXX)' },
          {
            pattern: phonePattern,
            message: 'Please input your mobile number (+63 9XX XXX XXXX)'
          }
        ]}
      >
        <Input name="phone" type="tel" placeholder="+63 9XX XXX XXXX" />
      </Form.Item>

      <Button size="large" block className="btn" type="primary" htmlType="submit" disabled={loading}>
        Send code
      </Button>
    </Form>
  );
};

export const PhoneConfirm = communicationCustomersPhones.injector(PhoneConfirmComponent);
