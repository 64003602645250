import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Form, Input, Button } from 'antd';
import { useHistory } from 'react-router';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useQuery } from 'common/hooks';
import { ERoutesCommon } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { requiredValidationRule, passValidationRules } from 'common/consts/validation.consts';
import { LoadingSpin } from 'common/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';

type AllProps = IAuthConnectedProps;

const PasswordRestorePageComponent: React.FC<AllProps> = props => {
  const { authPasswordRestore, passwordRestoreAuthTokenCheck, authTokenCheck } = props;
  const { loading, params, errors, data } = authPasswordRestore;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = authTokenCheck;

  const history = useHistory();
  const token: string = useQuery().get('token') || '';
  const isTokenValid = !tokenCheckLoading && !tokenCheckError;
  const { fields } = useFormMapper(['password'], data, params, errors);

  useEffect(() => {
    passwordRestoreAuthTokenCheck({ token });
  }, [passwordRestoreAuthTokenCheck, token]);

  if (tokenCheckLoading) {
    return <LoadingSpin />;
  }

  const submit = (values: any) => {
    if (!token) {
      message.error(EAuthErrorMessage.InvalidLink);
      history.push(ERoutesCommon.Root);

      return;
    }

    const { updateAuthPasswordRestore } = props;

    const password = values?.password || '';

    updateAuthPasswordRestore({ token, password });
  };

  const content = isTokenValid ? (
    <MobileLayout header="Create new password">
      <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
        <Form.Item rules={[requiredValidationRule, ...passValidationRules]} name="password" label="New password" validateFirst>
          <Input.Password type="password" placeholder="Password" disabled={loading} />
        </Form.Item>

        <Button size="large" block className="btn" htmlType="submit" type="primary">
          Submit
        </Button>
      </Form>
    </MobileLayout>
  ) : (
    <MobileLayout
      header="The request to reset your password has expired."
      subHeader={
        <>
          Please{' '}
          <Link to={ERoutesCommon.PasswordForgot} className="link" title="Request to change your password">
            request to change your password
          </Link>{' '}
          again.
        </>
      }
    />
  );

  return <TwoColumnsLayout left={content} />;
};

export const PasswordRestorePage = communicationAuth.injector(PasswordRestorePageComponent);
