import React, { useEffect } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useQuery } from 'common/hooks';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';

type AllProps = IAuthConnectedProps;

const EmailConfirmComponent: React.FC<AllProps> = props => {
  const { authTokenCheck, confirmUserAuthTokenCheck } = props;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = authTokenCheck;
  const isTokenValid = !tokenCheckLoading && !tokenCheckError;

  const history = useHistory();
  const token: string = useQuery().get('token') || '';

  useEffect(() => {
    if (!token) {
      message.error(EAuthErrorMessage.InvalidLink);
      history.push(ERoutesCommon.Root);
      return;
    }

    confirmUserAuthTokenCheck({ token });
  }, [confirmUserAuthTokenCheck, token, history]);

  if (tokenCheckLoading) {
    return <LoadingSpin />;
  }

  return isTokenValid ? (
    <LoadingSpin />
  ) : (
    <TwoColumnsLayout
      left={
        <MobileLayout
          header="Email verification link has expired"
          subHeader={
            <>
              Try to{' '}
              <Link to={ERoutesPublic.Signup} className="link" title="Sign up">
                sign up
              </Link>{' '}
              again. If you have already created an account, you may{' '}
              <Link to={ERoutesPublic.Login} className="link" title="Login">
                log in
              </Link>{' '}
              to the platform.
            </>
          }
        />
      }
    />
  );
};

export const EmailConfirm = communicationAuth.injector(EmailConfirmComponent);
