import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { requiredValidationRule, passValidationRules, oldPassValidationRules } from 'common/consts/validation.consts';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { ICustomerSetPasswordParams, ICustomerUpdatePasswordParams } from 'entities/Customer/Customer.models';

interface IComponentProps {
  isPasswordSet: boolean;
  userId: string;
}

type AllProps = ICustomerConnectedProps & IComponentProps;

const UpdatePasswordFormComponent: React.FC<AllProps> = props => {
  const { isPasswordSet, setCustomerPassword, customerPassword, userId, updateCustomerPassword, clearCustomerPassword } = props;
  const { loading: customerPasswordLoading, params: customerPasswordParams, errors: customerPasswordErrors } = customerPassword;
  const loading = customerPasswordLoading;
  const { fields } = useFormMapper(['password', 'oldPassword'], null, customerPasswordParams, customerPasswordErrors);
  const buttonText = isPasswordSet ? 'Change password' : 'Set password';

  useEffect(() => {
    return () => clearCustomerPassword();
  }, [clearCustomerPassword]);

  const onSubmit = (values: any) => {
    const { password, oldPassword } = values;

    if (isPasswordSet) {
      const params: ICustomerUpdatePasswordParams = {
        id: userId,
        password,
        oldPassword
      };
      updateCustomerPassword(params);
    } else {
      const params: ICustomerSetPasswordParams = {
        id: userId,
        password
      };
      setCustomerPassword(params);
    }
  };

  return (
    <Form onFinish={onSubmit} requiredMark={false} layout="vertical" fields={fields}>
      {isPasswordSet && (
        <Form.Item
          rules={[requiredValidationRule, ...oldPassValidationRules]}
          name="oldPassword"
          label="Old password"
          validateFirst
        >
          <Input.Password type="password" disabled={loading} />
        </Form.Item>
      )}

      <Form.Item rules={[requiredValidationRule, ...passValidationRules]} name="password" label="New password" validateFirst>
        <Input.Password type="password" disabled={loading} />
      </Form.Item>

      <Button size="large" className="btn" htmlType="submit" type="primary" disabled={loading} block title={buttonText}>
        {buttonText}
      </Button>
    </Form>
  );
};

export const UpdatePasswordForm = communicationCustomer.injector(UpdatePasswordFormComponent);
