import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { ESocialType } from 'entities/Auth/Auth.models';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';

interface IComponentProps {
  isVisible: boolean;
  socialType?: ESocialType;
  onClose: () => void;
}

type AllProps = IComponentProps & ICustomerConnectedProps;

const socialTypeLabels: Record<ESocialType, string> = {
  [ESocialType.Facebook]: 'Facebook',
  [ESocialType.Google]: 'Google'
};

const SocialUnlinkConfirmModalComponent: React.FC<AllProps> = props => {
  const { isVisible, socialType, onClose, disconnectFacebookCustomerModel, disconnectGoogleCustomerModel } = props;
  const header = socialType
    ? `Are you sure you want to remove your ${socialTypeLabels[socialType]} account from your MB Go account? `
    : '';
  const subHeader = socialType
    ? `You may need to remove MB Go from your allowed apps from your ${socialTypeLabels[socialType]} account for this to be completed.`
    : '';

  const disconnectSocial = () => {
    socialType === ESocialType.Facebook && disconnectFacebookCustomerModel();
    socialType === ESocialType.Google && disconnectGoogleCustomerModel();
    onClose();
  };

  return (
    <ModalContent visible={isVisible} close={onClose} header={header} subHeader={subHeader}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={disconnectSocial}>
        Remove
      </Button>
      <Button block className="btn" onClick={onClose}>
        Cancel
      </Button>
    </ModalContent>
  );
};

export const SocialUnlinkConfirmModal = communicationCustomer.injector(SocialUnlinkConfirmModalComponent);
