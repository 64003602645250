import { useCallback, useEffect, useState } from 'react';

export function useResetTimer() {
  const [seconds, setSeconds] = useState<number>(timeout);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isTimerActive) {
      interval = setInterval(() => {
        setSeconds(oldValue => oldValue - 1);
      }, 1000);
    }

    if (isTimerActive && seconds === 0) {
      setIsTimerActive(false);
      interval && clearInterval(interval);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [isTimerActive, seconds, setIsTimerActive]);

  const setTimer = useCallback(() => {
    setIsTimerActive(true);
    setSeconds(timeout);
  }, []);

  return { setTimer, isTimerActive, seconds };
}

const timeout = 60;
