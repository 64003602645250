import { APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch, EActionsTypes } from '@axmit/redux-communications';
import { BaseHttpTransport } from '@axmit/transport';
import axios from 'axios';
import { IPromoModel } from 'entities/Promo/Promo.models';

const namespace = 'promo';

export interface IPromoConnectedProps {
  promoModel: StoreBranch<IPromoModel>;
  getPromoModel(): void;
}

const transport = new BaseHttpTransport('promos/customer', axios);

const ModelAPIProvider = [new APIProvider(EActionsTypes.get, transport.getCollection)];

const branches = [new Branch('model', ModelAPIProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPromo = buildCommunication<IPromoConnectedProps>(strategy);
