import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { IModalContent, ModalContent } from 'common/components';
import { LoginForm, SignUp } from 'entities/Auth/components';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EAuthModals } from 'entities/UI/UI.models';

type AllProps = ICustomerConnectedProps & IUIConnectedProps;

const AuthModalComponent: React.FC<AllProps> = ({ uiAuthModal, updateUiAuthModal, clearCustomerModel }) => {
  const [contentType, toggleModalType] = useState<EAuthModals>();
  const isVisible = uiAuthModal?.data?.isVisible;

  useEffect(() => {
    const { data } = uiAuthModal;
    if (isVisible) {
      toggleModalType(data?.type || EAuthModals.Type);
    }
  }, [isVisible, toggleModalType, uiAuthModal]);

  const closeModal = () => {
    updateUiAuthModal({ isVisible: false });
  };

  const components: IModalContent = {
    [EAuthModals.Type]: {
      content: (
        <>
          <Button
            block
            className="btn mb-9"
            size="large"
            type="primary"
            onClick={() => {
              toggleModalType(EAuthModals.Signup);
            }}
          >
            Register
          </Button>

          <Button
            size="large"
            block
            className="btn"
            onClick={() => {
              toggleModalType(EAuthModals.Login);
            }}
          >
            Log in
          </Button>
        </>
      ),
      header: 'Please register or log in'
    },
    [EAuthModals.Login]: {
      content: <LoginForm onSuccess={closeModal} close={closeModal} />,
      header: 'Log in'
    },
    [EAuthModals.Signup]: {
      content: (
        <SignUp
          onSuccess={() => {
            toggleModalType(EAuthModals.Verify);
          }}
        />
      ),
      header: 'Registration',
      subheader: 'It will take less than a minute'
    },
    [EAuthModals.Verify]: {
      content: (
        <Button
          size="large"
          block
          className="btn"
          type="primary"
          onClick={() => {
            updateUiAuthModal({ isVisible: false });
            clearCustomerModel();
          }}
        >
          Got it!
        </Button>
      ),
      header: 'Please verify your account',
      subheader: 'Confirmation link has been sent to your email'
    }
  };
  const modalContent = contentType && components[contentType];

  return (
    <ModalContent visible={isVisible} close={closeModal} header={modalContent?.header} subHeader={modalContent?.subheader}>
      {modalContent?.content}
    </ModalContent>
  );
};

export const AuthModal = communicationUI.injector(communicationCustomer.injector(AuthModalComponent));
