import queryString from 'querystring';
import React, { useEffect } from 'react';
import { saveCreds } from '@axmit/axios-patch-jwt';
import { useHistory } from 'react-router';
import { LoadingSpin } from 'common/components';
import { ERoutesCommon } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

const SocialAuthPageComponent: React.FC<IAuthConnectedProps> = props => {
  const { updateAuthModel, updateAuthSocialNetworkRegistered } = props;
  const history = useHistory();

  useEffect(() => {
    const userIdData = queryString.parse(window.location.search)[`?userId`];
    const access = queryString.parse(window.location.search)[`access`];
    const refreshData = queryString.parse(window.location.search)[`refresh`];
    const isRegisteredData = queryString.parse(window.location.search)[`isRegistered`];
    const refresh = Array.isArray(refreshData) ? refreshData[0] : refreshData;
    const issuedAt = new Date().getTime();
    const expiredAt = new Date().getTime();
    const userId = Array.isArray(userIdData) ? userIdData[0] : userIdData;
    const isRegistered = isRegisteredData && isRegisteredData === 'true';

    if (userId && access && refresh) {
      const token = {
        id: userId,
        userId,
        issuedAt,
        expiredAt,
        token: Array.isArray(refresh) ? refresh[0] : refresh
      };

      saveCreds({
        refresh: token,
        access: token
      }).then(() => {
        isRegistered && updateAuthSocialNetworkRegistered(true);
        updateAuthModel({ refreshToken: refresh });
      });
    } else {
      history.push(ERoutesCommon.Root);
    }
  }, [updateAuthModel, history, updateAuthSocialNetworkRegistered]);

  return <LoadingSpin />;
};

export const SocialAuthPage = communicationAuth.injector(SocialAuthPageComponent);
