import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Row, Spin } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { communicationPromo, IPromoConnectedProps } from 'entities/Promo/Promo.communication';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';
import { PromoCard } from 'entities/Promo/components/PromoCard';

type AllProps = IPromoConnectedProps & ICartConnectedProps;

const PersonalPromoPageComponent: React.FC<AllProps> = props => {
  const { promoModel, cartModel, addPromoCodeCartModel, removePromoCodeCartModel } = props;

  const { data: promoModelData, loading: promoLoading } = promoModel;
  const { loading: cartLoading } = cartModel;
  const activePromo = promoModelData?.activePromo;
  const availablePromos = promoModelData?.availablePromos;
  const history = useHistory();

  useEffect(() => {
    const { getPromoModel } = props;
    getPromoModel();
  }, []);

  const applyPromo = (code?: string) => {
    code && addPromoCodeCartModel({ code });
  };

  const removePromo = () => {
    removePromoCodeCartModel();
  };

  return (
    <Spin spinning={promoLoading}>
      <TwoColumnsLayout
        left={
          <MobileLayout header="MB Go! Exclusive deals" back={() => history.goBack()}>
            <div className="mb-7">
              Select from the current list of MB Go! exclusive deals to enjoy freebies and discounts with your order. Only with MB
              Go!
            </div>
            {activePromo && <PromoCard promoItem={activePromo} isActive onClick={removePromo} loading={cartLoading} />}
            {!!availablePromos?.length &&
              availablePromos.map((item, index) => (
                <PromoCard key={index} promoItem={item} onClick={applyPromo} loading={cartLoading} />
              ))}
            {!activePromo && !availablePromos?.length && <Row justify="center">You have no deals available yet.</Row>}
          </MobileLayout>
        }
      />
    </Spin>
  );
};

export const PersonalPromoPage = communicationCart.injector(communicationPromo.injector(PersonalPromoPageComponent));
