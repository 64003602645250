import React from 'react';
import { List } from 'antd';
import { IAddOnsCategory } from 'entities/Menu/Menu.models';
import { ICartAddItemAddOnParams } from 'entities/Cart/Cart.models';
import { AddOnsCategoriesListItem } from 'entities/AddOns/components/AddOnsCategoriesListItem';

interface IComponentProps {
  itemAddOns?: ICartAddItemAddOnParams[];
  allAddOnsCategories?: IAddOnsCategory[];
  update: (item: ICartAddItemAddOnParams[]) => void;
  needRemoveItem?: boolean;
}

export const AddonsCategoriesList: React.FC<IComponentProps> = props => {
  const { allAddOnsCategories, itemAddOns, update, needRemoveItem } = props;

  const handleUpdateAddOns = (addOn: ICartAddItemAddOnParams) => {
    const newAddOns = itemAddOns?.filter(({ menuItemId }) => menuItemId !== addOn?.menuItemId) || [];

    if (addOn?.quantity !== 0) {
      newAddOns.push(addOn);
    }

    update(newAddOns);
  };

  return (
    <List
      className="list-without-empty menu-card-addons pr-3"
      dataSource={allAddOnsCategories}
      renderItem={category => (
        <AddOnsCategoriesListItem
          itemAddOns={itemAddOns}
          addOnCategory={category}
          needRemoveItem={!!needRemoveItem}
          update={handleUpdateAddOns}
        />
      )}
    />
  );
};
