import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosInstance, AxiosStatic } from 'axios';
import { IStoreModel, IStoreCollection } from 'entities/Store/Store.models';

const getByShortIdPath = 'by-short-id';

class StoresModelTransport extends BaseHttpTransport<AxiosInstance, IStoreModel> {
  public getByShortId = (code: string) => axios.get(`${this.path}/${getByShortIdPath}/${code}`);
}

export const storesModelTransport = new StoresModelTransport('/stores', axios);
export const storesListTransport = new BaseHttpTransport<AxiosStatic, IStoreModel, IStoreCollection>(
  '/stores/customer-list',
  axios
);
