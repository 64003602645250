import React, { useMemo } from 'react';
import { Button, Row, Typography } from 'antd';
import moment from 'moment';
import { ModalContent } from 'common/components';
import { CountSelector } from 'common/components/CountSelector';
import { communicationMenu, IMenuConnectedProps } from 'entities/Menu/Menu.communication';
import { MenuItemImage } from 'entities/Menu/components/MenuItemImage';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { EStoreState } from 'entities/Store/Store.models';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { useCartItemForm } from 'entities/Cart/Cart.hooks';
import { getCartItemTotalSum } from 'entities/Cart/Cart.helpers';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';
import { mapMenuItemToCartAddItemParams } from 'entities/Cart/Cart.mappers';
import { ECategoryType } from 'entities/Menu/Menu.models';
import { AddonsCategoriesList } from 'entities/AddOns/components/AddonsCategoriesList';

type AllProps = IUIConnectedProps & IMenuConnectedProps & ICustomerConnectedProps & IStoreConnectedProps & ICartConnectedProps;

const MenuDetailsModalComponent: React.FC<AllProps> = props => {
  const { uiCartItemModal, closeUiCartItemModal, menuAddOns, customerModel, storesModel, addItemCartModel } = props;
  const { data: uiCartItemData } = uiCartItemModal;
  const { isVisible, menuItem } = uiCartItemData || {};

  const { data: customer } = customerModel;
  const { data: storesData } = storesModel;
  const store = customer?.store || storesData || undefined;
  const isStoreOffline = store && store.state === EStoreState.Offline;
  const hasOpenClose = store?.closeTime && store?.openTime;
  const storeOpenTime = formatTime(moment(store?.openTime || '00:00', 'HH:mm'));

  const { data: menuAddOnsData } = menuAddOns;
  const { categoriesToAsset: addOnsCategories } = menuAddOnsData || {};
  const hasBEAddOns = !!addOnsCategories?.length;

  const { name, description, image, type } = menuItem || {};
  const isVisibleAddons = type === ECategoryType.IsAddOnsEnable && hasBEAddOns && !isStoreOffline;

  const initialValues = useMemo(() => (menuItem ? mapMenuItemToCartAddItemParams(menuItem) : undefined), [menuItem]);
  const { formValues, setFormValues, initValues } = useCartItemForm(initialValues);

  const { quantity, addOns, price } = formValues;

  const orderItemSum = getCartItemTotalSum(formValues);

  const submit = () => {
    const { updateUiAuthModal } = props;
    if (!customer) {
      closeUiCartItemModal();
      updateUiAuthModal({ isVisible: true });
      return;
    }
    addItemCartModel({ params: formValues });
    closeUiCartItemModal();
  };

  return (
    <ModalContent
      closable={true}
      afterClose={() => {
        setFormValues(initValues());
      }}
      visible={isVisible}
      close={() => {
        closeUiCartItemModal();
      }}
    >
      <div className="menu-details">
        <div className="menu-details__content">
          <Row justify="space-between" className="d-flex_no-wrap">
            <div>
              {name && <div className="menu-details__header">{name}</div>}
              {description && (
                <Typography.Paragraph className="menu-details__description word-break">{description}</Typography.Paragraph>
              )}

              {!isStoreOffline && (
                <CountSelector
                  label={
                    <Row className="fs" align="middle">
                      P&nbsp;<span className="fs-xs font-weight-7">{price}</span>
                    </Row>
                  }
                  value={quantity}
                  onChange={(newCount: number) => {
                    setFormValues(prevState => ({ ...prevState, quantity: newCount }));
                  }}
                  min={1}
                />
              )}
            </div>

            {image && (
              <div className="menu-details__image-wrapper">
                <MenuItemImage src={image} className="menu-details__image" alt={name} />
              </div>
            )}
          </Row>

          {isVisibleAddons && (
            <AddonsCategoriesList
              allAddOnsCategories={addOnsCategories}
              itemAddOns={addOns}
              update={items => {
                setFormValues(prevState => ({ ...prevState, addOns: items }));
              }}
            />
          )}
        </div>
      </div>

      {isStoreOffline ? (
        <div className="t-align-c fs-xss">
          <span className="font-weight-7">Store is offline</span>
          {hasOpenClose && ` — Opens at ${storeOpenTime}`}
        </div>
      ) : (
        <Button size="large" block className="btn" type="primary" disabled={isStoreOffline} onClick={submit}>
          Add to bag
          {<span className="pl-2 font-weight-default"> + P{orderItemSum || 0}</span>}
        </Button>
      )}
    </ModalContent>
  );
};

export const MenuDetailsModal = communicationCart.injector(
  communicationStore.injector(
    communicationCustomer.injector(communicationUI.injector(communicationMenu.injector(MenuDetailsModalComponent)))
  )
);
