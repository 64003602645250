import React from 'react';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { EErrorStatus, errorMapper } from '@axmit/error-helper';
import { message } from 'antd';
import { envConfig } from 'common/config/env.config';
import { ThirdPartyServicesHelper } from 'common/helpers/ThirdPartyServicesHelper.helpers';
import { storageHelper } from 'common/helpers/storage.helper';
import createStore from 'app/store/createStore';
import 'app/assets/sass/index.scss';
import { App } from 'app/App';
import { ECustomerPhoneErrorCode, ECustomerPhoneErrorMessage } from 'entities/CustomersPhones/CustomersPhones.models';
import { EBaseErrorMessage } from 'entities/Auth/Auth.models';

// Check access to localStorage
const storage = storageHelper.checkAndCreateLocalStorage();

// Axios initialization
export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth'
};

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN || envConfig.REACT_APP_SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded']
});

// @ts-ignore
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};

ThirdPartyServicesHelper.initGoogleTag(envConfig.GOOGLE_TAG_ID || envConfig.REACT_APP_GOOGLE_TAG_ID, envConfig.NODE_ENV);
ThirdPartyServicesHelper.initFacebookPixel(envConfig.FB_PIXEL_ID || envConfig.REACT_APP_FB_PIXEL_ID, envConfig.NODE_ENV);
ThirdPartyServicesHelper.initGoogleAnalytics(
  envConfig.GOOGLE_ANALYTICS_ID || envConfig.REACT_APP_GOOGLE_ANALYTICS_ID,
  envConfig.NODE_ENV
);

configureAxiosJWTInterseptors(axiosConfig);

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => ({
  ...config,
  headers: {
    'Source-Service': 'customer',
    'x-client-version': envConfig.RELEASE_VERSION || envConfig.REACT_APP_RELEASE_VERSION || '',
    ...config.headers
  }
}));
axios.interceptors.response.use(
  response => response.data,
  error =>
    errorMapper(
      error,
      { showError: message.error, defaultError: EBaseErrorMessage.Default },
      {
        withPopupMessage: [
          EErrorStatus.BadRequest,
          EErrorStatus.Conflict,
          EErrorStatus.PayloadTooLarge,
          EErrorStatus.FailedDependency,
          EErrorStatus.TooManyRequests,
          EErrorStatus.RetryWith,
          EErrorStatus.InternalServerError
        ]
      },
      (msg: [string, string] | string) => {
        if (Array.isArray(msg)) {
          const [code, text] = msg;

          if (code === ECustomerPhoneErrorCode.NotUnique) {
            return ECustomerPhoneErrorMessage.NotUnique;
          }
          return text;
        }

        return msg;
      }
    )
);
// Render Setup

const MOUNT_NODE = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
const store = createStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
