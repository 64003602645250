import queryString from 'querystring';
import { STORE_CODE_URL_PARAM } from 'common/consts/Store.const';
import { ThirdPartyServicesHelper } from 'common/helpers/ThirdPartyServicesHelper.helpers';
import { IStoreModel } from 'entities/Store/Store.models';

export const getStoreAddressString = (store?: IStoreModel) => {
  if (!store || !store.address) {
    return '';
  }

  const { street, house, city, region } = store.address;

  const streetFormatted = street ? `${street} St. ` : '';
  const houseFormatted = house ? `${house}. ` : '';
  const cityFormatted = city ? `${city}. ` : '';
  const regionFormatted = region ? `${region}.` : '';

  return `${streetFormatted}${houseFormatted}${cityFormatted}${regionFormatted}`;
};

export const getMapLinkToStore = (store?: IStoreModel): string => {
  if (!store?.address) {
    return '#';
  }
  const { lat, lon } = store.address;

  return ThirdPartyServicesHelper.getGoogleMapsLink(lat, lon);
};

export const getStoreShortId = (): string => {
  const code = queryString.parse(window.location.search)[`?${STORE_CODE_URL_PARAM}`];
  const formattedCode = code ? (Array.isArray(code) ? code[0] : code) : '';
  return formattedCode.toUpperCase();
};
