import React, { useEffect } from 'react';
import { Button, Form, Rate, Skeleton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useHistory, useParams } from 'react-router';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { rateStoreValidationRules } from 'common/consts/validation.consts';
import { ERoutesCommon } from 'common/models/routesModel';
import { communicationRatings, IRatingsConnectedProps } from 'entities/Ratings/Ratings.communication';
import { communicationOrderHistory, IOrderHistoryConnectedProps } from 'entities/OrderHistory/OrderHistory.communication';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { RatingsHooks } from 'entities/Ratings/Ratings.hooks';

interface IParams {
  id?: string;
}

interface IFormValues {
  rate: number;
  quality: number;
  serve: number;
  friendly: number;
  feedback?: string;
}

type AllProps = IRatingsConnectedProps & IOrderHistoryConnectedProps & ICustomerConnectedProps;

const RateStorePageComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { addRatingsModel, ratingsModel, getOrderHistoryModel, orderHistoryModel, clearOrderHistoryModel, customerModel } = props;
  const { id } = useParams<IParams>();
  const { loading } = ratingsModel;
  const { data: orderHistoryData, loading: orderHistoryLoading } = orderHistoryModel;
  const { store } = orderHistoryData || {};
  const storeName = store?.storeName;
  const header = orderHistoryLoading ? (
    <Skeleton.Input active style={{ width: '320px' }} />
  ) : (
    `How was your order at ${storeName || 'store'} today?`
  );

  useEffect(() => {
    id && getOrderHistoryModel(id);
    return () => clearOrderHistoryModel();
  }, [id, getOrderHistoryModel, clearOrderHistoryModel]);

  const { data: customerData } = customerModel;
  RatingsHooks.useRedirectIfError(orderHistoryData, customerData);

  const submit = (values: IFormValues) => {
    const { feedback, rate, friendly, quality, serve } = values;
    id && addRatingsModel({ feedback, rate, friendly, quality, serve, orderHistory: id });
  };

  const cancel = () => history.push(ERoutesCommon.Main);

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header={header}>
          <Form onFinish={submit} layout="vertical">
            <Form.Item
              name="rate"
              rules={rateStoreValidationRules.rate}
              label={
                <span>
                  Please rate your <b>overall</b> experience from 1-5
                </span>
              }
            >
              <Rate allowClear={false} className="rate-store-stars" />
            </Form.Item>
            <Form.Item name="quality" rules={rateStoreValidationRules.quality} label="How was the quality of your food today?">
              <Rate allowClear={false} className="rate-store-stars" />
            </Form.Item>
            <Form.Item name="serve" rules={rateStoreValidationRules.serve} label="How fast did we serve you today?">
              <Rate allowClear={false} className="rate-store-stars" />
            </Form.Item>
            <Form.Item
              name="friendly"
              rules={rateStoreValidationRules.friendly}
              label="How friendly was our crew's service today?"
            >
              <Rate allowClear={false} className="rate-store-stars" />
            </Form.Item>
            <Form.Item className="mb-9" name="feedback" rules={rateStoreValidationRules.feedback} label="Your feedback">
              <TextArea name="feedback" rows={6} placeholder="What would you like the store to know? (240 ch. max)" />
            </Form.Item>
            <Form.Item>
              <Button className="btn" block type="primary" htmlType="submit" disabled={loading}>
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button className="btn" block onClick={cancel}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </MobileLayout>
      }
    />
  );
};

export const RateStorePage = communicationCustomer.injector(
  communicationOrderHistory.injector(communicationRatings.injector(RateStorePageComponent))
);
