import { IUpdateParams } from '@axmit/transport';
import { IBasePathParams } from 'common/models/requestModels';

export interface ICustomerModel extends Components.Schemas.CustomerDto {}
export interface ICustomerAddParams extends Components.Schemas.CustomerCreateDto {
  onSuccess?: Function;
}
export interface ICustomerUpdateParams extends Components.Schemas.CustomerUpdateDto, IUpdateParams {}
export interface ICustomerSetStoreParams extends Components.Schemas.SetStoreDto, IUpdateParams {}
export interface ICustomerSetPasswordPath extends Paths.CustomerControllerSetPassword.PathParameters {}
export interface ICustomerSetPasswordParams extends Components.Schemas.SetPasswordDto, ICustomerSetPasswordPath {}
export interface ICustomerUpdatePasswordPath extends Paths.CustomerControllerUpdatePassword.PathParameters {}
export interface ICustomerUpdatePasswordParams extends Components.Schemas.UpdatePasswordDto, ICustomerUpdatePasswordPath {}

export interface IConfirmationModel extends Components.Schemas.TokenPairPayloadDto {}
export interface IConfirmationParams extends Components.Schemas.EmailConfirmDto {}

export interface ICustomerCompleteRegistrationParams
  extends Components.Schemas.CustomerCompleteRegistrationDto,
    IBasePathParams {}

export enum ECustomerErrorCode {
  AlreadyRegistered = 'error.customerAlreadyConfirmedException',
  CodeSendingError = 'error.httpException'
}

export enum ECustomerErrorMessage {
  AlreadyRegistered = 'This Email Address is already in use'
}

export enum ECustomerStatus {
  Active = 'active',
  Blocked = 'blocked',
  Pending = 'pending'
}
