import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuMode } from 'antd/lib/menu';
import { ERoutes, ERoutesCommon, ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';

type AllProps = IAuthConnectedProps & RouteComponentProps & IUserConnectedProps & IComponentProps;

interface IComponentProps {
  mode?: MenuMode;
  mobile?: boolean;
  close?: () => void;
}

class HeaderMenuComponent extends React.Component<AllProps> {
  render() {
    const { history, mobile, authModel } = this.props;
    const { data } = authModel;
    const authorized = Boolean(data && Object.keys(data).length !== 0);
    const visibleLogin = Boolean(!authorized && mobile);
    const visibleLogout = Boolean(authorized && mobile);

    const path = history.location.pathname;
    const currentPath = path?.split(/(^\/[a-zA-Z-]*)/)[1];

    const showLogo = !mobile;
    const mobileClass = mobile ? 'header-menu_mobile' : '';
    const mode = mobile ? 'vertical' : 'horizontal';

    return (
      <Menu selectedKeys={[currentPath]} mode={mode} className={`header-menu ${mobileClass}`}>
        {showLogo && (
          <Menu.Item key="logo" onClick={this.redirectToMain}>
            <span className="header__title">MB Go!</span>
          </Menu.Item>
        )}

        <Menu.Item key={ERoutesCommon.Root} onClick={this.redirect} className="header-menu__activilable-item">
          Menu
        </Menu.Item>

        {authorized && (
          <Menu.Item key={ERoutes.Order} onClick={this.redirect} className="header-menu__activilable-item">
            Current Order
          </Menu.Item>
        )}

        {authorized && (
          <Menu.Item key={ERoutes.AccountSettings} onClick={this.redirect} className="header-menu__activilable-item">
            Account settings
          </Menu.Item>
        )}

        {authorized && (
          <Menu.Item key={ERoutesPrivate.OrderHistory} onClick={this.redirect} className="header-menu__activilable-item">
            Order history
          </Menu.Item>
        )}

        {visibleLogin && (
          <Menu.Item key={ERoutesPublic.Login} onClick={this.redirect} className="header-menu__activilable-item mt-8">
            Log In
          </Menu.Item>
        )}

        {visibleLogout && (
          <Menu.Item key="logout" onClick={this.logout} className="header-menu__activilable-item mt-8">
            Log out
          </Menu.Item>
        )}

        <Menu.Item key={ERoutesCommon.Privacy} onClick={this.redirect} className="header-menu__link-item">
          Privacy policy
        </Menu.Item>

        <Menu.Item key={ERoutesCommon.ToS} onClick={this.redirect} className="header-menu__link-item">
          Terms of Service
        </Menu.Item>
      </Menu>
    );
  }

  logout = () => {
    const { deleteAuthModel, close } = this.props;
    close && close();
    deleteAuthModel();
  };

  redirect = (e: MenuInfo) => {
    const { history, close } = this.props;
    close && close();
    history.push(e.key.toString());
  };

  redirectToMain = () => {
    const { history, close } = this.props;
    close && close();
    history.push(ERoutesCommon.Root);
  };
}

export const HeaderMenu = communicationUser.injector(communicationAuth.injector(withRouter(HeaderMenuComponent)));
