import React, { memo } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { StoreRating } from 'common/components/StoreRating';
import { getStoreAddressString } from 'common/helpers/Store.helper';
import iconChevron from 'app/assets/images/chevron.svg';
import { EStoreState, IStoreModel } from 'entities/Store/Store.models';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';

interface IComponentProps {
  store?: IStoreModel;
  disabled?: boolean;
  onClick?: () => void;
}

const StoreCardComponent: React.FC<IComponentProps> = props => {
  const { store, onClick, disabled } = props;
  const { state, storeName, openTime, closeTime } = store || {};

  const isStoreSelected = !!store;
  const storeAddress = getStoreAddressString(store);
  const storeStatusText = state ? `Store is ${state}` : '';
  const finalStoreName = storeName || '';
  const hasOpenTime = !!openTime;
  const finalOpenTime = moment(openTime || '00:00', 'HH:mm');
  const finalCloseTime = moment(closeTime || '00:00', 'HH:mm');
  const storeOpenCloseText =
    state === EStoreState.Online ? ` — Closes at ${formatTime(finalCloseTime)}` : ` — Opens at ${formatTime(finalOpenTime)}`;
  const classes = !disabled ? 'cursor-pointer' : '';

  const handleClick = () => {
    !disabled && onClick && onClick();
  };

  return (
    <Row align="middle" justify="space-between" wrap={false} style={{ minHeight: 72 }} className={classes} onClick={handleClick}>
      <Col>
        {isStoreSelected ? (
          <div className="mr-5">
            <div className="fs-xxs font-weight-5 color-default o-md lh-sm mb-1">
              <span title={finalStoreName}>{finalStoreName}</span>
              <StoreRating store={store} className="ml-3" />
            </div>
            <div className="fs-xxs color-default o-md lh-sm mb-1">{storeAddress}</div>
            <div className="fs color-default lh-sm">
              <b className={`store_${state}`}>{storeStatusText}</b> {hasOpenTime && storeOpenCloseText}
            </div>
          </div>
        ) : (
          <div className="mr-5">Please select a pick-up store</div>
        )}
      </Col>
      <Col>{!disabled && <img src={iconChevron} alt="chevron" />}</Col>
    </Row>
  );
};

export const StoreCard = memo(StoreCardComponent);
