export interface IMenuCollection extends Components.Schemas.AssetDto {}
export interface IGetMenuCollectionParams extends Paths.MenuControllerGet.QueryParameters {
  id: string;
}
export interface IGetMenuDefaultCollectionParams extends Paths.MenuControllerGetDefault.QueryParameters {}

export interface IMenuCategory extends Components.Schemas.CategoryToAssetDto {}
export interface IMenuItem extends Components.Schemas.MenuItemToAssetDto {}

export enum ECategoryType {
  IsAddOns = 'isAddOns',
  IsAddOnsEnable = 'isAddOnsEnable',
  IsAddOnsDisable = 'isAddOnsDisable'
}

export interface IMenuItemWithCategoryNameAndType extends IMenuItem {
  category?: string;
  type?: ECategoryType;
}

export interface IMenuAddOn extends Components.Schemas.MenuItemToAssetDto {}
export interface IAddOnsCategory extends Components.Schemas.CategoryToAssetDto {}

export enum EMenuErrorCode {
  ItemNotFound = 'error.menuItemNotFoundException'
}

export enum EMenuErrorMessage {
  ItemNotFound = 'Menu item not found'
}
