import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCollectionPreRequestDataMapper,
  buildCommunication,
  EActionsTypes,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import { put, select } from 'redux-saga/effects';
import { message } from 'antd';
import { EErrorStatus, IError } from '@axmit/error-helper';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { IParamWithCallback } from 'common/models/requestModels';
import {
  ECartErrorMessage,
  ECartErrorCode,
  ICartAddItemParams,
  ICartAddPromoCodeParams,
  ICartModel,
  ICartUpdateItemParams
} from 'entities/Cart/Cart.models';
import { cartTransport } from 'entities/Cart/Cart.transport';
import { ICustomerModel } from 'entities/Customer/Customer.models';
import { EStoreErrorCode, EStoreErrorMessage } from 'entities/Store/Store.models';
import { EMenuErrorCode, EMenuErrorMessage } from 'entities/Menu/Menu.models';
import { EOrdersErrorCode } from 'entities/Orders/Orders.models';

const namespace = 'cart';

export interface ICartConnectedProps {
  cartModel: StoreBranch<ICartModel, ICartAddItemParams | ICartUpdateItemParams | string, IError>;
  getCartModel(): void;
  addItemCartModel(params: IParamWithCallback<ICartAddItemParams>): void;
  updateItemCartModel(params: ICartUpdateItemParams): void;
  removeItemCartModel(id: string): void;
  addPromoCodeCartModel(params: ICartAddPromoCodeParams): void;
  removePromoCodeCartModel(): void;
  clearCartModel(): void;
}

const ModelApiProviders = [
  new APIProvider(EActionsTypes.get, cartTransport.getCart, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper()
  }),
  new APIProvider<ICartModel | null, IParamWithCallback<ICartAddItemParams>>('addItem', cartTransport.addItem, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    *onSuccess(response, originalParams) {
      const { items, total } = response || {};
      const { menuItemId } = originalParams?.params || {};
      const customerData: ICustomerModel = yield select(state => state?.customer?.model?.data);
      const { email } = customerData || {};
      const customerFullName = getCustomerFullName(customerData);

      if (menuItemId && items && total) {
        const cartItem = items.find(item => item.menuItemId === menuItemId);
        cartItem && GDLHelper.pushAddToBagEvent(customerFullName, email || '', total, cartItem);
      }
      originalParams?.onSuccess && originalParams.onSuccess();
    },
    onFail(response) {
      const errorCode = response?.data?.code;

      if (errorCode === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
      if (errorCode === EMenuErrorCode.ItemNotFound) {
        message.error(EMenuErrorMessage.ItemNotFound);
      }
    }
  }),
  new APIProvider('updateItem', cartTransport.updateItem, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    onFail(response) {
      const errorCode = response?.data?.code;

      if (errorCode === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
      if (errorCode === ECartErrorCode.CartItemNotFound) {
        message.error(ECartErrorMessage.CartItemNotFound);
      }
      if (errorCode === EMenuErrorCode.ItemNotFound) {
        message.error(EMenuErrorMessage.ItemNotFound);
      }
    }
  }),
  new APIProvider('removeItem', cartTransport.deleteItem, {
    clearParams: false,
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    onFail(response) {
      const errorCode = response?.data?.code;

      if (errorCode === ECartErrorCode.CartItemNotFound) {
        message.error(ECartErrorMessage.CartItemNotFound);
      }
      if (errorCode === EMenuErrorCode.ItemNotFound) {
        message.error(EMenuErrorMessage.ItemNotFound);
      }
      if (errorCode === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
    }
  }),
  new APIProvider('addPromoCode', cartTransport.addPromoCode, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    *onSuccess() {
      yield put({ type: getStartType('promo', 'model', EActionsTypes.get) });
    },
    onFail(response) {
      if (response?.data?.code === ECartErrorCode.PromoTemporarilyUnavailable) {
        message.error(ECartErrorMessage.PromoTemporarilyUnavailable);
        return;
      }

      if (
        response?.data?.code === ECartErrorCode.PromoNotFound ||
        (response?.data?.statusCode === EErrorStatus.Validation && response?.data?.code !== EOrdersErrorCode.UnavailablePromo)
      ) {
        message.error(ECartErrorMessage.PromoNotFound);
      }
    }
  }),
  new APIProvider('removePromoCode', cartTransport.deletePromoCode, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    *onSuccess() {
      yield put({ type: getStartType('promo', 'model', EActionsTypes.get) });
    },
    onFail(response) {
      if (response?.data?.code === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
    }
  })
];

const branches = [new Branch('model', ModelApiProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getCartModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get) });
}

export function* getSuccessCartModel(payload: ICartModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.get), payload });
}

export function* clearCartModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

export const communicationCart = buildCommunication<ICartConnectedProps>(strategy);
