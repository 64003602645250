export interface IOrderHistoryCollection extends Components.Schemas.OrderHistoryCollectionDto {}
export interface IOrderHistoryModel extends Components.Schemas.OrderHistoryDto {}
export interface IOrderHistoryMenuItem extends Components.Schemas.OrderHistoryItemDto {}
export interface IOrderHistoryCollectionFilter extends Paths.OrderHistoryControllerList.QueryParameters {}

export interface IOrderHistoryAddOnItem extends Components.Schemas.BaseOrderHistoryItemDto {}

export interface IOrderHistoryReorderModel extends Components.Schemas.CustomerDto {}
export interface IOrderHistoryAddReorderParams extends Paths.OrderHistoryControllerReorder.PathParameters {
  storeName?: string;
}

export enum EOrderHistoryErrorCode {
  StoreIsClosed = 'error.storeIsClosedException',
  EmptyOrder = 'error.orderHistoryIsEmptyException'
}

export enum EOrderHistoryErrorMessage {
  EmptyOrder = 'The items in your previous order are currently unavailable.'
}
