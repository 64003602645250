import { Rule } from 'rc-field-form/lib/interface';
import { envConfig } from 'common/config/env.config';

export const phonePattern = envConfig.NODE_ENV === 'development' ? /\+(639|79)[0-9]{9}$/ : /\+639[0-9]{9}$/;

export const requiredValidationRule = {
  required: true,
  message: 'This field is required'
};

const minPass = 8;
const maxPass = 64;

export const oldPassValidationRules: Rule[] = [
  {
    min: minPass,
    max: maxPass,
    message: 'Old password is incorrect, please try again'
  },
  {
    pattern: new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])'),
    message: 'Old password is incorrect, please try again'
  }
];

export const passValidationRules: Rule[] = [
  {
    min: minPass,
    max: maxPass,
    message: `Your password must be at least ${minPass} characters, contain at least one uppercase letter, one lowercase letter, and at least one digit`
  },
  {
    pattern: new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])'),
    message: `Your password must be at least ${minPass} characters, contain at least one uppercase letter, one lowercase letter, and at least one digit`
  }
];

export const emailValidationRules: Rule[] = [
  {
    required: true,
    message: 'Please enter valid email address'
  },
  {
    type: 'email',
    message: 'Please enter valid email address'
  }
];

const minName = 2;
const maxName = 128;

export const firstNameValidationRules: Record<string, Rule> = {
  required: {
    required: true,
    message: 'First name is required'
  },
  signUpMin: {
    min: minName,
    message: `First name must be longer than or equal to ${minName} characters`
  },
  updateMin: {
    min: minName,
    message: `First name must be at least ${minName} characters`
  },
  max: {
    max: 128,
    message: `First name must be shorter than or equal to ${maxName} characters`
  }
};

export const lastNameValidationRules: Record<string, Rule> = {
  required: {
    required: true,
    message: 'Last name is required'
  },
  signUpMin: {
    min: minName,
    message: `Last name must be longer than or equal to ${minName} characters`
  },
  updateMin: {
    min: minName,
    message: `Last name must be at least ${minName} characters`
  },
  max: {
    max: 128,
    message: `Last name must be shorter than or equal to ${maxName} characters`
  }
};

const feedbackMax = 240;
export const rateStoreValidationRules = {
  rate: [{ ...requiredValidationRule }],
  quality: [{ ...requiredValidationRule }],
  serve: [{ ...requiredValidationRule }],
  friendly: [{ ...requiredValidationRule }],
  feedback: [
    {
      max: feedbackMax,
      message: `Please keep your feedback within ${feedbackMax} chars.`
    }
  ]
};

export const ORDER_MAX_PRICE_THRESHOLD = 1501;
