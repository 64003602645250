import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCollectionPreRequestDataMapper,
  buildCommunication,
  EActionsTypes,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { IError } from '@axmit/error-helper';
import { LS_KEY_STORE } from 'common/consts/LocalStorage.const';
import { ERoutesCommon } from 'common/models/routesModel';
import { IApplicationState } from 'app/store/reducers';
import { EStoresLSStatus, IStoreCollection, IStoreCollectionParams, IStoreModel } from 'entities/Store/Store.models';
import { storesListTransport, storesModelTransport } from 'entities/Store/Store.transport';
import { setStoreCustomerModel } from 'entities/Customer/Customer.communication';

const namespace = 'stores';

export interface IStoreConnectedProps {
  storesModel: StoreBranch<IStoreModel, null, IError>;
  getStoresModel(id: string): void;
  getByShortIdStoresModel(code: string): void;
  initStoresModel(): void;

  storesCollection: StoreBranch<IStoreCollection>;
  getStoresCollection(params: IStoreCollectionParams): void;
  clearStoresCollection(): void;

  storesLsStatus: StoreBranch<string>;
  setStoresLSStatus(status: EStoresLSStatus): void;
}

const StoresModelAPIProviders = [
  new APIProvider<IStoreModel | null>(
    EActionsTypes.init,
    async () => {
      const storeStr = localStorage.getItem(LS_KEY_STORE);

      return storeStr ? JSON.parse(storeStr) : storeStr;
    },
    {
      *onStart() {
        yield setStoresLsStatus(EStoresLSStatus.NonUpdated);
      },
      *onSuccess(store) {
        yield store?.id && getStoresModel(store.id);
      }
    }
  ),
  new APIProvider<IStoreModel | null>(EActionsTypes.get, storesModelTransport.get, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper(),
    *onSuccess(response) {
      localStorage.setItem(LS_KEY_STORE, JSON.stringify(response));
      const lsStatus = yield select((state: IApplicationState) => state?.stores.lsStatus?.data);
      yield lsStatus && lsStatus === EStoresLSStatus.NonUpdated && setStoresLsStatus(EStoresLSStatus.Updated);
    }
  }),
  new APIProvider<IStoreModel>('getByShortId', storesModelTransport.getByShortId, {
    *onSuccess(store) {
      const userId = yield select((state: IApplicationState) => state?.auth.model?.data?.access?.userId);
      yield userId && setStoreCustomerModel({ params: { id: userId, store: store.id } });

      if (!userId) {
        localStorage.setItem(LS_KEY_STORE, JSON.stringify(store));
        yield setStoresLsStatus(EStoresLSStatus.Updated);
      }
    },
    *onFail() {
      yield put({ type: getStartType(namespace, 'model', EActionsTypes.init) });
      yield put(push(ERoutesCommon.Root));
    }
  })
];

const CollectionAPIProviders = [
  new APIProvider<IStoreCollection, IStoreCollectionParams>(EActionsTypes.get, storesListTransport.getCollection)
];

const LSStatusAPIProviders = [new APIProvider(EActionsTypes.set, async data => data)];

const branches = [
  new Branch('model', StoresModelAPIProviders),
  new Branch('collection', CollectionAPIProviders),
  new Branch('lsStatus', LSStatusAPIProviders, new StoreBranch(EStoresLSStatus.NonUpdated))
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearStoresModel() {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.clear) });
}
function* getStoresModel(payload: string) {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload });
}

function* clearStoresModelSaga() {
  const type = getSuccessType(namespace, 'model', EActionsTypes.clear);
  yield takeEvery(type, function() {
    localStorage.removeItem(LS_KEY_STORE);
  });
}

function* setStoresLsStatus(payload: EStoresLSStatus) {
  yield put({ type: getSuccessType(namespace, 'lsStatus', EActionsTypes.set), payload });
}

export const communicationStore = buildCommunication<IStoreConnectedProps>(strategy);

communicationStore.sagas.push(clearStoresModelSaga());
