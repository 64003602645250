import React from 'react';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import { ADMIN_COMMUNICATION_LINK } from 'common/consts/common';
import exclamationMark from 'app/assets/images/exclamationMark.svg';

interface IComponentProps {
  banExpiredAt?: string;
}

export const BlockingMessage: React.FC<IComponentProps> = props => {
  const { banExpiredAt } = props;
  const isTemporarilyBlocked = !!banExpiredAt;
  const timeBanExpired = isTemporarilyBlocked && moment(banExpiredAt).format('HH:mm');
  const dateBanExpired = isTemporarilyBlocked && moment(banExpiredAt).format('DD.MM.YYYY');
  const message = isTemporarilyBlocked
    ? `Our system detected that you recently did not pick up an order that you made at the store. Access to your account has been temporarily suspended until ${timeBanExpired} on ${dateBanExpired}. An email was sent to you with more information. If you think this was a mistake, please contact Customer Care`
    : 'Our system detected that you did not pick up multiple orders that you made at our stores. Access to your account has been suspended. An email was sent to you with more information. If you think this was a mistake, please contact Customer Care';

  return (
    <div className="blocking-message__wrapper">
      <img width={20} src={exclamationMark} alt="warning" />
      <span className="pl-4">
        {message}{' '}
        <Link target="_blank" rel="noopener noreferrer" href={ADMIN_COMMUNICATION_LINK} className="link">
          here.
        </Link>
      </span>
    </div>
  );
};
