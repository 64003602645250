import React from 'react';
import { List } from 'antd';
import { IAddOnsCategory } from 'entities/Menu/Menu.models';
import { ICartAddItemAddOnParams } from 'entities/Cart/Cart.models';
import { AddOnsListItem } from 'entities/AddOns/components/AddOnsListItem';

interface IComponentProps {
  addOnCategory: IAddOnsCategory;
  itemAddOns?: ICartAddItemAddOnParams[];
  needRemoveItem: boolean;
  update: (item: ICartAddItemAddOnParams) => void;
}

export const AddOnsCategoriesListItem: React.FC<IComponentProps> = props => {
  const { addOnCategory, itemAddOns, needRemoveItem, update } = props;
  const { name, menuItemsToAsset: items } = addOnCategory;

  return (
    <>
      <div className="menu-card-sign mt-7">{name}</div>
      <List
        className="list-without-empty"
        dataSource={items}
        renderItem={addOn => (
          <AddOnsListItem addOn={addOn} needRemoveItem={needRemoveItem} update={update} itemAddOns={itemAddOns} />
        )}
      />
    </>
  );
};
