import React from 'react';
import { Button, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesCommon } from 'common/models/routesModel';
import { emailValidationRules } from 'common/consts/validation.consts';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { SocialBar } from 'entities/Auth/components/SocialBar';

interface IComponentProps {
  onSuccess?: Function;
  close?: () => void;
}

type AllProps = IAuthConnectedProps & IComponentProps;

const LoginFormComponent: React.FC<AllProps> = props => {
  const { onSuccess, authModel, close } = props;
  const { loading, errors, params, data } = authModel;
  const { fields } = useFormMapper(['email', 'password'], data, params?.params, errors);

  const closeModal = () => close && close();
  const login = (values: any) => {
    const { addAuthModel } = props;

    if (loading) {
      return;
    }

    addAuthModel({ params: values, onSuccess });
  };

  return (
    <Form onFinish={login} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
        <Input name="email" type="email" placeholder="Email" disabled={loading} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: 'Password is required'
          }
        ]}
        name="password"
        label="Password"
        className="mb-4"
      >
        <Input.Password type="password" placeholder="Password" disabled={loading} />
      </Form.Item>

      <Row justify="end" align="middle" className="mb-8">
        <Link to={ERoutesCommon.PasswordForgot} className="link" title="Forgot password?" onClick={closeModal}>
          Forgot password?
        </Link>
      </Row>

      <Button size="large" block className="btn" htmlType="submit" type="primary">
        Log in
      </Button>
      <div className="social-bar__separator font-weight-bold fs">Or</div>
      <SocialBar disabled={loading} />
    </Form>
  );
};

export const LoginForm = communicationAuth.injector(LoginFormComponent);
