import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IMenuItem, IGetMenuDefaultCollectionParams, IGetMenuCollectionParams } from 'entities/Menu/Menu.models';

const basePath = '/menu';
const getDefaultMenuPath = 'default';

class MenuTransport extends BaseHttpTransport<AxiosInstance, IMenuItem> {
  public getList = ({ id, ...rest }: IGetMenuCollectionParams) => {
    return this.axios.get(`${this.path}/${id}`, { params: rest });
  };
  public getDefault = (params: IGetMenuDefaultCollectionParams) => axios.get(`${this.path}/${getDefaultMenuPath}`, { params });
}

export const menuTransport = new MenuTransport(basePath, axios);
