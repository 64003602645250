import React from 'react';
import { Button, Form, Input } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import {
  emailValidationRules,
  firstNameValidationRules,
  lastNameValidationRules,
  passValidationRules
} from 'common/consts/validation.consts';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { ICustomerCompleteRegistrationParams } from 'entities/Customer/Customer.models';

const CompleteRegistrationPageComponent: React.FC<ICustomerConnectedProps> = props => {
  const { customerModel, completeRegistrationCustomerModel } = props;
  const { loading, errors, params, data } = customerModel;
  const { fields } = useFormMapper(['firstName', 'lastName', 'email', 'password'], data, params, errors);
  const disableEmailInput = !!data?.email || loading;

  const completeRegistration = (values: any) => {
    const { firstName, lastName, password, email } = values;
    const { userId: id } = data || {};

    if (id) {
      const params: ICustomerCompleteRegistrationParams = {
        id,
        firstName,
        lastName,
        email
      };
      if (password) {
        params.password = password;
      }
      completeRegistrationCustomerModel(params);
    }
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Complete your registration" subHeader="It will take less than a minute">
          <Form onFinish={completeRegistration} layout="vertical" fields={fields}>
            <Form.Item
              rules={[firstNameValidationRules.required, firstNameValidationRules.signUpMin, firstNameValidationRules.max]}
              validateTrigger="onBlur"
              name="firstName"
              label="First name"
            >
              <Input name="firstName" placeholder="First name" disabled={loading} />
            </Form.Item>

            <Form.Item
              rules={[lastNameValidationRules.required, lastNameValidationRules.signUpMin, lastNameValidationRules.max]}
              validateTrigger="onBlur"
              name="lastName"
              label="Last name"
            >
              <Input name="lastName" placeholder="Last name" disabled={loading} />
            </Form.Item>

            <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
              <Input name="email" type="email" disabled={disableEmailInput} />
            </Form.Item>

            <Form.Item rules={[...passValidationRules]} validateFirst name="password" label="Password">
              <Input.Password type="password" disabled={loading} />
            </Form.Item>

            <Button size="large" block className="btn" type="primary" htmlType="submit" disabled={loading}>
              Continue
            </Button>
          </Form>
        </MobileLayout>
      }
    />
  );
};

export const CompleteRegistrationPage = communicationCustomer.injector(CompleteRegistrationPageComponent);
