import moment from 'moment';
import { IPeakHours } from 'entities/PeakHours/PeakHours.models';

const incrementForNextTime = 15;
const incrementAsap = 20;
const incrementASAPForBusy = 30;

const loopBreakValue = 20;

const gapBeforeClosing = 30;
const gapAfterOpening = 30;

const BETimeFormat = 'HH:mm:ss';

export const formatTime = (time: moment.Moment, format: string = 'LT') => {
  return time.format(format);
};

export const getTimeList = (
  open: moment.Moment,
  close: moment.Moment,
  peakHours: IPeakHours | null
): {
  times: moment.Moment[];
  asapIncrement?: number;
  asap?: moment.Moment;
} => {
  const result: moment.Moment[] = [];

  const currentTime: moment.Moment = moment();

  if (!currentTime || !onOpenHours(currentTime, open, close)) {
    return { asap: undefined, times: [] };
  }

  const asapIncrement = getASAPIncrement(currentTime, peakHours, open);
  const asap = currentTime.clone().add(asapIncrement, 'minutes');

  if (!onOpenHours(asap, open, close)) {
    return { asap: undefined, times: [] };
  }

  let i = 0;

  while (i <= loopBreakValue) {
    const previous: moment.Moment = result[i - 1] || asap;
    const item = previous.clone().add(incrementForNextTime, 'minutes');

    if (!onOpenHours(item, open, close)) {
      break;
    }

    result.push(item);

    i = i + 1;
  }

  return { asap, asapIncrement, times: result };
};

const onOpenHours = (time: moment.Moment, open: moment.Moment, close: moment.Moment): boolean => {
  return time.isBetween(open, close.clone().subtract(gapBeforeClosing, 'minutes'), undefined, '[]');
};
const onBusyHours = (time: moment.Moment, peakHours: IPeakHours | null): boolean => {
  if (!peakHours?.peakHours.length) {
    return false;
  }

  return peakHours.peakHours.some(({ begin, end }) => {
    return time.isBetween(moment(begin, BETimeFormat), moment(end, BETimeFormat), undefined, '[]');
  });
};
const getASAPIncrement = (time: moment.Moment, peakHours: IPeakHours | null, open: moment.Moment): number => {
  if (time.isSameOrBefore(open.clone().add(gapAfterOpening, 'minutes'), 'minutes')) {
    return incrementASAPForBusy;
  }

  return onBusyHours(time, peakHours) ? incrementASAPForBusy : incrementAsap;
};
