import React from 'react';
import { Row } from 'antd';
import { CountSelector } from 'common/components/CountSelector';
import { IMenuAddOn } from 'entities/Menu/Menu.models';
import { mapMenuAddonToCartAddItemAddonParams } from 'entities/Cart/Cart.mappers';
import { MAX_ADD_ONS_COUNT } from 'entities/Menu/Menu.const';
import { ICartAddItemAddOnParams } from 'entities/Cart/Cart.models';

interface IComponentProps {
  addOn: IMenuAddOn;
  needRemoveItem: boolean;
  itemAddOns?: ICartAddItemAddOnParams[];
  update: (item: ICartAddItemAddOnParams) => void;
}

export const AddOnsListItem: React.FC<IComponentProps> = props => {
  const { addOn, needRemoveItem, itemAddOns, update } = props;
  const { id, name, price, isEnable } = addOn;
  const itemAddon =
    itemAddOns?.find(({ menuItemId }) => menuItemId === addOn?.menuItemId) || mapMenuAddonToCartAddItemAddonParams(addOn);
  const quantity = itemAddon?.quantity || 0;
  const maxCount = !isEnable || needRemoveItem ? quantity : MAX_ADD_ONS_COUNT;
  const minCount = needRemoveItem ? quantity : 0;

  return (
    <CountSelector
      value={quantity}
      label={
        <Row className="fs" align="middle">
          <span className={`fs-xs font-weight-7 pr-2 word-break ${!isEnable ? 'color-gray' : ''}`}>{name}</span>
          {!isEnable ? (
            <span className="fs-xxs color-gray">(Unavailable)</span>
          ) : (
            <span>
              (+P&nbsp;<span className="fs-xs font-weight-7 word-break">{price}</span>)
            </span>
          )}
        </Row>
      }
      onChange={newCount => {
        update({ ...itemAddon, quantity: newCount });
      }}
      key={id}
      max={maxCount}
      min={minCount}
    />
  );
};
