import React, { memo } from 'react';
import { ICartItemAddOnModel } from 'entities/Cart/Cart.models';

interface IComponentProps {
  addOn: ICartItemAddOnModel;
  itemQuantity: number;
}

const CartItemAddOnsListItemComponent: React.FC<IComponentProps> = props => {
  const { addOn, itemQuantity } = props;
  const { isEnable, name, quantity, total } = addOn;
  const finalTotal = total * itemQuantity || 0;
  const finalAddOnQuantity = quantity * itemQuantity || 0;

  return (
    <div className="menu-card__description mt-3">
      {!isEnable ? (
        <div className="f-right ml-2 color-gray font-weight-5">Unavailable</div>
      ) : (
        <div className="f-right ml-2">Р{finalTotal || 0}</div>
      )}
      <span className={!isEnable ? 'color-gray' : ''}>
        {finalAddOnQuantity}x {name}
      </span>
    </div>
  );
};

export const CartItemAddOnsListItem = memo(CartItemAddOnsListItemComponent);
