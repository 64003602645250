import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { getQueryParam } from 'common/helpers/url.helper';
import { QR_QUERY_PARAM_NAME } from 'common/consts/QR.const';
import { ERoutesCommon } from 'common/models/routesModel';
import { LoadingSpin, ModalContent } from 'common/components';
import { LS_KEY_STORE } from 'common/consts/LocalStorage.const';
import { StoreCard } from 'entities/QR/components/StoreCard';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { communicationCart, ICartConnectedProps } from 'entities/Cart/Cart.communication';
import { ECartErrorCode } from 'entities/Cart/Cart.models';
import { IStoreModel, EStoreState } from 'entities/Store/Store.models';
import { StorePickerModal } from 'entities/Store/components/StorePickerModal';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ScannedItemCard } from 'entities/QR/components/ScannedItemCard';
import { communicationQR, IQrConnectedProps } from 'entities/QR/QR.communication';
import { EAuthModals } from 'entities/UI/UI.models';
import { EQRError } from 'entities/QR/QR.models';
import { AddToBagConfirmModal } from 'entities/QR/components/AddToBagConfirmModal';
import { IMenuItem } from 'entities/Menu/Menu.models';

enum EItemErrorText {
  storeTUText = 'The selected store is temporarily unavailable.',
  itemTUText = 'The item is temporarily unavailable.',
  itemTUInStoreText = 'The item is temporarily unavailable at this store.',
  changeStoreText = 'Please, select another store and try again.',
  qrNotFound = 'This scanned QR code is not available anymore.',
  storeProblem = 'If you see this message, you haven’t selected any store yet, or the currently selected store is no longer available.'
}

type AllProps = ICustomerConnectedProps & IStoreConnectedProps & IUIConnectedProps & IQrConnectedProps & ICartConnectedProps;

const ScanPageComponent: React.FC<AllProps> = ({
  customerModel,
  qrModel,
  cartModel,
  getQrModel,
  setStoreCustomerModel,
  openUiCommonModal,
  updateUiAuthModal,
  openUiAddToBagConfirmModal,
  addItemCartModel
}) => {
  const qrName = getQueryParam(QR_QUERY_PARAM_NAME) || '';
  const { data: qrModelData, loading } = qrModel;
  const { data: customer } = customerModel;
  const { errors: errorAddItemCart, loading: cartLoading } = cartModel;
  const isAuthorized = !!customer?.userId;
  const storeOptions = useMemo(() => qrModelData?.data.map(({ store }) => store) || [], [qrModelData]);

  const [quantity, setQuantity] = useState<number>(1);
  const [selectedStore, setSelectedStore] = useState<IStoreModel>();
  const [menuItemFromQrCode, setMenuItemFromQrCode] = useState<IMenuItem>();
  const [selectedMenuItem, setSelectedMenuItem] = useState<IMenuItem>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  const qrError = qrModelData?.error;
  const isStoreSettedByQR = qrModelData?.isStoreSetted;
  const isItemUnavailable = qrError === EQRError.MenuItemUnavailableException;
  const isStoreOffline = useMemo(() => selectedStore?.state !== EStoreState.Online, [selectedStore]);
  const isStoreUnavailable = qrError === EQRError.StoreTemporarilyUnavailableException;
  const lsStoreStr = localStorage.getItem(LS_KEY_STORE);
  const customerStore = customer?.store || (lsStoreStr ? JSON.parse(lsStoreStr) : lsStoreStr);
  const isStoreDifferent = useMemo(() => customerStore?.id !== selectedStore?.id, [selectedStore, customerStore]);
  const customerStoreInOptions = storeOptions.find(({ id }) => id === customerStore?.id);
  const menuItem = menuItemFromQrCode || selectedMenuItem;

  useEffect(() => {
    getQrModel(qrName);
  }, [qrName]);

  useEffect(() => {
    const itemFromStore = qrModelData?.data && qrModelData.data[0].menuItemAsset;
    setMenuItemFromQrCode(() => itemFromStore);
  }, [qrModelData]);

  useEffect(() => {
    setIsShowErrorModal(!!errorAddItemCart);
  }, [errorAddItemCart]);

  const modalErrorMessage = useMemo(() => {
    // @ts-ignore
    if (errorAddItemCart?.data?.code === ECartErrorCode.CartQRItemNotFound) {
      return `${EItemErrorText.itemTUText} ${isStoreSettedByQR ? '' : EItemErrorText.changeStoreText}`;
    }
    // @ts-ignore
    if (errorAddItemCart?.data?.code === ECartErrorCode.StoreQRItemNotFound) {
      return `${EItemErrorText.storeTUText} ${isStoreSettedByQR ? '' : EItemErrorText.changeStoreText}`;
    }
    return '';
  }, [errorAddItemCart]);

  useEffect(() => {
    if (isStoreSettedByQR) {
      handleSelectStore(storeOptions[0]);
    } else {
      if (customerStoreInOptions) {
        handleSelectStore(customerStoreInOptions);
      } else {
        customerStore && handleSelectStore(customerStore);
      }
    }
  }, [isStoreSettedByQR, storeOptions, customerStore, customerStoreInOptions]);

  const history = useHistory();

  const goToMain = () => {
    history.push(ERoutesCommon.Root);
  };

  const handleSelectStore = useCallback(
    (store: IStoreModel) => {
      setSelectedStore(store);
      const itemFromStore =
        qrModelData?.data && qrModelData.data.find(({ store: storeFromData }) => storeFromData.id === store.id)?.menuItemAsset;
      setSelectedMenuItem(() => itemFromStore);
    },
    [qrModelData]
  );

  const handleStoreClick = useCallback(() => {
    openUiCommonModal();
  }, [openUiCommonModal]);

  const handleAddToBagClick = useCallback(() => {
    if (!isAuthorized) {
      updateUiAuthModal({ isVisible: true, type: EAuthModals.Type });
    } else if (isStoreDifferent) {
      openUiAddToBagConfirmModal();
    } else {
      confirmAddToBag();
    }
  }, [isAuthorized, isStoreDifferent, selectedMenuItem, quantity]);

  // TODO Try to refactor this method
  const confirmAddToBag = useCallback(() => {
    if (selectedMenuItem && selectedStore) {
      const { userId } = customer || {};

      if (userId) {
        isStoreDifferent
          ? setStoreCustomerModel({
              params: { id: userId, store: selectedStore.id },
              onSuccess: () =>
                addItemCartModel({
                  params: { ...selectedMenuItem, quantity, addOns: [] },
                  onSuccess: goToMain
                })
            })
          : addItemCartModel({
              params: { ...selectedMenuItem, quantity, addOns: [] },
              onSuccess: goToMain
            });
      }
    }
  }, [isStoreDifferent, selectedMenuItem, selectedStore, quantity]);

  const isUserHasChoices = useMemo(() => storeOptions.length > 1, [storeOptions]);

  // TODO Try to move to separate helper
  const itemErrorText = useMemo(() => {
    let errorText = `We're sorry! `;
    let hasError = false;

    switch (true) {
      case qrModelData && !customerStoreInOptions && customerStore && selectedStore?.id === customerStore.id:
        if (customerStore.status === 'active') {
          errorText += `${EItemErrorText.itemTUInStoreText} ${EItemErrorText.changeStoreText} `;
          hasError = true;
        } else {
          errorText = `${EItemErrorText.storeProblem} ${EItemErrorText.changeStoreText} `;
          hasError = true;
        }
        break;
      case isItemUnavailable && isUserHasChoices:
        errorText += `${EItemErrorText.itemTUInStoreText} ${EItemErrorText.changeStoreText} `;
        hasError = true;
        break;
      case isItemUnavailable && !isUserHasChoices:
        errorText += `${EItemErrorText.itemTUText} `;
        hasError = true;
        break;
      case isStoreUnavailable && isUserHasChoices:
        errorText += `${EItemErrorText.storeTUText} ${EItemErrorText.changeStoreText} `;
        hasError = true;
        break;
      case isStoreUnavailable && !isUserHasChoices:
        errorText += `${EItemErrorText.storeTUText} `;
        hasError = true;
        break;
      case !qrModelData:
        errorText += `${EItemErrorText.qrNotFound} `;
        hasError = true;
        break;
      case !selectedStore:
        errorText = `${EItemErrorText.storeProblem} ${EItemErrorText.changeStoreText} `;
        hasError = true;
        break;
    }

    return { hasError, errorText };
  }, [qrModelData, isStoreUnavailable, isItemUnavailable, isUserHasChoices, selectedStore, storeOptions, customer]);

  const handleCloseModal = useCallback(() => {
    setIsShowErrorModal(false);
    history.go(0);
  }, []);

  if (loading) {
    return <LoadingSpin />;
  }

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Go add this to your bag!">
          {qrModelData && (
            <StoreCard store={selectedStore} disabled={isStoreSettedByQR || cartLoading} onClick={handleStoreClick} />
          )}
          {menuItem && (
            <ScannedItemCard item={menuItem} selectedStore={selectedStore} quantity={quantity} onQuantityChange={setQuantity} />
          )}
          {itemErrorText.hasError && <div className="mt-9 mb-6">{itemErrorText.errorText}</div>}
          <Button
            block
            className="btn mb-7"
            type="primary"
            disabled={
              isStoreOffline ||
              isItemUnavailable ||
              isStoreUnavailable ||
              cartLoading ||
              (!customerStoreInOptions && customerStore && selectedStore?.id === customerStore.id)
            }
            onClick={handleAddToBagClick}
          >
            Add to bag
          </Button>
          <Button block className="btn" onClick={goToMain}>
            Cancel
          </Button>
          <StorePickerModal options={storeOptions} onSelect={handleSelectStore} />
          <AddToBagConfirmModal onConfirm={confirmAddToBag} />
          <ModalContent
            visible={isShowErrorModal && !!modalErrorMessage}
            close={handleCloseModal}
            header="We're sorry!"
            subHeader={modalErrorMessage}
          >
            <Button size="large" block className="btn" type="primary" onClick={handleCloseModal}>
              Update
            </Button>
          </ModalContent>
        </MobileLayout>
      }
    />
  );
};

export const ScanPage = communicationQR.injector(
  communicationCart.injector(
    communicationCustomer.injector(communicationStore.injector(communicationUI.injector(ScanPageComponent)))
  )
);
