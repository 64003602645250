import { IAsyncStorage, WebStorageDecorator } from '@axmit/universal-storage';
import { ERoutesCommon } from 'common/models/routesModel';

const checkAndCreateLocalStorage = () => {
  let storage = {} as IAsyncStorage;

  const { pathname: currentPath } = window.location;

  try {
    storage = new WebStorageDecorator(localStorage);

    if (currentPath === `${ERoutesCommon.CookieBlocked}`) {
      window.location.pathname = `${ERoutesCommon.Root}`;
    }
  } catch (error) {
    if (currentPath !== `${ERoutesCommon.CookieBlocked}`) {
      window.location.pathname = `${ERoutesCommon.CookieBlocked}`;
    }
  }

  return storage;
};

export const storageHelper = { checkAndCreateLocalStorage };
