export interface IQRModel extends Components.Schemas.QRCodeStoreWithItemAssetDto {}
export interface IQRModelCollection extends Components.Schemas.QRCodeCollectionDto {}

export interface IQRModelData extends Components.Schemas.QRCodeResultDto {}

export enum EQRError {
  StoreIsOfflineException = 'StoreIsOfflineException',
  MenuItemUnavailableException = 'MenuItemUnavailableException',
  StoreTemporarilyUnavailableException = 'StoreTemporarilyUnavailableException'
}
