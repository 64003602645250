import React from 'react';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { SignUp } from 'entities/Auth/components/SignUpForm';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EAuthModals } from 'entities/UI/UI.models';

type AllProps = IUIConnectedProps;

const SignupPageComponent: React.FC<AllProps> = ({ updateUiAuthModal }) => {
  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Registration" subHeader="It will take less than a minute">
          <SignUp onSuccess={() => updateUiAuthModal({ isVisible: true, type: EAuthModals.Verify })} />
        </MobileLayout>
      }
    />
  );
};

export const SignupPage = communicationUI.injector(SignupPageComponent);
