import React, { ReactNode } from 'react';
import { Button, Col, Row } from 'antd';
import { ReactComponent as MinusIcon } from 'app/assets/images/minus.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/plus.svg';

interface IComponentProps {
  value: number;
  onChange: (value: number) => void;
  label?: ReactNode;
  max?: number;
  min?: number;
  className?: string;
}

export const CountSelector: React.FC<IComponentProps> = props => {
  const { value, onChange, max, min = 0, label = '', className = '' } = props;

  const isMinusBtnDisabled = value <= min;
  const isPlusBtnDisabled = max !== undefined && value >= max;

  return (
    <Row className={`mt-5 ${className}`} wrap={false} align="middle">
      <Col>
        <Row wrap={false}>
          <Button
            className={`btn-opacity btn-opacity_gray btn-opacity_left ${isMinusBtnDisabled && 'btn-opacity_gray-disabled'}`}
            disabled={isMinusBtnDisabled}
            onClick={() => onChange(value - 1)}
          >
            <MinusIcon />
          </Button>

          <div className="menu-details__count">{value}</div>

          <Button
            className={`mr-5 btn-opacity btn-opacity_gray btn-opacity_right ${isPlusBtnDisabled && 'btn-opacity_gray-disabled'}`}
            onClick={() => onChange(value + 1)}
            disabled={isPlusBtnDisabled}
          >
            <PlusIcon />
          </Button>
        </Row>
      </Col>
      <Col>{label}</Col>
    </Row>
  );
};
