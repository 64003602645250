import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType,
  getSuccessType
} from '@axmit/redux-communications';
import { call, put, select } from 'redux-saga/effects';
import { saveCreds } from '@axmit/axios-patch-jwt';
import { push } from 'react-router-redux';
import { message } from 'antd';
import { IError, EErrorStatus } from '@axmit/error-helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { mapParamsWithCallback } from 'common/mappers/Params.mapper';
import { RequestLoadingHelper } from 'common/helpers/requestLoading.helper';
import { getStoreShortId } from 'common/helpers/Store.helper';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { IParamWithCallback } from 'common/models/requestModels';
import { IApplicationState } from 'app/store/reducers';
import {
  ICustomerModel,
  ICustomerAddParams,
  IConfirmationModel,
  IConfirmationParams,
  ICustomerUpdateParams,
  ICustomerSetStoreParams,
  ECustomerErrorMessage,
  ECustomerErrorCode,
  ICustomerCompleteRegistrationParams,
  ICustomerSetPasswordParams,
  ICustomerUpdatePasswordParams
} from 'entities/Customer/Customer.models';
import { EAuthErrorMessage, EAuthSuccessMessage } from 'entities/Auth/Auth.models';
import { confirmationTransport, customerTransport } from 'entities/Customer/Customer.transport';
import { getDefaultMenuCollection, getMenuAddOns, getMenuCollection } from 'entities/Menu/Menu.communication';
import { clearStoresModel } from 'entities/Store/Store.communication';
import { IStoreModel } from 'entities/Store/Store.models';
import { EUserSuccessMessage } from 'entities/User/User.models';
import { closeCommonModal } from 'entities/UI/UI.communication';
import { clearSocialNetworkRegistered } from 'entities/Auth/Auth.communication';
import { getSuccessCartModel } from 'entities/Cart/Cart.communication';

const namespace = 'customer';

export interface ICustomerConnectedProps {
  customerModel: StoreBranch<ICustomerModel, ICustomerAddParams | ICustomerUpdateParams, IError>;
  customerPassword: StoreBranch<ICustomerModel, ICustomerSetPasswordParams | ICustomerUpdatePasswordParams, IError>;
  setCustomerPassword(params: ICustomerSetPasswordParams): void;
  updateCustomerPassword(params: ICustomerUpdatePasswordParams): void;
  getCustomerModel(id: string): void;
  addCustomerModel(params: ICustomerAddParams): void;
  updateCustomerModel(params: ICustomerUpdateParams): void;
  setStoreCustomerModel(params: IParamWithCallback<ICustomerSetStoreParams>): void;
  completeRegistrationCustomerModel(params: ICustomerCompleteRegistrationParams): void;
  clearCustomerModel(): void;
  clearCustomerPassword(): void;
  disconnectGoogleCustomerModel(): void;
  disconnectFacebookCustomerModel(): void;

  customerConfirmation: StoreBranch<IConfirmationModel, IConfirmationParams, IError>;
  addCustomerConfirmation(params: IConfirmationParams): void;
}

const CustomerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, customerTransport.get, {
    *onSuccess(response) {
      const isJustRegisteredViaSocialNetwork = yield select(
        (state: IApplicationState) => state?.auth?.socialNetworkRegistered?.data
      );

      if (isJustRegisteredViaSocialNetwork) {
        const customerFullName = yield call(getCustomerFullName, response);
        const { email } = response || {};

        yield call(GDLHelper.pushSignupEvent, customerFullName, email || '');
        yield clearSocialNetworkRegistered();
      }

      const phone = response.phone;

      if (phone) {
        yield put({ type: getSuccessType('customersPhones', 'model', EActionsTypes.add), payload: { phone } });
      }
    }
  }),
  new APIProvider<ICustomerModel, ICustomerAddParams>(EActionsTypes.add, customerTransport.add, {
    mapParams: mapParamsWithCallback,
    clearParams: true,
    onSuccess: function(response, originalParams) {
      originalParams?.onSuccess && originalParams.onSuccess();

      const { email } = response || {};
      const customerFullName = getCustomerFullName(response);
      GDLHelper.pushSignupEvent(customerFullName, email || '');
    },
    onFail(error) {
      if (error?.data?.code === ECustomerErrorCode.AlreadyRegistered) {
        message.error(ECustomerErrorMessage.AlreadyRegistered);
      }
    }
  }),
  new APIProvider<ICustomerModel, ICustomerUpdateParams>(EActionsTypes.update, customerTransport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: () => {
      message.success(EAuthSuccessMessage.SuccessfullyEdited);
    }
  }),
  new APIProvider<ICustomerModel, IParamWithCallback<ICustomerSetStoreParams>>('setStore', customerTransport.setStore, {
    onSuccess: function*(customer, originalParams) {
      if (originalParams?.onSuccess) {
        originalParams.onSuccess();
      } else {
        const { cart } = customer || {};
        yield cart && getSuccessCartModel(cart);

        const store: IStoreModel = yield select((state: IApplicationState) => state?.stores.model?.data);
        yield store && clearStoresModel();

        const storeShortId = getStoreShortId();

        if (!storeShortId) {
          return;
        }

        const storeId = customer?.store?.id;

        if (storeId) {
          yield getMenuCollection(storeId);
          yield getMenuAddOns(storeId);
        } else {
          yield getDefaultMenuCollection();
        }
      }
    }
  }),
  new APIProvider('completeRegistration', customerTransport.completeRegistration, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider('disconnectFacebook', customerTransport.disconnectFacebook),
  new APIProvider('disconnectGoogle', customerTransport.disconnectGoogle)
];

const ConfirmationAPIProviders = [
  new APIProvider(EActionsTypes.add, confirmationTransport.add, {
    clearParams: true,
    onSuccess: function*(response) {
      message.success(EAuthSuccessMessage.AccountConfirmed);
      yield call(saveCreds, response);
      yield put({ type: getStartType('auth', 'model', EActionsTypes.init) });
      yield put(push(ERoutesCommon.Root));
    },
    onFail: function*(response: IError) {
      if (response.status === EErrorStatus.Validation || response.status === EErrorStatus.NotFound) {
        message.error(EAuthErrorMessage.InvalidLink);
      }
      yield put(push(ERoutesCommon.Root));
    }
  })
];
const SetPasswordAPIProviders = [
  new APIProvider('set', customerTransport.setPassword, {
    onSuccess: function*(response: ICustomerModel) {
      yield getCustomerModelSuccessType(response);
      yield closeCommonModal();
    }
  }),
  new APIProvider(EActionsTypes.update, customerTransport.updatePassword, {
    onSuccess: function*(response: ICustomerModel) {
      yield getCustomerModelSuccessType(response);
      yield closeCommonModal();
      message.success(EUserSuccessMessage.PasswordChanged);
    }
  })
];

export function* confirmCustomer(token: string) {
  yield put({ type: getStartType(namespace, 'confirmation', EActionsTypes.add), payload: { token } });
}

export function* getCustomerModel(id: string) {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload: id });
}

export function* getCustomerModelSuccessType(model: ICustomerModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.get), payload: model });
}

export function* clearCustomerModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

export function* setStoreCustomerModel(payload: IParamWithCallback<ICustomerSetStoreParams>) {
  yield put({ type: getStartType(namespace, 'model', 'setStore'), payload });
}

export function* updateCustomerStoreFromLS(userId: string) {
  const store: IStoreModel = yield select((state: IApplicationState) => state?.stores.model?.data);
  yield store && setStoreCustomerModel({ params: { store: store.id, id: userId } });
}

export function* updateCustomerStore(store: IStoreModel) {
  const customer: ICustomerModel = yield select((state: IApplicationState) => state?.customer?.model?.data);
  yield customer && getCustomerModelSuccessType({ ...customer, store });
}

const branches = [
  new Branch('model', CustomerModelAPIProviders),
  new Branch('confirmation', ConfirmationAPIProviders),
  new Branch('password', SetPasswordAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCustomer = buildCommunication<ICustomerConnectedProps>(strategy);
