import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { RequestLoadingHelper } from 'common/helpers/requestLoading.helper';
import {
  IAddToBagConfirmModal,
  IAuthViewModal,
  ICartItemModalModel,
  IChangeStoreConfirmModalModel,
  ICommonModalModel,
  IReorderConfirmModalModel,
  ISocialLinkModalModel
} from 'entities/UI/UI.models';

const namespace = 'ui';

export interface IUIConnectedProps {
  uiCommonModal: StoreBranch<ICommonModalModel>;

  openUiCommonModal(): void;
  closeUiCommonModal(): void;

  uiAuthModal: StoreBranch<IAuthViewModal>;
  updateUiAuthModal(params: IAuthViewModal): void;

  uiSocialLinkModal: StoreBranch<ISocialLinkModalModel>;
  openUiSocialLinkModal(params: ISocialLinkModalModel): void;
  closeUiSocialLinkModal(): void;

  uiReorderConfirmModal: StoreBranch<IReorderConfirmModalModel>;
  openUiReorderConfirmModal(params: IReorderConfirmModalModel): void;
  closeUiReorderConfirmModal(): void;

  uiCartItemModal: StoreBranch<ICartItemModalModel>;
  openUiCartItemModal(params: ICartItemModalModel): void;
  closeUiCartItemModal(): void;

  uiChangeStoreConfirmModal: StoreBranch<IChangeStoreConfirmModalModel>;
  openUiChangeStoreConfirmModal(params: IChangeStoreConfirmModalModel): void;
  closeUiChangeStoreConfirmModal(): void;

  uiAddToBagConfirmModal: StoreBranch<IAddToBagConfirmModal>;
  openUiAddToBagConfirmModal(): void;
  closeUiAddToBagConfirmModal(): void;
}

const AuthModalAPIProvider = [
  new APIProvider(EActionsTypes.update, async data => data, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const CommonModalApiProvider = [
  new APIProvider('open', (): Promise<ICommonModalModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<ICommonModalModel> => Promise.resolve({ isVisible: false }))
];

const SocialLinkModalApiProvider = [
  new APIProvider(
    'open',
    (params: ISocialLinkModalModel): Promise<ISocialLinkModalModel> => Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider('close', (): Promise<ISocialLinkModalModel> => Promise.resolve({ isVisible: false }))
];

const ReorderConfirmModalApiProvider = [
  new APIProvider(
    'open',
    (params: IReorderConfirmModalModel): Promise<IReorderConfirmModalModel> => Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider('close', (): Promise<IReorderConfirmModalModel> => Promise.resolve({ isVisible: false }))
];

const CartItemModalApiProvider = [
  new APIProvider(
    'open',
    (params: ICartItemModalModel): Promise<ICartItemModalModel> => Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider('close', (): Promise<ICartItemModalModel> => Promise.resolve({ isVisible: false }))
];

const ChangeStoreConfirmApiProvider = [
  new APIProvider(
    'open',
    (params: IChangeStoreConfirmModalModel): Promise<IChangeStoreConfirmModalModel> =>
      Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider('close', (): Promise<IChangeStoreConfirmModalModel> => Promise.resolve({ isVisible: false }))
];

const AddToBagConfirmModalApiProviders = [
  new APIProvider('open', (): Promise<IAddToBagConfirmModal> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IAddToBagConfirmModal> => Promise.resolve({ isVisible: false }))
];

const branches = [
  new Branch('commonModal', CommonModalApiProvider),
  new Branch('authModal', AuthModalAPIProvider),
  new Branch('socialLinkModal', SocialLinkModalApiProvider),
  new Branch('reorderConfirmModal', ReorderConfirmModalApiProvider),
  new Branch('cartItemModal', CartItemModalApiProvider),
  new Branch('changeStoreConfirmModal', ChangeStoreConfirmApiProvider),
  new Branch('addToBagConfirmModal', AddToBagConfirmModalApiProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUI = buildCommunication<IUIConnectedProps>(strategy);

export function* closeCommonModal() {
  yield put({ type: getStartType(namespace, 'commonModal', 'close') });
}
