import React from 'react';
import { Button, Form, Input } from 'antd';
import { useFormValidation } from '@axmit/antd4-helpers';
import { useHistory } from 'react-router';
import { ERoutesPublic } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { emailValidationRules } from 'common/consts/validation.consts';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const ForgotPasswordPageComponent: React.FC<AllProps> = props => {
  const { authPasswordRestore } = props;
  const { errors, loading, params } = authPasswordRestore;

  const history = useHistory();

  const { fields } = useFormValidation(['email'], params, errors);

  const submit = (values: any) => {
    const { addAuthPasswordRestore } = props;

    const email = values?.email || '';

    addAuthPasswordRestore({ email });
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Restore password" back={() => history.push(ERoutesPublic.Login)}>
          <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
            <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
              <Input name="email" type="email" placeholder="Email" disabled={loading} />
            </Form.Item>

            <Button size="large" block className="btn" htmlType="submit" type="primary" disabled={loading}>
              Send instructions
            </Button>
          </Form>
        </MobileLayout>
      }
    />
  );
};

export const ForgotPasswordPage = communicationAuth.injector(ForgotPasswordPageComponent);
