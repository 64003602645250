import React from 'react';
import { Col, Row } from 'antd';
import MinuteBurger from 'app/assets/images/minuteBurger.png';
import MBGoSmall from 'app/assets/images/mbGoSmall.png';

export const CookieBlockedPage: React.FC = () => {
  return (
    <Row justify="center" className="blocked-cookie">
      <Col className="blocked-cookie__wrapper">
        <Row justify="center" className="mb-8">
          <Col>
            <img src={MinuteBurger} width={85} height={89} alt="Minute Burger" className="mr-8" />
            <img src={MBGoSmall} width={89} height={89} alt="Minute Burger" />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="blocked-cookie__header">We use cookies to give you better experience.</div>
            <div className="blocked-cookie__subheader">You might have all cookies disabled in your browser settings.</div>
            <div className="blocked-cookie__subheader">
              Please enable cookies in your browser settings and try to load our website again.
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
