import React, { memo } from 'react';
import { IOrderItemAddOnModel } from 'entities/Orders/Orders.models';

interface IComponentProps {
  addOn: IOrderItemAddOnModel;
  itemQuantity: number;
}

const OrderItemAddOnsListItemComponent: React.FC<IComponentProps> = props => {
  const { addOn, itemQuantity } = props;
  const { assetItemData, quantity, total } = addOn;
  const { name } = assetItemData;
  const finalQuantity = itemQuantity * quantity || 0;

  return (
    <div className="menu-card__description mt-3">
      <div className="f-right ml-2">Р{total || 0}</div>
      <span>
        {finalQuantity}x {name}
      </span>
    </div>
  );
};

export const OrderItemAddOnsListItem = memo(OrderItemAddOnsListItemComponent);
