import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch,
  getSuccessType
} from '@axmit/redux-communications';
import { call, put } from 'redux-saga/effects';
import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { message } from 'antd';
import { push } from 'react-router-redux';
import { IError } from '@axmit/error-helper';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { IParamWithCallback } from 'common/models/requestModels';
import {
  IAuthModel,
  IAuthParams,
  IPasswordRestoreParams,
  ITokenModel,
  IPasswordForgotParams,
  EAuthSuccessMessage,
  EAuthErrorMessage,
  EAuthErrorCode,
  ICheckTokenData,
  IAuthRefreshParams
} from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';
import {
  getCustomerModel,
  clearCustomerModel,
  updateCustomerStoreFromLS,
  confirmCustomer
} from 'entities/Customer/Customer.communication';
import { clearStoresModel } from 'entities/Store/Store.communication';
import { userTransport } from 'entities/User/User.transport';
import { clearCartModel, getCartModel } from 'entities/Cart/Cart.communication';

const namespace = 'auth';

export interface IAuthConnectedProps {
  authModel: StoreBranch<IAuthModel, IParamWithCallback<IAuthParams>, IError>;

  initAuthModel(): void;
  addAuthModel(params: IParamWithCallback<IAuthParams>): void;
  updateAuthModel(params: IAuthRefreshParams): void;
  deleteAuthModel(): void;
  clearAuthModel(): void;

  authPasswordRestore: StoreBranch<any>;
  addAuthPasswordRestore(params: IPasswordForgotParams): void;
  updateAuthPasswordRestore(data: IPasswordRestoreParams): void;

  authTokenCheck: StoreBranch<any>;
  passwordRestoreAuthTokenCheck(data: ICheckTokenData): void;
  confirmUserAuthTokenCheck(data: ICheckTokenData): void;

  authSocialNetworkRegistered: StoreBranch<boolean>;
  updateAuthSocialNetworkRegistered(data: boolean): void;
}

const successAuth = function*(response: IAuthModel) {
  const userId = response?.access?.userId;

  if (userId) {
    yield getCustomerModel(userId);
    yield getCartModel();
    yield updateCustomerStoreFromLS(userId);
  }
};

const modelApiProvider = [
  new APIProvider<IAuthModel, IParamWithCallback<IAuthParams>>(EActionsTypes.add, authTransport.login, {
    clearParams: true,
    onSuccess: function*(response, originalParams) {
      originalParams?.onSuccess && originalParams.onSuccess();
      yield call(saveCreds, response);
      yield successAuth(response);

      yield put(push(ERoutesCommon.Root));
    },
    onFail(error) {
      if (error?.data?.code === EAuthErrorCode.InvalidCredentials) {
        message.error(EAuthErrorMessage.InvalidCredentials);
      }
      if (error?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      if (error?.data?.code === EAuthErrorCode.UserNotConfirmed) {
        message.error(EAuthErrorMessage.UserNotConfirmed);
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, authTransport.logout, {
    onSuccess: function*() {
      yield put(push(ERoutesCommon.Root));
      yield clearAuth();
    },
    onFail: function*() {
      yield put(push(ERoutesCommon.Root));
    }
  }),
  new APIProvider(EActionsTypes.update, authTransport.refresh, {
    onSuccess: function*(response) {
      yield call(saveCreds, response);
      yield successAuth(response);
      yield put(push(ERoutesCommon.Root));
    },
    onFail: function*() {
      yield put(push(ERoutesCommon.Root));
    }
  }),
  new APIProvider(EActionsTypes.init, (): Promise<ITokenModel> => getCreds(), {
    onSuccess: function*(response) {
      yield successAuth(response);
    }
  })
];
const passwordRestoreApiProvider = [
  new APIProvider(EActionsTypes.add, authTransport.passwordRestore, {
    onSuccess: function(response, originalParams) {
      const email = originalParams?.email || '';
      const popupMessage = email
        ? `${EAuthSuccessMessage.CheckEmailAfterRequestWithEmail}${email}`
        : EAuthSuccessMessage.CheckEmailAfterRequest;

      message.success(popupMessage);
    }
  }),
  new APIProvider(EActionsTypes.update, authTransport.passwordRestoreNew, {
    onSuccess: function*() {
      message.success(EAuthSuccessMessage.PasswordChanged);
      yield put(push(ERoutesPublic.Login));
    },
    onFail: function(e: any) {
      const linkInvalid = e.status === 422 && e?.validation?.token;

      if (e.status === 404 || linkInvalid) {
        message.error(EAuthErrorMessage.LinkExpired);
      }
    }
  })
];

const tokenCheckAPIProviders = [
  new APIProvider('confirmUser', userTransport.checkInviteToken, {
    onSuccess: function*(response, originalParams) {
      const token = originalParams?.token;
      yield token && confirmCustomer(token);
    }
  }),
  new APIProvider('passwordRestore', authTransport.passwordRestoreCheckToken)
];

const socialNetworkRegisteredAPIProviders = [new APIProvider(EActionsTypes.update, async data => data)];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('passwordRestore', passwordRestoreApiProvider),
  new Branch('tokenCheck', tokenCheckAPIProviders),
  new Branch('socialNetworkRegistered', socialNetworkRegisteredAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearAuth() {
  yield clearAuthModel();
  yield clearStoresModel();
  yield clearCustomerModel();
  yield clearCartModel();
  yield call(clearCreds);
}

function* clearAuthModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

export function* clearSocialNetworkRegistered() {
  yield put({ type: getSuccessType(namespace, 'socialNetworkRegistered', EActionsTypes.clear) });
}

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);
