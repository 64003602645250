import React from 'react';
import { ReactComponent as StarIcon } from 'app/assets/images/star.svg';
import { IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  className?: string;
  store?: IStoreModel;
}

export const StoreRating: React.FC<IComponentProps> = props => {
  const { className, store } = props;
  const { rate, rateCount } = store || {};
  const rateTitle = rate ? `${rate.toFixed(1)} (${rateCount || 0})` : 'No rating yet';

  return (
    <span className={className || ''}>
      <StarIcon className="mr-0" />{' '}
      <span className="fs font-weight-5 vertical-align-bottom" title={rateTitle}>
        {rateTitle}
      </span>
    </span>
  );
};
