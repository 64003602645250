import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { communicationOrderHistory, IOrderHistoryConnectedProps } from 'entities/OrderHistory/OrderHistory.communication';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

interface IComponentProps {
  isVisible: boolean;
  onClose(): void;
  orderHistoryItem?: IOrderHistoryModel;
}

type AllProps = IComponentProps & IOrderHistoryConnectedProps;

const ReorderConfirmModalComponent: React.FC<AllProps> = props => {
  const { isVisible, onClose, orderHistoryItem, addOrderHistoryReorder } = props;

  const handleContinue = () => {
    const { id, store } = orderHistoryItem || {};
    const { id: storeId, storeName } = store || {};

    id && storeId && addOrderHistoryReorder({ storeId, id, storeName });
    onClose();
  };

  return (
    <ModalContent
      visible={isVisible}
      close={onClose}
      header="You already have items in your Bag."
      subHeader="If you reorder, the currently selected store will be changed and the items in your Bag will be replaced with the items of this order."
    >
      <Button size="large" block className="btn mb-7" type="primary" onClick={handleContinue}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={onClose}>
        Cancel
      </Button>
    </ModalContent>
  );
};

export const ReorderConfirmModal = communicationOrderHistory.injector(ReorderConfirmModalComponent);
