import React, { memo } from 'react';
import { Row } from 'antd';
import { formatAmount } from 'common/helpers/format.helper';
import { ECartPromoBonusType } from 'entities/Cart/Cart.models';

interface IComponentProps {
  isPromoApplied?: boolean;
  bonusType?: ECartPromoBonusType;
  total?: number;
  discountTotal?: number | null;
  bonusTotal?: number;
}

const CartItemTotalComponent: React.FC<IComponentProps> = props => {
  const { isPromoApplied, total, discountTotal, bonusTotal } = props;

  if (isPromoApplied && !!bonusTotal) {
    return (
      <Row justify="space-between" wrap={false}>
        <div className="basic__text_title t-decoration-line-through">P{formatAmount(total)}</div>
        <div className="basic__text_title color-orange-main ml-4">P{formatAmount(discountTotal || 0)}</div>
      </Row>
    );
  }

  return <div className="basic__text_title">P{formatAmount(total)}</div>;
};

export const CartItemTotal = memo(CartItemTotalComponent);
