import { IParamWithCallback } from 'common/models/requestModels';

export interface IConfirmPhoneParams extends IParamWithCallback<Components.Schemas.TemporaryPhoneCreateDto> {}
export interface IConfirmPhoneCodeParams extends IParamWithCallback<Components.Schemas.TemporaryPhoneActivateDto> {}

export enum ECustomerPhoneErrorCode {
  NotUnique = 'validation.phoneNotUnique'
}

export enum ECustomerPhoneErrorMessage {
  InvalidCode = 'Invalid code',
  NotUnique = 'This mobile number is already in use. Please use another number.'
}

export enum ECustomerPhoneSuccessMessage {
  Activated = 'Successfully activated'
}
