import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IMenuItem } from 'entities/Menu/Menu.models';

const basePath = '/qr-codes';

class QRTransport extends BaseHttpTransport<AxiosInstance, IMenuItem> {
  public getQr = (name: string) => this.axios.get(`${this.path}/${name}/item`);
}

export const qrTransport = new QRTransport(basePath, axios);
