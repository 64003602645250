import React, { RefObject } from 'react';
import { Anchor } from 'antd';
import { getWithoutSpaces } from 'common/helpers/format.helper';
import { IMenuConnectedProps, communicationMenu } from 'entities/Menu/Menu.communication';
import { ANCHOR_TOP_OFFSET } from 'entities/Menu/Menu.const';

type AllProps = IMenuConnectedProps;

const CategoriesPanelComponent: React.FC<AllProps> = props => {
  const { menuCollection } = props;
  const { data } = menuCollection;
  const categories = data?.categoriesToAsset;

  if (!categories?.length) {
    return null;
  }

  const refs = categories.reduce((acc: { [key: string]: RefObject<HTMLDivElement> }, value) => {
    acc[getWithoutSpaces(value.name)] = React.createRef<HTMLDivElement>();

    return acc;
  }, {});

  const handleAnchorAction = (name: string) => {
    const formattedName = name.replace(/#/g, '');

    if (!formattedName) {
      return;
    }

    const wrapper = document.getElementsByClassName('anchor');

    if (wrapper[0] && refs && refs[formattedName] && refs[formattedName].current) {
      const leftItemOffset = refs[formattedName].current?.offsetLeft || 0;
      const leftPadding = 24;

      wrapper[0].scrollLeft = leftItemOffset ? leftItemOffset - leftPadding : leftItemOffset;
    }
  };

  return (
    <div className="store-panel">
      <Anchor targetOffset={ANCHOR_TOP_OFFSET} className="anchor" onChange={handleAnchorAction}>
        {categories?.map(({ name, id }) => (
          <div ref={refs[getWithoutSpaces(name)]} key={id}>
            <Anchor.Link href={`#${getWithoutSpaces(name)}`} title={name} className="anchor__link" />
          </div>
        ))}
      </Anchor>
    </div>
  );
};

export const CategoriesPanel = communicationMenu.injector(CategoriesPanelComponent);
