import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useFormValidation } from '@axmit/antd4-helpers';
import { useResetTimer } from 'common/hooks/ResetTimer.hooks';
import { secondsToStringTime } from 'common/helpers/date.helper';
import {
  communicationCustomersPhones,
  ICustomersPhonesConnectedProps
} from 'entities/CustomersPhones/CustomersPhones.communication';
import { EAuthSuccessMessage } from 'entities/Auth/Auth.models';

interface IComponentProps {
  phone: string;
  onSuccess?: Function;
}

type AllProps = IComponentProps & ICustomersPhonesConnectedProps;

const CodeConfirmComponent: React.FC<AllProps> = props => {
  const { customersPhonesModel, customersPhonesCode } = props;
  const { loading: loadingPhone } = customersPhonesModel;
  const { loading: loadingCode } = customersPhonesCode;
  const loading = loadingPhone || loadingCode;
  const { setTimer, isTimerActive, seconds } = useResetTimer();

  const { fields } = useFormValidation(['token'], customersPhonesCode.params?.params, customersPhonesCode.errors);

  const submit = (values: any) => {
    const { onSuccess, addCustomersPhonesCode } = props;
    const token = values?.token || '';

    addCustomersPhonesCode({ onSuccess, params: { token } });
  };

  const resend = () => {
    const { addCustomersPhonesModel, phone } = props;

    if (loading) {
      return;
    }

    setTimer();

    addCustomersPhonesModel({ params: { phone }, onSuccess: () => message.success(EAuthSuccessMessage.SMSResend) });
  };

  return (
    <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Code is required'
          }
        ]}
        name="token"
      >
        <Input name="token" disabled={loading} />
      </Form.Item>

      <div className="fs t-align-c">
        Didn&apos;t receive the code?{' '}
        <Button className="btn_link" type="link" onClick={resend} disabled={loading || isTimerActive}>
          Resend
        </Button>
      </div>

      {isTimerActive && (
        <div className="fs font-weight-bold color-orange-main t-align-c mt-2">
          Please check your SMS inbox for your code ({secondsToStringTime(seconds)})
        </div>
      )}

      <Button size="large" block className="btn mt-7" type="primary" htmlType="submit" disabled={loading}>
        Confirm and order
      </Button>
    </Form>
  );
};

export const CodeConfirm = communicationCustomersPhones.injector(CodeConfirmComponent);
